import { Component } from '@angular/core';
import { LayoutService } from '../layout/service/app.layout.service';
import { ImportsModule } from '../../imports/imports.module';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './register.component.html',
    standalone:true,
    imports:[ImportsModule,CheckboxModule,PasswordModule,TranslateModule ]
})
export class RegisterComponent {
    confirmed: boolean = false;
    selectedLanguage: string;
    selectedLanguageIcon: string = '';
    dropdownVisible: boolean = false;

    constructor(private layoutService: LayoutService, private translate: TranslateService
    ) { 
      const defaultLanguage = 'gr';
      this.translate.setDefaultLang(defaultLanguage);
      this.translate.use(defaultLanguage);
      this.selectedLanguage = defaultLanguage;
      this.updateSelectedLanguageIcon();
    }
  
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible; // Toggle visibility
    }
  
    switchLanguage(language: string) {
      this.translate.use(language);
      localStorage.setItem('selectedLanguage', language);
      this.selectedLanguage = language;
      this.updateSelectedLanguageIcon();
      this.dropdownVisible = false; // Close the dropdown after selection
    }
  
    private updateSelectedLanguageIcon() {
      const foundLanguage = this.languages.find(lang => lang.value === this.selectedLanguage);
      this.selectedLanguageIcon = foundLanguage ? foundLanguage.icon : '';
    }
  
    languages = [
      { label: 'Ελληνικά', value: 'gr', icon: '../../assets/i18n/icons/gr.png' },
      { label: 'English', value: 'en', icon: '../../assets/i18n/icons/en.png' }, 
    ];

    get dark(): boolean {
        return this.layoutService.config().colorScheme !== 'light';
    }
}
