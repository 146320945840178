import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import Talk from 'talkjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ChatService } from '../../services/chat/chat.service';

@Component({
  selector: 'app-inbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inbox.component.html',
  styleUrl: './inbox.component.css',
})
export class InboxComponent implements OnInit, OnDestroy {
  chatbox!: Talk.Chatbox;
  private session!: Talk.Session;
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  constructor(
    private talkService: ChatService,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
   this.createInbox();
  }

  private async createInbox(user?: any) {
    const session = await this.talkService.createCurrentSession();
    this.chatbox = await this.talkService.createInbox(session);
    this.chatbox.mount(this.talkjsContainer.nativeElement);
  }

  ngOnDestroy() {
    if (this.chatbox) {
      this.chatbox.destroy();
    }
  }
}
