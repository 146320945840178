import { Component, OnInit, Type } from '@angular/core';
import { ImportsModule } from 'shared';
import { CheckboxModule } from 'primeng/checkbox';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { IncidentComponent } from '../../../../../insured-app/src/app/pages/incident/incident.component';
import { IncidentDateComponent } from './incident-date/incident-date.component';
import { PatientDiagnosisComponent } from './incident-date/patient-diagnosis/patient-diagnosis.component';
import { FilesComponent } from './incident-date/files/files.component';
import { HealthRecordComponent } from './incident-date/health-record/health-record.component';
import { AuditsComponent } from './audits/audits.component';
import { ImportsComponent } from './imports/imports.component';
import { GuaranteeLettersComponent } from './guarantee-letters/guarantee-letters.component';
import { OrganizationComponent } from './organization/organization.component';
import { ProformaInvoiceComponent } from './proforma-invoice/proforma-invoice.component';
import { DeductionsListComponent } from './deductions-list/deductions-list.component';
import { ArbitrationDocumentsComponent } from './arbitration/arbitration-documents/arbitration-documents.component';
import { ArbitrationComponent } from './arbitration/arbitration.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-claim-details',
  standalone: true,
  imports: [
    ImportsModule,
    CheckboxModule,
    PatientInformationComponent,
    IncidentComponent,
    IncidentDateComponent,
    PatientDiagnosisComponent,
    FilesComponent,
    HealthRecordComponent,
    AuditsComponent,
    ImportsComponent,
    GuaranteeLettersComponent,
    OrganizationComponent,
    ProformaInvoiceComponent,
    DeductionsListComponent,
    ArbitrationDocumentsComponent,
    ArbitrationComponent,
  ],
  templateUrl: './claim-details.component.html',
  styleUrl: './claim-details.component.scss',
})
export class ClaimDetailsComponent implements OnInit {
  currentComponent: Type<any> | null = PatientInformationComponent;
  activeMenuItem: string = '';

  items: MenuItem[] = [
    {
      label: 'Στοιχεία Ασθενούς',
      command: () => this.onMenuItemClick('Ασθενής'),
    },
    {
      label: 'Περιστατικό',
      command: () => this.onMenuItemClick('Περιστατικό'),
    },
    {
      label: 'Έλεγχοι',
      command: () => this.onMenuItemClick('Έλεγχοι'),
    },
    {
      label: 'Τιμολόγια',
      command: () => this.onMenuItemClick('Τιμολόγια'),
    },
    {
      label: 'Περικοπές',
      command: () => this.onMenuItemClick('Περικοπές'),
    },
    {
      label: 'Ασφαλιστήριο Συμβόλαιο',
      command: () => this.onMenuItemClick(''),
    },
    {
      label: 'Ιστορικό Περιστατικών',
      command: () => this.onMenuItemClick('Ιστορικό Περιστατικών'),
    },

    {
      label: 'Διαιτησία',
      command: () => this.onMenuItemClick('Διαιτησία'),
    },

    // {
    //   label: 'Φορείς',
    //   command: () => this.onMenuItemClick('Φορείς'),
    // },
    // {
    //   label: 'Εγγυητικές',
    //   command: () => this.onMenuItemClick('Εγγυητικές'),
    // },
  ];

  componentMapping: { [key: string]: Type<any> } = {
    Ασθενής: PatientInformationComponent,
    Περιστατικό: IncidentDateComponent,
    Έλεγχοι: AuditsComponent,
    Τιμολόγια: ProformaInvoiceComponent,
    Περικοπές: DeductionsListComponent,
    // 'Ασφαλιστήριο Συμβόλαιο': ,
    'Ιστορικό Περιστατικών': ImportsComponent,
    Διαιτησία: ArbitrationComponent,
    // 'Πρακτικά Διαιτησίας': ArbitrationDocumentsComponent,
    // Φορείς: OrganizationComponent,
    // Εγγυητικές: GuaranteeLettersComponent,
  };

  constructor() {}

  ngOnInit(): void {}

  onMenuItemClick(menuItem: string) {
    this.activeMenuItem = menuItem;
    this.currentComponent = this.getComponent(menuItem); // Loop through the menu items and assign 'active-menu-item' class to the selected item
    this.items.forEach((menuItem) => {
      if (menuItem.label === this.activeMenuItem) {
        menuItem.styleClass = 'active-menu-item';
      } else {
        menuItem.styleClass = '';
      }
    });
  }

  getComponent(key: string): Type<any> | null {
    return this.componentMapping[key] || null;
  }
}
