import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MedicalRecordService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}medicalRecord/`;

  constructor(private http: HttpClient) {}

  // DIAGNOSIS DATA
  getDiagnosisData(): Observable<any> {
    return of([
      {
        id: 1,
        date: '13/02/2023',
        code: 'Ι49',
        description: 'Άλλες καρδιακές αρρυθμίες',
        origin: 'G91',
        type: 'Διαγνωστική Πράξη',
      },
      {
        id: 2,
        date: '20/03/2023',
        code: 'J20',
        description: 'Οξεία βρογχίτιδα',
        origin: 'R95',
        type: 'Θεραπευτική Πράξη',
      },
      {
        id: 3,
        date: '15/04/2023',
        code: 'K21',
        description: 'Γαστροοισοφαγική παλινδρόμηση',
        origin: 'K44',
        type: 'Διαγνωστική Πράξη',
      },
      {
        id: 4,
        date: '10/05/2023',
        code: 'E11',
        description: 'Σακχαρώδης διαβήτης τύπου 2',
        origin: 'E10',
        type: 'Παρακολούθηση',
      },
      {
        id: 5,
        date: '25/06/2023',
        code: 'N39',
        description: 'Άλλες διαταραχές του ουροποιητικού συστήματος',
        origin: 'N40',
        type: 'Θεραπευτική Πράξη',
      },
    ]);
  }
}
