import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ImportsModule, IncidentService } from 'shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incident',
  standalone: true,
  imports: [ImportsModule],
  providers: [ConfirmationService],
  templateUrl: './incident.component.html',
  styleUrl: './incident.component.scss',
})
export class IncidentComponent {
  loading: boolean = true;

  rows: number = 10; // Number of rows per page
  subscriptions: Subscription[] = [];
  dataLoaded: boolean = false;

  data: any;

  dropdownParochosOptions = [
    { name: 'Mediterraneo Hospital​', code: 'MediterraneoHospital​' },
    { name: 'Other', code: 'other' },
  ];

  dropdownEidosPeristatikouOptions = [
    { name: 'Επείγον​', code: 'epeigon' },
    { name: 'Προγραμματισμένο', code: 'programmatismeno' },
  ];

  dropdownAsthenoforoOptions = [
    { name: 'Ναι', code: 'nai' },
    { name: 'Όχι', code: 'oxi' },
  ];

  dropdownExamsOptions = [];

  constructor(
    private confirmationService: ConfirmationService,
    private incidentService: IncidentService
  ) {}
  ngOnInit() {
    this.loading = false;
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchData() {
    const incidentSubscription = this.incidentService
      .getAllIncidents()
      .subscribe({
        next: (result) => {
          if (result) {
            this.data = result;
            this.data.sort((a: any, b: any) => {
              const dateA = new Date(
                a.startDate.split('/').reverse().join('-')
              ).getTime();
              const dateB = new Date(
                b.startDate.split('/').reverse().join('-')
              ).getTime();
              return dateB - dateA;
            });
          }
        },
        error: (error) => {
          console.error('Error fetching centers:', error);
        },
        complete: () => {
          this.dataLoaded = true;
        },
      });

    this.subscriptions.push(incidentSubscription);
  }

  get isSinglePage() {
    return this.data.length <= this.rows;
  }

  confirm1() {
    this.confirmationService.confirm({
      key: 'confirm1',
      message: 'Are you sure to perform this action?',
    });
  }

  getIncidentBackgroundColor(incident: string) {
    if (incident == 'Σοβαρό' || incident == 'Επείγον') {
      return 'bg-red-200 font-bold p-2 border-round';
    } else {
      return 'bg-green-200 font-bold p-2 border-round';
    }
  }

  getIncidentIcon(incident: string) {
    if (incident == 'Σοβαρό' || incident == 'Επείγον') {
      return 'pi pi-sort-amount-up';
    } else {
      return 'pi pi-sort-amount-down-alt';
    }
  }

  getAmbulanceBackgroundColor(value: string) {
    if (value.toLowerCase() == 'όχι') {
      return 'bg-yellow-200 font-bold p-2 border-round';
    } else {
      return 'bg-green-200 font-bold p-2 border-round';
    }
  }

  getStatusBackgroundColor(status: string) {
    if (status.toLowerCase() == 'αναμονή') {
      return 'bg-red-200 font-bold p-2 border-round';
    } else if (status.toLowerCase() == 'ολοκληρώθηκε') {
      return 'bg-green-200 font-bold p-2 border-round';
    } else {
      return 'bg-yellow-200 font-bold p-2 border-round';
    }
  }
}
