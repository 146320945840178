import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

interface Claim {
  status: string;
  auditorClaimId: string;
  patientName: string;
  insuranceCompany: string;
  policyNumbers: string[];
  healthProvider: string;
  incidentDate: Date;
  incidentType: string;
  admissionStatus: string;
}

@Component({
  selector: 'app-claims-table',
  standalone: true,
  imports: [CommonModule, RouterLink, TableModule, FormsModule, InputTextModule],
  templateUrl: './claims-table.component.html',
  styleUrl: './claims-table.component.scss'
})
export class ClaimsTableComponent {
  statuses = [
    {
      label: 'Σε εκκρεμότητα',
      icon: 'pi pi-lock-open'
    },
    {
      label: 'Υπό έλεγχο/επεξεργασία',
      icon: 'pi pi-unlock'
    },
    {
      label: 'Υπό διαπραγμάτευση για πληρωμή',
      icon: 'pi pi-clock'
    },
    {
      label: 'Ολοκληρωμένες',
      icon: 'pi pi-check'
    }
  ];

  selectedStatus: string | null = null;
  // selectedStatus: string = 'Σε εκκρεμότητα';

  searchText: string = '';
  
  claims: Claim[] = [
    {
      status: 'Σε εκκρεμότητα',
      auditorClaimId: 'ID123',
      patientName: 'Γιάννης Παπαδόπουλος',
      insuranceCompany: 'Alpha Insurance',
      policyNumbers: ['P123456', 'P654321'],
      healthProvider: 'Υγεία Hospital',
      incidentDate: new Date('2023-05-12'),
      incidentType: 'Παθολογικό',
      admissionStatus: 'Σε εξέλιξη'
    },
    {
      status: 'Σε εκκρεμότητα',
      auditorClaimId: 'ID124',
      patientName: 'Μαρία Κωνσταντίνου',
      insuranceCompany: 'Beta Assurance',
      policyNumbers: ['P987654'],
      healthProvider: 'Metropolitan Clinic',
      incidentDate: new Date('2023-06-01'),
      incidentType: 'Χειρουργικό',
      admissionStatus: 'Με εξιτήριο'
    },
    {
      status: 'Σε εκκρεμότητα',
      auditorClaimId: 'ID125',
      patientName: 'Ανδρέας Νικολάου',
      insuranceCompany: 'Gamma Health',
      policyNumbers: ['P223344', 'P556677'],
      healthProvider: 'Ασκληπιείο Hospital',
      incidentDate: new Date('2023-07-18'),
      incidentType: 'Παθολογικό',
      admissionStatus: 'Σε εξέλιξη'
    },
    
    {
      status: 'Υπό έλεγχο/επεξεργασία',
      auditorClaimId: 'ID126',
      patientName: 'Κώστας Γεωργίου',
      insuranceCompany: 'Delta Insurance',
      policyNumbers: ['P334455'],
      healthProvider: 'Ιπποκράτειο',
      incidentDate: new Date('2023-05-22'),
      incidentType: 'Χειρουργικό',
      admissionStatus: 'Με εξιτήριο'
    },
    {
      status: 'Υπό έλεγχο/επεξεργασία',
      auditorClaimId: 'ID127',
      patientName: 'Ελένη Δημητρίου',
      insuranceCompany: 'Alpha Insurance',
      policyNumbers: ['P778899'],
      healthProvider: 'Metropolitan Clinic',
      incidentDate: new Date('2023-06-10'),
      incidentType: 'Παθολογικό',
      admissionStatus: 'Σε εξέλιξη'
    },

    {
      status: 'Υπό διαπραγμάτευση για πληρωμή',
      auditorClaimId: 'ID128',
      patientName: 'Νικόλας Χριστοδούλου',
      insuranceCompany: 'Epsilon Health',
      policyNumbers: ['P998877'],
      healthProvider: 'Ιπποκράτειο',
      incidentDate: new Date('2023-07-05'),
      incidentType: 'Χειρουργικό',
      admissionStatus: 'Με εξιτήριο'
    },
    {
      status: 'Υπό διαπραγμάτευση για πληρωμή',
      auditorClaimId: 'ID129',
      patientName: 'Άννα Παπαδημητρίου',
      insuranceCompany: 'Gamma Health',
      policyNumbers: ['P554433'],
      healthProvider: 'Υγεία Hospital',
      incidentDate: new Date('2023-08-15'),
      incidentType: 'Παθολογικό',
      admissionStatus: 'Σε εξέλιξη'
    },

    {
      status: 'Ολοκληρωμένες',
      auditorClaimId: 'ID130',
      patientName: 'Θεόδωρος Καραλής',
      insuranceCompany: 'Zeta Insurance',
      policyNumbers: ['P665544'],
      healthProvider: 'Metropolitan Clinic',
      incidentDate: new Date('2023-04-20'),
      incidentType: 'Χειρουργικό',
      admissionStatus: 'Με εξιτήριο'
    },
    {
      status: 'Ολοκληρωμένες',
      auditorClaimId: 'ID131',
      patientName: 'Αφροδίτη Πέτρου',
      insuranceCompany: 'Delta Insurance',
      policyNumbers: ['P112233'],
      healthProvider: 'Ασκληπιείο Hospital',
      incidentDate: new Date('2023-02-28'),
      incidentType: 'Παθολογικό',
      admissionStatus: 'Με εξιτήριο'
    }
  ];

  showTable(status: string) {
    this.selectedStatus = this.selectedStatus === status ? null : status;
  }

  getFilteredClaims() {
    return this.claims.filter(claim =>
      (this.selectedStatus === null || claim.status === this.selectedStatus) &&
      (claim.auditorClaimId.toLowerCase().includes(this.searchText.toLowerCase()) ||
       claim.patientName.toLowerCase().includes(this.searchText.toLowerCase()) ||
       claim.insuranceCompany.toLowerCase().includes(this.searchText.toLowerCase()) ||
       claim.healthProvider.toLowerCase().includes(this.searchText.toLowerCase()) ||
       claim.policyNumbers.some(policy => policy.toLowerCase().includes(this.searchText.toLowerCase())))
    );
  }

  getStatusIconClass(status: string): { icon: string; colorClass: string } {
    switch (status) {
      case 'Σε εκκρεμότητα':
        return { icon: 'pi pi-lock-open', colorClass: 'status-icon-red' };
      case 'Υπό έλεγχο/επεξεργασία':
        return { icon: 'pi pi-unlock', colorClass: 'status-icon-yellow' };
      case 'Υπό διαπραγμάτευση για πληρωμή':
        return { icon: 'pi pi-clock', colorClass: 'status-icon-orange' };
      case 'Ολοκληρωμένες':
        return { icon: 'pi pi-check', colorClass: 'status-icon-green' };
      default:
        return { icon: '', colorClass: '' };
    }
  }
}
