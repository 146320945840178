import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import { delay, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExamsService {
  private categories = [
    {
      serviceCategoryCode: 1,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΕΡΓΑΣΤΗΡΙΑΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 2,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΑΠΕΙΚΟΝΙΣΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 3,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΕΝΔΟΣΚΟΠΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 4,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΚΑΡΔΙΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 5,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΝΕΥΡΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 6,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΟΦΘΑΛΜΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 7,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΠΝΕΥΜΟΝΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 8,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΔΙΑΦΟΡΑ',
    },
    {
      serviceCategoryCode: 9,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΟΡΛ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 10,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΙΣΤΟΛΟΓΙΚΕΣ-ΚΥΤΤΑΡΟΛΟΓΙΚΕΣ',
    },
    {
      serviceCategoryCode: 11,
      serviceCategoryCode2: null,
      serviceCategoryDescription: 'ΑΙΜΟΔΥΝΑΜΙΚΟ',
    },
  ];
  private subCategories = [
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 1,

      serviceSubcategoryDescription: 'ΑΙΜΑΤΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 2,

      serviceSubcategoryDescription: 'ΑΝΟΣΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 3,

      serviceSubcategoryDescription: 'ΒΙΟΧΗΜΙΚΕΣ-ΟΡΜΟΝΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 4,

      serviceSubcategoryDescription: 'ΜΙΚΡΟΒΙΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ - ΚΑΛΛΙΕΡΓΙΕΣ',
    },
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 5,

      serviceSubcategoryDescription: 'ΜΟΡΙΑΚΗ ΒΙΟΛΟΓΙΑ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 6,

      serviceSubcategoryDescription: 'TRIPLEX',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 7,

      serviceSubcategoryDescription: 'ΑΞΟΝΙΚΕΣ ΤΟΜΟΓΡΑΦΙΕΣ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 8,

      serviceSubcategoryDescription: 'ΜΑΓΝΗΤΙΚΕΣ ΤΟΜΟΓΡΑΦΙΕΣ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 9,

      serviceSubcategoryDescription: 'ΥΠΕΡΗΧΟΙ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 10,

      serviceSubcategoryDescription: 'ΑΚΤΙΝΟΓΡΑΦΙΕΣ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 11,

      serviceSubcategoryDescription: 'ΜΑΣΤΟΓΡΑΦΙΕΣ',
    },
    {
      serviceCategoryCode: 2,
      serviceSubcategoryCode: 12,

      serviceSubcategoryDescription: 'DEXA',
    },
    {
      serviceCategoryCode: 3,
      serviceSubcategoryCode: 13,

      serviceSubcategoryDescription: 'ΕΝΔΟΣΚΟΠΙΣΕΙΣ',
    },
    {
      serviceCategoryCode: 4,
      serviceSubcategoryCode: 14,

      serviceSubcategoryDescription: 'TRIPLEX ΚΑΡΔΙΑΣ',
    },
    {
      serviceCategoryCode: 4,
      serviceSubcategoryCode: 15,

      serviceSubcategoryDescription: 'ΚΑΡΔΙΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 5,
      serviceSubcategoryCode: 16,

      serviceSubcategoryDescription: 'ΝΕΥΡΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 6,
      serviceSubcategoryCode: 17,

      serviceSubcategoryDescription: 'ΟΦΘΑΛΜΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 7,
      serviceSubcategoryCode: 18,

      serviceSubcategoryDescription: 'ΠΝΕΥΜΟΝΟΛΟΓΙΚΕΣ ΕΞΕΤΑΣΕΙΣ',
    },
    {
      serviceCategoryCode: 8,
      serviceSubcategoryCode: 19,

      serviceSubcategoryDescription: 'ΔΙΑΦΟΡΑ',
    },
    {
      serviceCategoryCode: 9,
      serviceSubcategoryCode: 20,

      serviceSubcategoryDescription: 'ΩΡΛ',
    },
    {
      serviceCategoryCode: 1,
      serviceSubcategoryCode: 21,

      serviceSubcategoryDescription: 'ΙΣΤΟΛΟΓΙΚΕΣ-ΚΥΤΤΑΡΟΛΟΓΙΚΕΣ',
    },
    {
      serviceCategoryCode: 10,
      serviceSubcategoryCode: 22,

      serviceSubcategoryDescription: 'ΑΙΜΟΔΥΝΑΜΙΚΟ',
    },
  ];
  private exams = [
    {
      serviceCode: 1,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΠΡΟΛΗΠΤΙΚΗ ΕΞΕΤΑΣΗ ΓΙΑ ΔΟΚΙΜΑΣΙΑ ΔΡΕΠΑΝΩΣΕΩΣ',

      eopyyCode: 550000023,
    },
    {
      serviceCode: 2,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΠΡΟΛΗΠΤΙΚΗ ΓΕΝΙΚΗ ΑΙΜΑΤΟΣ',

      eopyyCode: 550000003,
    },
    {
      serviceCode: 3,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΠΡΩΤΕΙΝΗ-S',

      eopyyCode: 130000291,
    },
    {
      serviceCode: 4,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'C1 ΛΕΙΤΟΥΡΓΙΚΟΣ ΑΝΑΣΤΟΛΕΑΣ',

      eopyyCode: 130000024,
    },
    {
      serviceCode: 5,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΙΝΩΔΟΝΕΚΤΙΝΗ ΠΟΣΟΤΙΚΗ ΜΕΤΡΗΣΗ',

      eopyyCode: 110000268,
    },
    {
      serviceCode: 6,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΠΟΣΟΤΙΚΗ ΜΕΤΡΗΣΗ ΑΝΤΙΘΡΟΜΒΙΝΗΣ ΙΙΙ',

      eopyyCode: 110000267,
    },
    {
      serviceCode: 7,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΠΡΩΤΕΙΝΗ-C',

      eopyyCode: 110000254,
    },
    {
      serviceCode: 8,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΕΡΥΘΡΟΠΟΙΗΤΙΝΗ',

      eopyyCode: 110000148,
    },
    {
      serviceCode: 9,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΟΞΥΑΙΜΟΣΦΑΙΡΙΝΗΣ ΚΑΜΠΥΛΗ ΚΟΡΕΣΜΟΥ',

      eopyyCode: 110000135,
    },
    {
      serviceCode: 10,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΧΡΟΝΟΣ ΡΟΗΣ ΚΑΤΑ LEE-WHITE',

      eopyyCode: 110000126,
    },
    {
      serviceCode: 11,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΧΡΟΝΟΣ ΗOWELL',

      eopyyCode: 110000125,
    },
    {
      serviceCode: 12,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr:
        'D-DIΜERS - ΠΡΟΙΟΝΤΑ ΑΠΟΔΟΜΗΣ ΙΝΩΔΟΥΣ D-DIMERS(FDP)',

      eopyyCode: 110000119,
    },
    {
      serviceCode: 13,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΕΡΥΘΡΟΒΛΑΣΤΕΣ (ΑΝΑΖΗΤΗΣΗ)',

      eopyyCode: 110000117,
    },
    {
      serviceCode: 14,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΧΡΟΝΟΣ ΡΟΗΣ',

      eopyyCode: 110000111,
    },
    {
      serviceCode: 15,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'Τ.Κ.Ε.',

      eopyyCode: 110000110,
    },
    {
      serviceCode: 16,

      serviceSubcategoryCode: 1,

      serviceDescriptionGr: 'ΓΕΝΙΚΗ ΑΙΜΑΤΟΣ',

      eopyyCode: 110000107,
    },
  ];

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}`;

  constructor(private http: HttpClient) {}
  getExamCategories() {
    return of(this.categories).pipe(delay(500));
  }
  getExamSubcategories(categoryId: number) {
    const cat = this.subCategories.filter(
      (subCat) => subCat.serviceCategoryCode === categoryId
    );
    return of(cat).pipe(delay(500));
  }
  getExams(subCategoryId: number) {
    const cat = this.exams.filter(
      (subCat) => subCat.serviceSubcategoryCode === subCategoryId
    );
    return of(cat).pipe(delay(500));
  }

  getExamTypes() {
    return this.http.get<any>(`${this.apiUrl}exams/types`, this.httpOptions);
  }
  getExamReferencesByUserId(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs`,
      this.httpOptions
    );
  }
  getICD10List() {
    return this.http.get<any>(`${this.apiUrl}exams/icd10s`, this.httpOptions);
  }
  getICD10ByNameOrCode(
    ICD10Name: string | undefined,
    code: string | undefined
  ) {
    if (ICD10Name) {
      return this.http.get<any>(
        `${this.apiUrl}exams/icd10s?name=${ICD10Name}`,
        this.httpOptions
      );
    } else {
      return this.http.get<any>(
        `${this.apiUrl}exams/icd10s?code=${code}`,
        this.httpOptions
      );
    }
  }

  getExamRefByUserAndClinicId(
    userId: number,
    clinicId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs/${clinicId}`,
      this.httpOptions
    );
  }
  addExamResult(examObject: any) {
    return this.http.post<any>(
      `${this.apiUrl}exams/results`,
      examObject,
      this.httpOptions
    );
  }
  // returns list of exams in insured-app
  getPatientExamResults(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examResults`,
      this.httpOptions
    );
  }

  createExams(exams: any) {
    return this.http.post<any>(`${this.apiUrl}exams/new`, exams);
  }
}
