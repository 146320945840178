@if (chartOptions?.series) {

<apx-chart
  [series]="chartOptions.series"
  [labels]="chartOptions.labels"
  [chart]="chartOptions.chart"
  [xaxis]="chartOptions.xaxis"
  [yaxis]="chartOptions.yaxis"
  [plotOptions]="chartOptions.plotOptions"
  [title]="chartOptions.title"
  [colors]="chartOptions.colors"
></apx-chart
>}
