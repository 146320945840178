<div
  class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
  style="min-width: 17rem"
>
  <h5 class="mb-1 m-0 text-white text-center">
    @if(data().headerIcon){
    <i
      [ngClass]="data().headerIcon"
      class="font-bold text-2xl text-white mr-2"
    ></i>
    } @for(header of data().header;track $index;){
    <span>{{ header }}</span
    ><br />
    }
  </h5>
  @if(data().pipe=="number"){

  <h1 class="my-3 m-0 text-white">
    {{ data().dataCount | number }}
  </h1>
  }@else {

  <h1 class="my-3 m-0 text-white">{{ data().dataCount }}</h1>
  }
  <button
    pButton
    class="text-gray-800 text-xl w-full flex justify-content-center"
    style="background-color: white"
    [routerLink]="data().button.routerLink"
  >
    {{ data().button.label }}
  </button>
</div>
