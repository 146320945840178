import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-health-record',
  standalone: true,
  imports: [InputTextareaModule, CheckboxModule, FormsModule,CalendarModule],
  templateUrl: './health-record.component.html',
  styleUrl: './health-record.component.scss',
})
export class HealthRecordComponent {
  checkBox: boolean = false;
}
