<div class="font-medium text-3xl text-900 mb-3 mt-3">Ομαδοποιημένα Σύνολα</div>
<p-table
  [value]="serviceCategories"
  dataKey="id"
  [tableStyle]="{ 'min-width': '60rem' }"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="category">
        Κατηγορίες Υπηρεσιών (Νοσοκομείου) <p-sortIcon field="category" />
      </th>
      <th pSortableColumn="netAmount">
        Καθαρό Ποσό <p-sortIcon field="netAmount" />
      </th>
      <th pSortableColumn="credit">Πίστωση <p-sortIcon field="credit" /></th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-service>
    <tr>
      <td>{{ service.category }}</td>
      <td>{{ service.netAmount | currency : "EUR" }}</td>
      <td>{{ service.credit | currency : "EUR" }}</td>
    </tr>
  </ng-template>
</p-table>
