<div class="surface-section">
  <div class="font-medium text-3xl text-900 mb-3">Βιβλιάρια</div>
  <div class="text-500 mb-5">
    <!-- Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum. -->
  </div>
  <ul class="list-none p-0 m-0">
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Φορέας Υγείας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        ΕΟΠΥΥ - ΟΑΕΕ
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Κατάσταση Βιβλιαρίου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        Έγκυρο - Δεν ελέγχθηκε - Συνεστήθη να προσκομισθεί
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Ημερομηνία Έκδοσης Βιβλιαρίου:
      </div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-calendar
          dateFormat="dd.mm.yy"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder=""
          disabled
        />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Συμμετοχή Ασφ.Ταμείου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap bg-red-100"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Δεν επιθυμεί χρήση:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap bg-red-100"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Επιπλέον, δηλώνω ότι δεν επιθυμώ τη χρήση του δημοσίου φορέα διότι
      </div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true">
        </textarea>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap bg-blue-100"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Δεν επιθυμεί έλεγχο:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap bg-blue-100"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Επιπλέον, δηλώνω ότι δεν επιθυμώ τον έλεγχο του βιβλιαρίου διότι:
      </div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true">
        </textarea>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Σημειώσεις Βιβλιαρίου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true">
        </textarea>
      </div>
    </li>
  </ul>
</div>