import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}incidents/`;

  constructor(private http: HttpClient) {}

  getAllIncidents(): Observable<any> {
    return of(
      [
        {
          id: 1,
          startDate: '13/02/2023',
          endDate: '13/02/2023',
          insuranceContracts: '32145675 | 124674469',
          incident: 'Επείγον',
          ambulance: 'Ναι',
          status: 'Σε εξέλιξη',
        },
        {
          id: 2,
          startDate: '20/03/2023',
          endDate: '20/03/2023',
          insuranceContracts: '98765432 | 123456789',
          incident: 'Κανονικό',
          ambulance: 'Όχι',
          status: 'Ολοκληρώθηκε',
        },
        {
          id: 3,
          startDate: '15/04/2023',
          endDate: '15/04/2023',
          insuranceContracts: '87654321 | 987654321',
          incident: 'Σοβαρό',
          ambulance: 'Ναι',
          status: 'Αναμονή',
        },
      ]);
  }
}
