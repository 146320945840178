import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-information',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './patient-information.component.html',
  styleUrl: './patient-information.component.scss'
})
export class PatientInformationComponent {
  patientInfo = [
    { label: 'Επώνυμο', value: 'ΚΑΡΑΣΤΑΘΗ' },
    { label: 'Όνομα', value: 'ΧΡΥΣΟΥΛΑ' },
    { label: 'Πατρώνυμο', value: 'ΚΩΝΣΤΑΝΤΙΝΟΣ' },
    { label: 'Μητρώνυμο', value: 'ΚΩΝΣΤΑΝΤΙΝΑ' },
    { label: 'Ημ. Γέννησης:', value: '30/10/1976' },
    { label: 'Α.Μ.Κ.Α', value: '21060101587' },
    { label: 'Αρ. Ταυτότητας', value: 'Χ154254' },
    { label: 'Χέρι Προτίμησης', value: 'Δεξί' }
  ];

  contactInfo = [
    { label: 'Τηλέφωνο', value: '6955886688' },
    { label: 'Email', value: 'karastathi@co.gr' },
  ];

  homeAddress = [
    { label: 'Οδός', value: 'Βουλής 7' },
    { label: 'Περιοχή', value: 'Σύνταγμα' },
    { label: 'Νομός', value: 'Αττικής' },
    { label: 'Τ.Κ.', value: '21820' }
  ];

  workAddress = [
    { label: 'Οδός', value: 'Βουλής 7' },
    { label: 'Περιοχή', value: 'Σύνταγμα' },
    { label: 'Νομός', value: 'Αττικής' },
    { label: 'Τ.Κ.', value: '21820' }
  ];
}
