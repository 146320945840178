import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  AuthService,
  MemberService,
  MenuDataService,
} from 'shared';
import { RouterOutlet } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private primengConfig = inject(PrimeNGConfig);
  private menuDataService = inject(MenuDataService);
  private memberService = inject(MemberService);
  private authService = inject(AuthService);

  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Προγράμματα Κάλυψης',
          icon: 'pi pi-fw pi-info-circle',
          routerLink: ['coverage-programs'],
        },
        {
          label: 'Απαιτήσεις',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/claims-table'],
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'doctor';
    const user = JSON.parse(localStorage.getItem('token')!);
    if (user) {
      this.authService.fetchUser(user).subscribe(() => {});
    }
  }
}
