<p-table
  [value]="invoices"
  dataKey="id"
  [tableStyle]="{ 'min-width': '80rem' }"
  [expandedRowKeys]="expandedRows"
  selectionMode="single"
  [(selection)]="selectedInvoice"
  (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem"></th>
      <th pSortableColumn="insuranceCompany">
        Ασφαλιστική Εταιρεία <p-sortIcon field="insuranceCompany" />
      </th>
      <th>Ημερ.Έκδοσης</th>
      <th pSortableColumn="netAmount">
        Καθαρό Ποσό <p-sortIcon field="netAmount" />
      </th>
      <th pSortableColumn="vat">Φ.Π.Α <p-sortIcon field="vat" /></th>
      <th pSortableColumn="grossAmount">
        Μικτό Ποσό <p-sortIcon field="grossAmount" />
      </th>
      <th pSortableColumn="patientAmount">
        Ποσό Ασθενή <p-sortIcon field="patientAmount" />
      </th>
      <th pSortableColumn="fundCharge">
        Χρέωση Ταμείου <p-sortIcon field="fundCharge" />
      </th>
      <th pSortableColumn="totalPatientParticipation">
        Σύνολο Συμμετοχής Ασθενούς και Τρίτων
        <p-sortIcon field="totalPatientParticipation" />
      </th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-invoice let-expanded="expanded">
    <tr [pSelectableRow]="invoice">
      <td>
        <p-button
          type="button"
          pRipple
          [pRowToggler]="invoice"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        />
      </td>
      <td>{{ invoice.insuranceCompany }}</td>
      <td>{{ invoice.issueDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ invoice.netAmount | currency : "EUR" }}</td>
      <td>{{ invoice.vat | percent }}</td>
      <td>{{ invoice.grossAmount | currency : "EUR" }}</td>
      <td>{{ invoice.patientAmount | currency : "EUR" }}</td>
      <td>{{ invoice.fundCharge | currency : "EUR" }}</td>
      <td>{{ invoice.totalPatientParticipation | currency : "EUR" }}</td>
    </tr>
  </ng-template>

  <!-- Row Expansion Template -->
  <ng-template pTemplate="rowexpansion" let-invoice>
    <tr>
      <td colspan="10">
        <div class="p-3">
          <p-table [value]="[invoice.invoiceDetails]" dataKey="id">
            <ng-template pTemplate="header">
              <tr>
                <th>Έγγραφο</th>
                <th>eΤιμολόγιο</th>
                <th>Λάθος</th>
                <th>Άκυρο</th>
                <th>Ημ. Δημιουργίας</th>
                <th>Χρήστης Δημιουργίας</th>
                <th>Ημ. Τελευταίας Αλλαγής</th>
                <th>Χρήστης Τελευταίας Αλλαγής</th>
                <th>ID</th>
                <th>Χαρακτηρισμός Τιμολογίου</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-detail>
              <tr>
                <td>{{ detail.document }}</td>
                <td>{{ detail.eInvoice }}</td>
                <td>{{ detail.error }}</td>
                <td>{{ detail.invalid }}</td>
                <td>{{ detail.creationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ detail.createdByUser }}</td>
                <td>{{ detail.lastChangeDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ detail.lastChangedByUser }}</td>
                <td>{{ detail.id }}</td>
                <td>{{ detail.invoiceClassification }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="10">No invoice details available.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

@if(selectedInvoice){

<ul class="list-none p-0 m-0">
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <div class="col-2">
      <span class="text-900 text-lg font-bold">Προτιμολόγιο</span>
    </div>

    <div class="col-10 gap-2">
      <span class="text-700 text-lg font-medium mr-3">Ασφ.Κλάδος:</span>
      <span
        class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >{{ selectedInvoice.insuranceCompany }}</span
      >
    </div>
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- start row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Ζημιά:</span>
    </div>
    <div class="col-3">
      <span
        class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >{{ selectedInvoice.insuranceCompany }}</span
      >
    </div>
    <div class="col-7">
      <span
        class="text-700 text-lg font-medium bg-primary-100 p-2 border-round-sm"
        >{{ selectedInvoice.insuranceCompany }}</span
      >
    </div>
    <!-- end row -->
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start Row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Ημερ.Έκδοσης:</span>
    </div>
    <div class="col-3">
      <span>
        <p-calendar
          dateFormat="dd.mm.yy"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="8/12/2020"
          disabled
        />
      </span>
    </div>
    <div class="col-1">
      <span class="text-700 text-lg font-medium mr-3">Εκδότης:</span>
    </div>
    <div class="col-2">
      <span class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >Νοσοκομείο</span
      >
    </div>

    <div class="col-1">
      <span class="text-700 text-lg font-medium mr-3">Ιατρός:</span>
    </div>
    <div class="col-3 bg-gray-100 p-3 border-round-sm"></div>
    <!-- End Row -->
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start Row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Καθαρό Ποσό:</span>
    </div>
    <div class="col-2">
      <span
        class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >{{ selectedInvoice.netAmount | currency : "EUR" }}</span
      >
    </div>

    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Ποσό Ασθενή</span>
    </div>
    <div class="col-2">
      <span
        class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >{{ selectedInvoice.patientAmount | currency : "EUR" }}</span
      >
    </div>

    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Χρέωση Ταμείου </span>
    </div>
    <div class="col-2">
      <span
        class="text-700 text-lg font-medium bg-gray-100 p-2 border-round-sm"
        >{{ selectedInvoice.fundCharge | currency : "EUR" }}</span
      >
    </div>
    <!-- End Row -->
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start Row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Εκδότης(ΝΠ)</span>
    </div>
    <div class="col-10">
      <textarea rows="10" cols="60" pInputTextarea [autoResize]="true">
      </textarea>
    </div>
    <!-- End row -->
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Φ.Π.Α </span>
    </div>
    <div class="col-2 bg-gray-100 p-2 border-round-sm text-center">
      <span class="text-700 text-lg font-medium">{{
        selectedInvoice.vat | currency : "EUR"
      }}</span>
    </div>
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3"
        >Χρέωση άλλης ασφαλιστικής</span
      >
    </div>
    <div class="col-2 bg-gray-100 p-2 border-round-sm text-center">
      <span class="text-700 text-lg font-medium">{{
        selectedInvoice.vat | currency : "EUR"
      }}</span>
    </div>
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Αριθμός Τιμολογίου</span>
    </div>
    <div class="col-2 bg-gray-100 p-2 border-round-sm text-center">
      <span class="text-700 text-lg font-medium">{{
        selectedInvoice.invoiceDetails.document
      }}</span>
    </div>
  </li>
  <!-- End row -->
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3">Μικτό Ποσό: </span>
    </div>
    <div class="col-2 bg-gray-100 p-2 border-round-sm text-center">
      <span class="text-700 text-lg font-medium">{{
        selectedInvoice.grossAmount | currency : "EUR"
      }}</span>
    </div>
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3"
        >Αρχικό όνομα αρχείου</span
      >
    </div>
    <div class="col-6">
      <textarea
        rows="10"
        cols="60"
        pInputTextarea
        [autoResize]="true"
        ngModel="103104102123-12-05-2024.txt"
      >
      </textarea>
    </div>
    <!-- End row -->
  </li>
  <li
    class="grid align-items-center py-3 px-2 border-top-1 surface-border"
    style="grid-template-columns: 150px 1fr"
  >
    <!-- Start row -->
    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3"
        >Μηδενική χρέωση για την ασφαλιστική:</span
      >
    </div>
    <div class="col-2">
      <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
    </div>

    <div class="col-2">
      <span class="text-700 text-lg font-medium mr-3"
        >Έλεγχος Τιμών Ολοκληρωμένος:</span
      >
    </div>
    <div class="col-2">
      <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
    </div>

    <div class="col-2">
      <button pButton label="Αποθήκευση"></button>
    </div>
  </li>
  <!-- End row -->
</ul>
}

<div class="mt-5">
  <p-menubar [model]="menuItems"></p-menubar>
  @if(selectedMenuItem=='amountPerCategory'){
  <app-category-amount></app-category-amount>

  } @else if(selectedMenuItem=='invoiceRecordDetails'){

  <app-invoice-details></app-invoice-details>
  } @else if(selectedMenuItem=='groupedSums'){
  <app-grouped-sums></app-grouped-sums>

  } @else if(selectedMenuItem=='electronicFile'){
  <div class="font-medium text-3xl text-900 mb-3 mt-3">Ηλεκτρονικό Αρχείο</div>
  } @else if(selectedMenuItem=='packages'){
  <app-packages> </app-packages>
  }
</div>
