import { Component } from '@angular/core';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-coverage-programs',
  standalone: true,
  imports: [PanelModule],
  templateUrl: './coverage-programs.component.html',
  styleUrl: './coverage-programs.component.scss'
})
export class CoverageProgramsComponent {

}
