import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  // private apiUrl = 'localhost:8080/insurers/';
  private apiUrl: string = `${environment.api}`;
  // http://api.gateway.dev.phi-platform.gr/insurance/{{insurerId}}/contracts
  constructor(private http: HttpClient) {}

  getContractsByInsurerId(insurerId: number): Observable<any> {
    return this.http.get<any>(
      // `${this.apiUrl}contracts/${{ contractId }}`,
      `${this.apiUrl}insurance/${insurerId}/contracts`,
      this.httpOptions
    );
  }
}
