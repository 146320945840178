import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { AppSidebarComponent } from './app.sidebar.component';
import { ColorScheme, LayoutService } from './service/app.layout.service';
import { MessageService } from 'primeng/api';
import { NotificationsService } from '../../services/shared/notifications.service';
import { filter, Subject, Subscription } from 'rxjs';
import { MemberService } from '../../services/member/member.service';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ChatService } from '../../services/chat/chat.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  providers: [MessageService],
})
export class AppTopbarComponent implements AfterViewInit, OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
  @ViewChild('notification', { static: true }) notificationElement!: ElementRef;
  @ViewChild('dropdown') dropdown!: Dropdown; // Reference to the dropdown
  activeItem!: number;
  private subscriptions: Subscription = new Subscription();
  notificationCount: number = 0;
  sidebarVisible = false;
  newMessages: any[] = [];
  newNotifications: any[] = [];
  messageCount: number = 0;

  selectedLanguage: string;
  selectedLanguageIcon: string = '';
  dropdownVisible: boolean = false;

  languages = [
    { label: 'Ελληνικά', value: 'gr', icon: '../../assets/i18n/icons/gr.png' },
    { label: 'English', value: 'en', icon: '../../assets/i18n/icons/en.png' },
  ];
  appName: any;

  themeMode = 'pi pi-moon';
  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private messageService: MessageService,
    private memberService: MemberService,
    private notificationService: NotificationsService,
    private authService: AuthService,
    private translate: TranslateService,
    private chatService: ChatService,
    private router: Router
  ) {
    // const defaultLanguage = localStorage.getItem('selectedLanguage') || 'gr';
    const defaultLanguage = 'gr';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    this.selectedLanguage = defaultLanguage;
    this.updateSelectedLanguageIcon();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    // Subscribe to the current user
    this.appName = this.memberService.appName;
    // The structures of the json in the different apis for entities, is different. That's why I chose this way.
    let objName = this.appName;
    if (this.appName == 'insured' || this.appName == 'doctor') {
      objName = 'data';
    }
    console.log(this.appName);

    const userSubscription$ = this.authService.currentUserSubject
      .pipe(
        filter((user) => {
          return !!user;
        })
      ) // Only start polling if user is valid
      .subscribe((user) => {
        if (user) {
          // Start polling notifications for the user
          const userId = user[objName]?.userId;
          if (!(this.appName == 'insurers')) {
            const notificationSubscription$ = this.notificationService
              .startPolling(userId)
              .subscribe((notifications) => {
                if (notifications && notifications.data) {
                  console.log(notifications.data);
                  this.processNewNotifications(notifications.data);
                }
              });
          }
          console.log(objName);
          if (objName == 'data') {
            const messageSubscription$ = this.chatService
              .startPolling(userId)
              .subscribe(
                (conversations) => {
                  // Handle the fetched conversations
                  // console.log('Fetched Conversations:', conversations.data);
                  this.processNewMessages(conversations.data);
                },
                (error) => {
                  // Handle any error that occurs after retries are exhausted
                  console.error('Polling error:', error);
                }
              );

            this.subscriptions.add(messageSubscription$);
          }
          // this.subscriptions.add(notificationSubscription);
        }
      });

    this.subscriptions.add(userSubscription$);
  }

  ngOnDestroy(): void {
    this.notificationService.stopPollingForNotifications();
    // Unsubscribe from all observables to prevent memory leaks
    this.subscriptions.unsubscribe();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible; // Toggle visibility
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('selectedLanguage', language);
    this.selectedLanguage = language;
    this.updateSelectedLanguageIcon();
    this.dropdownVisible = false; // Close the dropdown after selection
  }

  private updateSelectedLanguageIcon() {
    const foundLanguage = this.languages.find(
      (lang) => lang.value === this.selectedLanguage
    );
    this.selectedLanguageIcon = foundLanguage ? foundLanguage.icon : '';
  }

  private processNewMessages(messages: any): void {
    // Ensure messages is also defined and not null
    if (!messages || !Array.isArray(messages)) {
      return;
    }
    // Process each message individually
    messages.forEach((msg: any) => {
      // Check if the message already exists in `this.newMessages`
      const existingMsgIndex = this.newMessages.findIndex(
        (existingMsg: any) => existingMsg.id === msg.id
      );

      if (existingMsgIndex !== -1) {
        // If the message already exists, update it
        this.newMessages[existingMsgIndex] = msg;
      } else {
        // If it's a new message, append it
        this.newMessages.push(msg);
      }
    });

    // Update the count of unread notifications after processing all messages
    this.updateMessageCount();
  }

  private updateMessageCount(): void {
    // console.log(this.newMessages);

    this.messageCount = this.newMessages.filter(
      (item: any) => item.unreadMessageCount >= 1
    ).length;
  }
  goToInbox() {
    // // Assuming this marks all messages as read (you might need to add this logic)
    this.messageCount = 0;

    // // Update the count of unread messages after marking them as read
    // this.updateMessageCount();
    this.router.navigate(['/messages']);
  }

  // Process and update notifications
  private processNewNotifications(notifications: any[]): void {
    const newNotifications = notifications
      .map((item) => {
        const exists = this.newNotifications.some(
          (msg) => msg.id === item.notificationId
        );
        if (!exists) {
          this.messageService.addAll([
            {
              severity: 'info',
              summary: 'Ειδοποίηση',
              detail: item.message,
            },
          ]);
          return {
            id: item.notificationId,
            severity: 'info',
            summary: '', // Add more details if needed
            detail: item.message,
            viewed: false,
          };
        }
        return null; // Skip duplicates
      })
      .filter(Boolean); // Remove null values
    // Append new unique notifications
    this.newNotifications = [...this.newNotifications, ...newNotifications];
    console.log('new', this.newNotifications);
    this.updateNotificationCount();
  }

  // Update the count of unread notifications
  private updateNotificationCount(): void {
    this.notificationCount = this.newNotifications.filter(
      (item) => !item.viewed
    ).length;
  }

  discardMessage(message: any): void {
    this.notificationService
      .deleteNotification([message.id])
      .subscribe((res) => {
        this.newNotifications = this.newNotifications.filter(
          (msg) => msg.id !== message.id
        );
        this.updateNotificationCount();
      });
  }

  clearAllNotifications(): void {
    const notificationIds = this.newNotifications.map((item) => item.id);
    this.notificationService
      .deleteNotification(notificationIds)
      .subscribe((res) => {
        this.newNotifications = [];
        this.notificationCount = 0;
      });
  }

  onSidebarClose(): void {
    // Mark all notifications as viewed
    this.newNotifications.forEach(
      (item) => ((item.viewed = true), (item.severity = 'secondary'))
    );
    this.updateNotificationCount();
    console.log(this.newNotifications);
    this.sidebarVisible = false;
  }

  showSidebar(): void {
    this.sidebarVisible = true;
    this.messageService.clear();
    // Optionally, we could refresh or manipulate newNotifications here, but avoid unnecessary copying.
    this.newNotifications = [...this.newNotifications.map((x) => ({ ...x }))];
  }

  logout() {
    this.notificationService.stopPollingForNotifications();
    this.authService.logout();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onSidebarButtonClick() {
    this.layoutService.showSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  get colorScheme(): ColorScheme {
    return this.layoutService.config().colorScheme;
  }
  set colorScheme(_val: ColorScheme) {
    this.layoutService.config.update((config) => ({
      ...config,
      colorScheme: _val,
    }));
    this.layoutService.config.update((config) => ({
      ...config,
      menuTheme: _val,
    }));
    const tobarTheme =
      this.layoutService.config().topbarTheme === 'transparent'
        ? 'transparent'
        : _val;

    this.layoutService.config.update((config) => ({
      ...config,
      topbarTheme: tobarTheme,
    }));
  }
  goToMessages() {
    window.open('/messages');
  }
  changeThemeMode() {
    if (this.colorScheme == 'light') {
      this.colorScheme = 'dark';
      this.themeMode = 'pi pi-sun';
    } else {
      this.colorScheme = 'light';
      this.themeMode = 'pi pi-moon';
    }
  }
}
