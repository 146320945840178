import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { AuthService } from '../../shared/src/lib/services/auth/auth.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from '../../shared/src/lib/services/auth/jwt.interceptor';
import { AppRoutingModule } from './app/app-routing.module';
import { AppLayoutModule } from 'shared';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../../shared/src/lib/translate.loader';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      // console.log('App Initializer');
      //@ts-ignore
      if (authService.isAuthenticated()) {
        resolve(true);
        // console.log('Authenticated');
      } else {
        // console.log('Not Authenticated');
        resolve(false);
        authService.logout();
      }
    });
  };
}



bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(AppRoutingModule, AppLayoutModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        // EventService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch((err) => console.error(err));
