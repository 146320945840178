<div class="font-medium text-3xl text-900 mb-5 mt-3">Διαιτησία</div>
<p-table
  [value]="arbitrations"
  dataKey="id"
  [tableStyle]="{ 'min-width': '80rem' }"
  [expandedRowKeys]="expandedRows"
  (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="arbitration">
        Διαιτησία <p-sortIcon field="arbitration" />
      </th>
      <th pSortableColumn="completed">
        Ολοκληρωμένη <p-sortIcon field="completed" />
      </th>
      <th pSortableColumn="branch">Κλάδος <p-sortIcon field="branch" /></th>
      <th pSortableColumn="dateOfEDENotification">
        Ημερ. Ειδοποιήσης Διενέργειας Ε.Δ
        <p-sortIcon field="dateOfEDENotification" />
      </th>
      <th pSortableColumn="dateOfInsuranceEDENotification">
        Ημερ. Ειδοποιήσης Ασφαλιστικής Διενέργειας Ε.Δ
        <p-sortIcon field="dateOfInsuranceEDENotification" />
      </th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-arbitration let-expanded="expanded">
    <tr>
      <td>
        <p-button
          type="button"
          pRipple
          [pRowToggler]="arbitration"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        />
      </td>
      <td>{{ arbitration.arbitration }}</td>
      <td>{{ arbitration.completed }}</td>
      <td>{{ arbitration.branch }}</td>
      <td>{{ arbitration.dateOfEDENotification | date : "dd/MM/yyyy" }}</td>
      <td>
        {{ arbitration.dateOfInsuranceEDENotification | date : "dd/MM/yyyy" }}
      </td>
    </tr>
  </ng-template>

  <!-- Row Expansion Template -->
  <ng-template pTemplate="rowexpansion" let-arbitration>
    <tr>
      <td colspan="12">
        <div class="p-3">
          <p-table [value]="[arbitration.details]" dataKey="id">
            <ng-template pTemplate="header">
              <tr>
                <th>Ημερ. Παραλαβής Πρακτικών Ε.Δ</th>
                <th>Ημερ. Ανάρτησης Πρακτικών Ε.Δ</th>
                <th>Ημ. Δημιουργίας</th>
                <th>Χρήστης Δημιουργίας</th>
                <th>Ημ. τελευταίας αλλαγής</th>
                <th>Χρήστης τελευταίας αλλαγής</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-detail>
              <tr>
                <td>
                  {{ detail.dateOfEDEMinutesReceipt | date : "dd/MM/yyyy" }}
                </td>
                <td>
                  {{ detail.dateOfEDEMinutesPosting | date : "dd/MM/yyyy" }}
                </td>
                <td>{{ detail.creationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ detail.creatorUser }}</td>
                <td>{{ detail.lastChangeDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ detail.lastChangedByUser }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">Δεν υπάρχουν διαθέσιμες λεπτομέρειες.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">Δεν υπάρχουν διαθέσιμες εγγραφές.</td>
    </tr>
  </ng-template>
</p-table>

<div class="font-medium text-3xl text-900 mb-3 mt-5">Πρακτικά Διαιτησία</div>
