<div class="grid">
  <!-- <div class="text-1000 font-bold text-3xl m-3">
        Διενέργεια Περιστατικού​​
    </div> -->
  <div class="col-12 xl:col-12">
    <div class="card">
      <div class="text-lg white-space-wrap">
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card">
      <p-accordion>
        <p-accordionTab
          header="Νέο Περιστατικό​"
          [selected]="false"
          class="m-5"
        >
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="flex flex-column gap-2">
                <label>Αριθμός ​Παραπεμπτικού*</label>
                <div>
                  <span class="p-input-icon-right">
                    <input
                      type="text"
                      pInputText
                      placeholder="54763476368328"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="flex flex-column gap-2">
                <label>Εύρεση Παρόχου​​</label>
                <p-dropdown
                  [options]="dropdownParochosOptions"
                  optionLabel="name"
                  placeholder="Εύρεση Παρόχου​"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="flex flex-column gap-2">
                <label>Είδος Περιστατικού​​</label>
                <p-dropdown
                  [options]="dropdownEidosPeristatikouOptions"
                  optionLabel="name"
                  placeholder="Είδος Περιστατικού​"
                ></p-dropdown>
              </div>
            </div>

            <div class="col-12 md:col-4">
              <div class="flex flex-column gap-2">
                <label>Χρειάζεστε Ασθενοφόρο;​​​</label>
                <p-dropdown
                  [options]="dropdownAsthenoforoOptions"
                  optionLabel="name"
                  placeholder="Χρειάζεστε Ασθενοφόρο​;​"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-10 md:col-3">
              <div class="flex flex-column gap-2">
                <label>Πατήστε για ειδοποίηση για ασθενοφόρο​​</label>
                <div class="w-full flex justify-content-center">
                  <button
                    class="border-circle w-2rem h-2rem bg-primary"
                    (click)="confirm1()"
                  ></button>
                  <p-confirmDialog
                    header="Confirmation"
                    key="confirm1"
                    icon="pi pi-exclamation-triangle"
                    message="Are you sure you want to proceed?"
                    [style]="{ width: '350px' }"
                    acceptButtonStyleClass="p-button-text"
                    rejectButtonStyleClass="p-button-text"
                  ></p-confirmDialog>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-5">
              <div class="flex flex-column gap-2"></div>
            </div>

            <div class="col-12">
              <div class="flex flex-column gap-2">
                <label>Τηλ: 2104567568​​​​</label>
              </div>
            </div>
          </div>
          <div class="flex justify-content-end">
            <span class="p-button text-color">Δημιουργία</span>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
  <div class="col-12 xl:col-12">
    <div class="card">
      <div class="text-900 font-bold text-2xl mb-4 text-start">
        Ιστορικό Περιστατικών​​
      </div>
      <p-table
        #dt1
        [value]="data"
        dataKey="id"
        [rows]="10"
        [rowHover]="true"
        styleClass="p-datatable-striped"
        [paginator]="!isSinglePage"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th scope="col">Ημ/νία Έναρξης Περιστατικού</th>
            <th scope="col">Ημ/νία Ολοκλήρωσης Περιστατικού</th>
            <th scope="col">Ασφαλιστήρια Συμβόλαια</th>
            <th scope="col">Είδος Περιστατικού</th>
            <th scope="col">Ασθενοφόρο</th>
            <th scope="col">Κατάσταση Αιτήματος</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.startDate }}</td>
            <td>{{ rowData.endDate }}</td>
            <td>{{ rowData.insuranceContracts }}</td>
            <td>
              <div
                class="flex gap-1 align-items-center justify-content-around w-8rem h-2rem"
                [ngClass]="getIncidentBackgroundColor(rowData.incident)"
              >
                <span>{{ rowData.incident }}</span>
                <span [ngClass]="getIncidentIcon(rowData.incident)"></span>
              </div>
            </td>
            <td>
              <span
                [ngClass]="getAmbulanceBackgroundColor(rowData.ambulance)"
                >{{ rowData.ambulance }}</span
              >
            </td>
            <td>
              <span [ngClass]="getStatusBackgroundColor(rowData.status)">{{
                rowData.status
              }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
