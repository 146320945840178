<div class="surface-section">
  <div class="font-medium text-3xl text-900 mb-3">Πληροφορίες Ασθενή</div>
  <div class="text-500 mb-5"></div>
  
  <ul class="list-none p-0 m-0">

    <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
      <div class="text-500 w-full md:w-3 font-medium">Στοιχεία Ασθενή:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li *ngFor="let item of patientInfo; let i = index" [ngClass]="{'border-top-1': i > 0, 'surface-border': true}" class="flex align-items-center py-3 px-2 flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">{{ item.label }}</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ item.value }}</div>
        </li>
      </div>
    </li>

    <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
      <div class="text-500 w-full md:w-3 font-medium">Στοιχεία Επικοινωνίας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li *ngFor="let item of contactInfo; let i = index" [ngClass]="{'border-top-1': i > 0, 'surface-border': true}" class="flex align-items-center py-3 px-2 flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">{{ item.label }}</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ item.value }}</div>
        </li>
      </div>
    </li>

    <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
      <div class="text-500 w-full md:w-3 font-medium">Διεύθυνση Οικίας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li *ngFor="let item of homeAddress; let i = index" [ngClass]="{'border-top-1': i > 0, 'surface-border': true}" class="flex align-items-center py-3 px-2 flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">{{ item.label }}</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ item.value }}</div>
        </li>
      </div>
    </li>

    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-full md:w-3 font-medium">Διεύθυνση Εργασίας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li *ngFor="let item of workAddress; let i = index" [ngClass]="{'border-top-1': i > 0, 'surface-border': true}" class="flex align-items-center py-3 px-2 flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">{{ item.label }}</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ item.value }}</div>
        </li>
      </div>
    </li>
  </ul>
</div>