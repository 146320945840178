import { Component } from '@angular/core';

@Component({
  selector: 'app-arbitration-documents',
  standalone: true,
  imports: [],
  templateUrl: './arbitration-documents.component.html',
  styleUrl: './arbitration-documents.component.scss'
})
export class ArbitrationDocumentsComponent {
  src = '/assets/layout/images/accurate_auditor.png'
}
