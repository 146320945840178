import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'lib-filters',
  standalone: true,
  imports: [
    AccordionModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    CommonModule,
    CheckboxModule,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.css',
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input()
  header!: string;

  @Input()
  checkbox: boolean = false;

  @Input()
  dropdownCount!: number; // Set this based on user input or logic
  @Input()
  inputCount!: number;
  @Input()
  inputPlaceholders: any;

  @Input()
  dropdownOptions: Array<any>[] = [];
  selectedOptions: Array<any> = [];
  @Input()
  placeholderOptions: Array<string> = [];
  @Input() keysToFilter: Array<any> = [];
  _data: any;
  private defaultData: Array<any> = []; // Preserve original data

  @Input()
  set data(value: Array<any>) {
    this._data = value;
    if (!this.defaultData.length) {
      // Only set defaultData once
      this.defaultData = [...value]; // Store default data on first load
    }
  }

  get data(): Array<any> {
    return this._data;
  }
  dataFiltered: boolean = false;

  @Output() onSelect = new EventEmitter<any>();

  @Output() nearbyDoctorsChanged = new EventEmitter<boolean>();
  @Output() nearbyClinicChanged = new EventEmitter<boolean>();

  showNearbyDoctors = false;

  onNearbyDoctorsChange() {
    this.nearbyDoctorsChanged.emit(this.showNearbyDoctors);
  }

  showNearbyClinic = false;

  onNearbyClinicChange() {
    this.nearbyClinicChanged.emit(this.showNearbyClinic);
  }

  @Input() enableNearbyFilter: boolean = false;
  @Input() enableNearbyClinicFilter: boolean = false;

  @Input() currentUserLocation: { x: number; y: number } | null = null;
  @Input() calculateDistance!: (lat1: number, lon1: number, lat2: number, lon2: number) => number;

  selectedCheckup: string = '';
  selectedSpecialty: string = '';
  selectedArea: string = '';

  // Handle dropdown changes and emit selected values
  onFilterChange(event: any, filterType: string) {
    if (filterType === 'checkup') {
      this.selectedCheckup = event.target.value;
    } else if (filterType === 'specialty') {
      this.selectedSpecialty = event.target.value;
    } else if (filterType === 'area') {
      this.selectedArea = event.target.value;
    }

    this.onSelect.emit({
      selectedCheckup: this.selectedCheckup,
      selectedSpecialty: this.selectedSpecialty,
      selectedArea: this.selectedArea,
    });
  }
  @Output() dataChange = new EventEmitter<Array<any>>();
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['dropdownCount'] ||
      changes['dropdownOptions'] ||
      changes['placeholderOptions']
    ) {
      console.log(changes['checkbox']);
    }
  }

  searchData() {
    if (!this.defaultData) return;
    let filteredData = [...this.defaultData];
    for (let i = 0; i < this.dropdownCount; i++) {
      if (this.selectedOptions[i]) {
        const key = this.keysToFilter[i]; // 'specialty[name]'
        if (key.includes('[')) {
          const [mainKey, subKey] = key.replace(']', '').split('['); // Split 'specialty[name]' into ['specialty', 'name']
          filteredData = filteredData.filter((item) => {
            return item[mainKey][subKey] == this.selectedOptions[i];
          });
        } else {
          filteredData = filteredData.filter(
            (item) => item[key] == this.selectedOptions[i]
          );
        }
      }
    }
    if (this.showNearbyDoctors && this.currentUserLocation) {
      filteredData = filteredData.sort((a, b) => {
        const distanceA = this.calculateDistance(
          this.currentUserLocation?.x ?? 0,
          this.currentUserLocation?.y ?? 0,
          a.addresses[0].lat,
          a.addresses[0].lon
        );
        const distanceB = this.calculateDistance(
          this.currentUserLocation?.x ?? 0,
          this.currentUserLocation?.y ?? 0,
          b.addresses[0].lat,
          b.addresses[0].lon
        );
        return distanceA - distanceB;
      });
    }
    if (this.showNearbyClinic && this.currentUserLocation) {
      filteredData = filteredData.sort((a, b) => {
        const distanceA = this.calculateDistance(
          this.currentUserLocation?.x ?? 0,
          this.currentUserLocation?.y ?? 0,
          a.clinicAddresses[0].lat,
          a.clinicAddresses[0].lon
        );
        const distanceB = this.calculateDistance(
          this.currentUserLocation?.x ?? 0,
          this.currentUserLocation?.y ?? 0,
          b.clinicAddresses[0].lat,
          b.clinicAddresses[0].lon
        );
        return distanceA - distanceB;
      });
    }
    this.dataFiltered = true;
    this.dataChange.emit(filteredData); // Emit the filtered data
  }
  resetFilters() {
    this.dataFiltered = false;
    this.selectedOptions = this.selectedOptions.map(() => null);
    this.showNearbyDoctors = false;
    this.showNearbyClinic = false;
    this.dataChange.emit([...this.defaultData]); // Emit default data to parent
  }
}
