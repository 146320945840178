<div class="card">
  <p-accordion>
    <p-accordionTab
      [header]="header"
      [selected]="true"
      class="line-height-3 m-0"
    >
      <div class="flex align-items-center flex-wrap gap-4 my-2 ml-2">
        @for(option of dropdownOptions; track option; let i = $index){
        <div class="">
          <p-dropdown
            #dropdown
            [options]="option"
            [(ngModel)]="selectedOptions[i]"
            optionLabel="label"
            optionValue="value"
            [placeholder]="placeholderOptions[i]"
            class=""
          ></p-dropdown>
        </div>
        } @for(placeholder of inputPlaceholders; track placeholder; let i =
        $index){
        <div class="">
          <div>
            <span class="p-input-icon-right">
              @if(placeholder.icon){
              <i class="pi pi-sync"></i>
              }
              <input type="text" pInputText [placeholder]="placeholder.label" />
            </span>
          </div>
        </div>
        }
        <div [ngClass]="checkbox == true ? '' : ' hidden'">
          <p-dropdown
            #dropdown
            [options]="[
              { label: 'Ναι', value: 'ναι' },
              { label: 'Όχι', value: 'όχι' }
            ]"
            optionLabel="label"
            optionValue="value"
            placeholder="Χρήση Παραπεμπτικού;"
            class=""
          ></p-dropdown>
        </div>
        <div [ngClass]="enableNearbyFilter == true ? '' : ' hidden'">
          <label>
            <input type="checkbox" [(ngModel)]="showNearbyDoctors" />
            Κοντά μου
          </label>
        </div>
        <div [ngClass]="enableNearbyClinicFilter == true ? '' : ' hidden'">
          <label>
            <input type="checkbox" [(ngModel)]="showNearbyClinic" />
            Κοντά μου
          </label>
        </div>
        <div
          class="flex flex-wrap md:justify-content-end align-items-center column-gap-3 row-gap-4"
        >
          <p-button class="" (click)="searchData()"> Αναζήτηση </p-button>
          @if(dataFiltered){
          <!-- @if (doctorsFiltered) { -->
          <p-button class="gap-2" severity="danger" (click)="resetFilters()">
            Εμφάνιση Όλων
          </p-button>
          }
        </div>
      </div>
      <!-- <span class="p-button align-self-end gap-2">Εύρεση</span> -->
    </p-accordionTab>
  </p-accordion>
</div>
