<div class="flex justify-content-center align-items-center">
  <span class="text-900 text-lg mb-3 border-round bg-primary-100 p-2">
    <strong>Εγγυητικές επιστολές:</strong>  {{ guaranteeLettersCount }} εγγυητικές επιστολές
  </span>
</div>

<p-table
  responsiveLayout="scroll"
  #dt
  [value]="guaranteeLetters"
  [rows]="8"
  [paginator]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="insuranceBranch"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ασφαλιστικός Κλάδος <p-sortIcon field="insuranceBranch"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceAnswer"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Απάντηση Ασφαλιστικής <p-sortIcon field="insuranceAnswer"></p-sortIcon>
      </th>
      <th
        pSortableColumn="guaranteeDesc"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Περιγραφή Εγγυητικής <p-sortIcon field="guaranteeDesc"></p-sortIcon>
      </th>
      <!-- <th
        pSortableColumn="icon1"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        icon1 <p-sortIcon field="icon1"></p-sortIcon>
      </th> -->
      <th
        pSortableColumn="temporary"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Προσωρινή <p-sortIcon field="temporary"></p-sortIcon>
      </th>
      <th
        pSortableColumn="completed"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ολοκληρωμένη <p-sortIcon field="completed"></p-sortIcon>
      </th>
      <th
        pSortableColumn="userWhoCompleted"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Χρήστης που ολοκλήρωσε <p-sortIcon field="userWhoCompleted"></p-sortIcon>
      </th>
      <th
        pSortableColumn="completionDate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Ολοκλήρωσης <p-sortIcon field="completionDate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="invalid"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Άκυρη <p-sortIcon field="invalid"></p-sortIcon>
      </th>
      <th
        pSortableColumn="coverageRate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ποσοστό Κάλυψης <p-sortIcon field="coverageRate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="exemptionAmount"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ποσό Απαλλαγής <p-sortIcon field="exemptionAmount"></p-sortIcon>
      </th>
      <th
        pSortableColumn="maxCoverage"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ανώτατο όριο κάλυψης <p-sortIcon field="maxCoverage"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceManager"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Υπεύθυνος Ασφαλιστικής <p-sortIcon field="insuranceManager"></p-sortIcon>
      </th> 
      <th
        pSortableColumn="documents"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Έγγραφα <p-sortIcon field="documents"></p-sortIcon>
      </th>  
      <th
        pSortableColumn="creationDate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Δημιουργίας <p-sortIcon field="creationDate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="id"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        ID <p-sortIcon field="id"></p-sortIcon>
      </th>
      <!-- <th
        pSortableColumn="icon4"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        icon4 <p-sortIcon field="icon4"></p-sortIcon>
      </th> -->
      <th
        pSortableColumn="dateOfLastChange"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία τελευταίας αλλαγής <p-sortIcon field="dateOfLastChange"></p-sortIcon>
      </th>
      <th
        pSortableColumn="userOfLastChange"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Χρήστης τελευταίας αλλαγής <p-sortIcon field="userOfLastChange"></p-sortIcon>
      </th>    
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-guaranteeLetters>
    <tr>
      <td>
        <span>{{ guaranteeLetters.insuranceBranch }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.insuranceAnswer }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.guaranteeDesc }}</span>
      </td>
      <!-- <td>
        <span>{{ guaranteeLetters.icon1 }}</span>
      </td> -->
      <td>
        <!-- <span>{{ guaranteeLetters.temporary }}</span> -->

        <!-- Check if temporary is 'checkmark' -->
        <span *ngIf="guaranteeLetters.temporary === 'checkmark'; else noCheckmark">
          <i class="pi pi-check"></i>
        </span>
        <ng-template #noCheckmark>
          <span>{{ guaranteeLetters.temporary }}</span>
        </ng-template>

      </td>
      <td>
        <!-- <span>{{ guaranteeLetters.completed }}</span> -->
        
        <!-- Check if completed is 'checkmark' -->
        <span *ngIf="guaranteeLetters.completed === 'checkmark'; else noCheckmark2">
          <i class="pi pi-check"></i>
        </span>
        <ng-template #noCheckmark2>
          <span>{{ guaranteeLetters.completed }}</span>
        </ng-template>
      </td>
      <td>
        <span>{{ guaranteeLetters.userWhoCompleted }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.completionDate }}</span>
      </td>
      <td *ngIf="guaranteeLetters.invalid === 'checkmark'; else noCheckmark" class="bg-red-100">
        <!-- <span>{{ guaranteeLetters.invalid }}</span> -->

        <!-- Check if invalid is 'checkmark' -->
        <span *ngIf="guaranteeLetters.invalid === 'checkmark'; else noCheckmark">
          <i class="pi pi-check"></i>
        </span>
        <ng-template #noCheckmark>
          <span>{{ guaranteeLetters.invalid }}</span>
        </ng-template>

      </td>
      <td>
        <span>{{ guaranteeLetters.coverageRate }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.exemptionAmount }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.maxCoverage }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.insuranceManager }}</span>
      </td>
      <td>
        <span>{{ guaranteeLetters.documents }}</span>
      </td> 
      <td>
        <span>{{ guaranteeLetters.creationDate }}</span>
      </td> 
      <td>
        <span>{{ guaranteeLetters.id }}</span>
      </td> 
      <!-- <td>
        <span>{{ guaranteeLetters.icon4 }}</span>
      </td>  -->
      <td>
        <span>{{ guaranteeLetters.dateOfLastChange }}</span>
      </td>  
      <td>
        <span>{{ guaranteeLetters.userOfLastChange }}</span>
      </td>      
    </tr>
  </ng-template>
</p-table>