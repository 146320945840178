import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from 'shared';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../../../shared/src/lib/translate.loader';
import { AuthService } from '../../../shared/src/lib/services/auth/auth.service';
import { JwtInterceptor } from '../../../shared/src/lib/services/auth/jwt.interceptor';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      // console.log('App Initializer');
      //@ts-ignore
      if (authService.isAuthenticated()) {
        resolve(true);
        // console.log('Authenticated');
      } else {
        // console.log('Not Authenticated');
        resolve(false);
        authService.logout();
      }
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // EventService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
