import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExamsService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  // private apiUrl = 'localhost:8080/patients/';
  private apiUrl = `${environment.api}`;
  // private reportUrl = environment.reportUrl;

  constructor(private http: HttpClient) {}

  getExamTypes() {
    return this.http.get<any>(`${this.apiUrl}exams/types`, this.httpOptions);
  }
  getExamReferencesByUserId(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs`,
      this.httpOptions
    );
  }
  getICD10List() {
    return this.http.get<any>(`${this.apiUrl}exams/icd10s`, this.httpOptions);
  }
  getICD10ByNameOrCode(
    ICD10Name: string | undefined,
    code: string | undefined
  ) {
    if (ICD10Name) {
      return this.http.get<any>(
        `${this.apiUrl}exams/icd10s?name=${ICD10Name}`,
        this.httpOptions
      );
    } else {
      return this.http.get<any>(
        `${this.apiUrl}exams/icd10s?code=${code}`,
        this.httpOptions
      );
    }
  }

  getExamRefByUserAndClinicId(
    userId: number,
    clinicId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs/${clinicId}`,
      this.httpOptions
    );
  }
  addExamResult(examObject: any) {
    return this.http.post<any>(
      `${this.apiUrl}exams/results`,
      examObject,
      this.httpOptions
    );
  }
  // returns list of exams in insured-app
  getPatientExamResults(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examResults`,
      this.httpOptions
    );
  }



  createExams(exams: any) {
    return this.http.post<any>(`${this.apiUrl}exams/new`, exams);
  }
}
