import { Component, HostListener } from '@angular/core';
import { AppConfig, LayoutService } from '../layout/service/app.layout.service';
import { Router } from '@angular/router';
import { RouterLink } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { MemberService } from '../../services/member/member.service';
import { InsurerConfigService } from '../../../public-api';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../services/auth/auth.service';
import { ChatService } from '../../services/chat/chat.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
  ],
})
export class LoginComponent {
  rememberMe: boolean = false;
  email: string = '';
  password: string = '';
  selectedLanguage: string;
  selectedLanguageIcon: string = '';
  dropdownVisible: boolean = false;

  constructor(
    private layoutService: LayoutService,
    private auth: AuthService,
    private router: Router,
    private memberService: MemberService,
    private insurerConfigService: InsurerConfigService,
    private titleService: Title,
    private chatService: ChatService,
    private translate: TranslateService
  ) {
    const defaultLanguage = 'gr';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    this.selectedLanguage = defaultLanguage;
    this.updateSelectedLanguageIcon();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible; // Toggle visibility
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('selectedLanguage', language);
    this.selectedLanguage = language;
    this.updateSelectedLanguageIcon();
    this.dropdownVisible = false; // Close the dropdown after selection
  }

  private updateSelectedLanguageIcon() {
    const foundLanguage = this.languages.find(
      (lang) => lang.value === this.selectedLanguage
    );
    this.selectedLanguageIcon = foundLanguage ? foundLanguage.icon : '';
  }

  languages = [
    { label: 'Ελληνικά', value: 'gr', icon: '../../assets/i18n/icons/gr.png' },
    { label: 'English', value: 'en', icon: '../../assets/i18n/icons/en.png' },
  ];

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
  get dark(): boolean {
    return this.layoutService.config().colorScheme !== 'light';
  }

  login() {
    this.auth.login(this.email, this.password).subscribe(
      (response) => {
        if (response.jwtToken != null) {
          console.log(response);
          this.memberService.getEntityByUsername(response.userName).subscribe({
            next: (user) => {
              this.auth.currentUserSubject.next(user); // Set the logged-in user

              this.chatService.createCurrentSession();
              this.router.navigate(['/']);
            },
          });
          if (this.memberService.appName == 'insurers') {
            const insurer = this.insurerConfigService.getUserConfig(
              response.userName
            );
            this.titleService.setTitle(insurer!.appName);
            //   this.layoutService.config.update((config) => ({
            //     ...config,
            //     menuTheme: 'eurolife',
            // }));
            this.layoutService.config.update((config) => ({
              ...config,
              theme: 'eurolife',
            }));
            console.log(this.layoutService.config());

            this.layoutService.logoUrl.set(insurer!.logoUrl);
          }
        } else {
          alert('user not found');
        }
      },
      (error) => {
        // Handle login error
        console.error(error);
      }
    );
  }
}
