import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-category-amount',
  standalone: true,
  imports: [TableModule, FormsModule, InputTextModule],
  templateUrl: './category-amount.component.html',
  styleUrl: './category-amount.component.scss',
})
export class CategoryAmountComponent {
  services = [
    { id: 1, serviceType: 'Νοσηλία', netAmount: 0, vat: 0 },
    { id: 2, serviceType: 'Κ.Ε.Ν', netAmount: 0, vat: 0 },
    { id: 3, serviceType: 'Εξετάσεις', netAmount: 0, vat: 0 },
    { id: 4, serviceType: 'Υγειονομικό Υλικό', netAmount: 0, vat: 0 },
    { id: 5, serviceType: 'Φαρμακα', netAmount: 0, vat: 0 },
    { id: 6, serviceType: 'Ειδικα Υλικα', netAmount: 0, vat: 0 },
    { id: 7, serviceType: 'Εξοδα Χειρουργιου', netAmount: 0, vat: 0 },
    { id: 8, serviceType: 'Εξοδα Αναισθησιας', netAmount: 0, vat: 0 },
    { id: 9, serviceType: 'Αμοιβες Ιατρου', netAmount: 0, vat: 0 },
    { id: 10, serviceType: 'Αλλα', netAmount: 0, vat: 0 },
    { id: 11, serviceType: 'Υποβολες Δημοσιου', netAmount: 0, vat: 0 },
  ];
}
