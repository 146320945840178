import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@shared/environments';
@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}appointments`;
  // private reportUrl = environment.reportUrl;

  constructor(private http: HttpClient) {}

  getAppointments(userId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${userId}/userappointments`,
      this.httpOptions
    );
  }

  getUserAppointmentsByUserId(userId:number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${userId}/patientAppointments`,
      this.httpOptions
    );
  }

  createAppointment(appointment: any): Observable<any> {
    console.log('app',appointment)
    return this.http.post<any>(`${this.apiUrl}`, appointment);
  }

  updateAppointmentStatusById(  
    appointmentId: number,
    status: any
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/${appointmentId}/status`, status,
      this.httpOptions
    );
  }
}
