<div class="flex justify-content-center align-items-center">
  <span class="text-900 text-lg mb-3 border-round bg-primary-100 p-2">
    {{ organizationCount }} εγγραφές
  </span>
</div>

<p-table
  responsiveLayout="scroll"
  #dt
  [value]="organization"
  [rows]="8"
  [paginator]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="id"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        ID <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th
        pSortableColumn="hospital"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Νοσοκομείο <p-sortIcon field="hospital"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceBranch"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ασφ. Κλάδος <p-sortIcon field="insuranceBranch"></p-sortIcon>
      </th>
      <!-- <th
        pSortableColumn="icon1"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        icon1 <p-sortIcon field="icon1"></p-sortIcon>
      </th> -->
      <th
        pSortableColumn="arbitrationSignedByHospital"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Διαιτησία υπογ/νη από Νοσοκομείο <p-sortIcon field="arbitrationSignedByHospital"></p-sortIcon>
      </th>
      <th
        pSortableColumn="importDetails"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Στοιχεία Εισαγωγής <p-sortIcon field="importDetails"></p-sortIcon>
      </th>
      <th
        pSortableColumn="importDate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Εισαγωγής <p-sortIcon field="importDate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insured"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ασφαλισμένος <p-sortIcon field="insured"></p-sortIcon>
      </th>
      <th
        pSortableColumn="contract"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Συμβόλαιο <p-sortIcon field="contract"></p-sortIcon>
      </th>
      <th
        pSortableColumn="pendingGuarantee"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Εκκρεμεί Εγγυητική <p-sortIcon field="pendingGuarantee"></p-sortIcon>
      </th>
      <th
        pSortableColumn="assignments"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αναθέσεις <p-sortIcon field="assignments"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceΝotes"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Σημειώσεις Ασφαλιστικής <p-sortIcon field="insuranceΝotes"></p-sortIcon>
      </th>
      <th
        pSortableColumn="cutAmountInvoice"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ποσό Περικοπής (επι τιμολογίου) <p-sortIcon field="cutAmountInvoice"></p-sortIcon>
      </th> 
      <th
        pSortableColumn="pendingFinalGuarantee"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Εκκρεμεί Τελική Εγγυητική <p-sortIcon field="pendingFinalGuarantee"></p-sortIcon>
      </th>  
      <th
        pSortableColumn="insChanges"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αλλαγές Ασφαλιστικού Ενδιαφέροντος <p-sortIcon field="insChanges"></p-sortIcon>
      </th>
      <th
        pSortableColumn="maximize"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        <i class="pi pi-window-maximize"></i> <p-sortIcon field="maximize"></p-sortIcon>
      </th>
      <!-- <th
        pSortableColumn="icon4"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        icon4 <p-sortIcon field="icon4"></p-sortIcon>
      </th> -->
      <th
        pSortableColumn="insuranceAnswer"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Απάντηση Ασφαλιστικής <p-sortIcon field="insuranceAnswer"></p-sortIcon>
      </th>
      <th
        pSortableColumn="checkbox"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Επιλογή <p-sortIcon field="checkbox"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceManager"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Υπεύθυνος Ασφαλιστικής <p-sortIcon field="insuranceManager"></p-sortIcon>
      </th>    
      <th
        pSortableColumn="receiptFromInsuranceManager"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Παραλαβή από Υπεύθυνο <p-sortIcon field="receiptFromInsuranceManager"></p-sortIcon>
      </th>    
      <th
        pSortableColumn="accurateNotes"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Σημειώσεις Accurate <p-sortIcon field="accurateNotes"></p-sortIcon>
      </th>    
      <th
        pSortableColumn="NoInsuranceDamage"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αρ. Ζημιάς Ασφ. <p-sortIcon field="NoInsuranceDamage"></p-sortIcon>
      </th>    
      <th
        pSortableColumn="NoOrg"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αριθμός Φορέων <p-sortIcon field="NoOrg"></p-sortIcon>
      </th>    
      <th
        pSortableColumn="dateAnnunciation"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημ. Αναγγελίας <p-sortIcon field="dateAnnunciation"></p-sortIcon>
      </th>        
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-organization>
    <tr>
      <td>
        <span>{{ organization.id }}</span>
      </td>
      <td>
        <span>{{ organization.hospital }}</span>
      </td>
      <td>
        <span>{{ organization.insuranceBranch }}</span>
      </td>
      <!-- <td>
        <span>{{ organization.icon1 }}</span>
      </td> -->
      <td>
        <!-- <span>{{ organization.arbitrationSignedByHospital }}</span> -->

        <!-- Check if arbitrationSignedByHospital is 'checkmark' -->
        <span *ngIf="organization.arbitrationSignedByHospital === 'checkmark'; else noCheckmark">
          <i class="pi pi-check"></i>
        </span>
        <ng-template #noCheckmark>
          <span>{{ organization.arbitrationSignedByHospital }}</span>
        </ng-template>

      </td>
      <td>
        <span>{{ organization.importDetails }}</span>
      </td>
      <td>
        <span>{{ organization.importDate }}</span>
      </td>
      <td>
        <span>{{ organization.insured }}</span>
      </td>
      <td>
        <span>{{ organization.contract }}</span>
      </td>
      <td>
        <span>{{ organization.pendingGuarantee }}</span>
      </td>
      <td>
        <span>{{ organization.assignments }}</span>
      </td>
      <td>
        <span>{{ organization.insurancenotes }}</span>
      </td>
      <td>
        <span>{{ organization.cutAmountInvoice }}</span>
      </td>
      <td>
        <span>{{ organization.pendingFinalGuarantee }}</span>
      </td> 
      <td>
        <span>{{ organization.insChanges }}</span>
      </td> 
      <td>
        <!-- <span>{{ organization.maximize }}</span> -->
        <span *ngIf="organization.maximize === 'maximize'; else noCheckmark">
          <i class="pi pi-window-maximize"></i>
        </span>
      </td> 
      <td>
        <span>{{ organization.insuranceAnswer }}</span>
      </td> 
      <td>
        <!-- <span>{{ organization.checkbox }}</span> -->
        <span>
          <!-- Conditionally display a checkbox if organization.checkbox is not empty -->
          <input 
            type="checkbox" 
            [checked]="organization.checkbox === 'checkbox'"
            (change)="onCheckboxChange($event)" 
          />
        </span>
      </td>  
      <td>
        <span>{{ organization.insuranceManager }}</span>
      </td>
      <td>
        <span>{{ organization.receiptFromInsuranceManager }}</span>
      </td> 
      <td>
        <span>{{ organization.accurateNotes }}</span>
      </td>  
      <td>
        <span>{{ organization.NoInsuranceDamage }}</span>
      </td>  
      <td>
        <span>{{ organization.NoOrg }}</span>
      </td>  
      <td>
        <span>{{ organization.dateAnnunciation }}</span>
      </td>       
    </tr>
  </ng-template>
</p-table>