import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { PanelModule } from 'primeng/panel';

import { PanelMenuModule } from 'primeng/panelmenu';
import { StyleClassModule } from 'primeng/styleclass';
import { MultiSelectModule } from 'primeng/multiselect';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SliderModule } from 'primeng/slider';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { StarFillIcon } from 'primeng/icons/starfill';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { TagModule } from 'primeng/tag';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TabViewModule } from 'primeng/tabview';
import { CarouselModule } from 'primeng/carousel';
import { SidebarModule } from 'primeng/sidebar';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    MenuModule,
    TableModule,
    CurrencyPipe,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    DropdownModule,
    RatingModule,
    DividerModule,
    PanelModule,
    PanelMenuModule,
    StyleClassModule,
    MultiSelectModule,
    FullCalendarModule,
    DialogModule,
    InputTextareaModule,
    SliderModule,
    CalendarModule,
    CardModule,
    StarFillIcon,
    ConfirmDialogModule,
    MenubarModule,
    TagModule ,
    IconFieldModule,
    InputIconModule ,
    TabViewModule,
    CarouselModule,
    SidebarModule,
   
  ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    MenuModule,
    CurrencyPipe,
    TableModule,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    DropdownModule,
    RatingModule,
    DividerModule,
    PanelModule,
    PanelMenuModule,
    StyleClassModule,
    MultiSelectModule,
    FullCalendarModule,
    DialogModule,
    InputTextareaModule,
    SliderModule,
    CalendarModule,
    CardModule,
    StarFillIcon,
    ConfirmDialogModule,
    MenubarModule,
    TagModule,
    IconFieldModule,
    InputIconModule ,
    SidebarModule
  
  ],
})
export class ImportsModule {}
