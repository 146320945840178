import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Examcenter } from '../../interfaces/examcenter';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root',
})
export class ExamCentersService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}`;

  constructor(private http: HttpClient) {}

  getMedicalCenterPatientsByUserId(UserId: number) {
    return this.http.get<any>(
      `${this.apiUrl}medicalCenters/${UserId}/patients`,
      this.httpOptions
    );
  }
  getMedicalCenterDataByUsername(centerName: any) {
    return this.http.get<any>(
      `${this.apiUrl}medicalCenters/me/${centerName}`,
      this.httpOptions
    );
  }
  // medical centers
  getDiagnosticCenters(): Observable<Examcenter[]> {
    return of([
      {
        id: 1,
        image: '/assets/demo/images/hospitals/iatriko-kentro-athinon.png',
        name: 'Ιατρικό Κέντρο Αθηνών',
        address: 'Δ. Βασιλείου 4, ΑΘΗΝΑ',
        rating: 4.8,
        cost: 4,
        review: 'Άριστες υπηρεσίες υγείας',
        ratingCount: 452,
        type: 'ΓΕΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ',
        totalInsuredPatients: 1500,
      },
      {
        id: 2,
        image: '/assets/demo/images/hospitals/geniko-nosokomeio-larisas.png',
        name: 'Γενικό Νοσοκομείο Λάρισας',
        address: 'Κούμα 15, ΛΑΡΙΣΑ',
        rating: 4.5,
        cost: 3,
        review: 'Εξαιρετική φροντίδα και οργάνωση',
        ratingCount: 389,
        type: 'ΓΕΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ',
        totalInsuredPatients: 1200,
      },
    ]);
  }

  getAllDiagnosticCenters(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}medicalCenters/`, this.httpOptions);
  }
  getDiagnosticCentersById(userId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}medicalCenters/${userId}/details`,
      this.httpOptions
    );
  }

  selfAssignExamToClinic(examId: number, clinicId: number) {
    return this.http.put<any>(
      `${this.apiUrl}exams/${examId}/selfAssignExam?clinicUserId=${clinicId}`,
      this.httpOptions
    );
  }
  // hospitals
  getHospitals(): Observable<any> {
    return of([
      {
        id: '2',
        image: '/assets/demo/images/diagnostic-centers/fake-hospital1.png',
        name: 'Health First Diagnostics',
        address: '123 Wellness Way, HEALTH CITY',
        rating: 4.5,
        cost: 4,
        review: 'Professional staff and quick service',
        ratingCount: 200,
        type: 'RADIOLOGY',
        totalInsuredPatients: 1200,
      },
      {
        id: '3',
        image: '/assets/demo/images/diagnostic-centers/fake-hospital2.png',
        name: 'CarePlus Medical Center',
        address: '456 Recovery Road, MEDVILLE',
        rating: 3,
        cost: 3,
        review: 'Friendly staff but long wait times',
        ratingCount: 250,
        type: 'CARDIOLOGY',
        totalInsuredPatients: 1800,
      },
      {
        id: '4',
        image: '/assets/demo/images/diagnostic-centers/fake-hospital3.png',
        name: 'Wellness Diagnostics',
        address: '789 Healthy Street, WELLVILLE',
        rating: 4.8,
        cost: 5,
        review: 'State-of-the-art equipment and excellent care',
        ratingCount: 150,
        type: 'NEUROLOGY',
        totalInsuredPatients: 950,
      },
      {
        id: '5',
        image: '/assets/demo/images/diagnostic-centers/fake-hospital4.png',
        name: 'Prime Health Center',
        address: '321 Recovery Blvd, HEALTHBURG',
        rating: 4.2,
        cost: 4,
        review: 'Efficient service and knowledgeable staff',
        ratingCount: 300,
        type: 'ORTHOPEDICS',
        totalInsuredPatients: 1100,
      },
    ]);
  }
}
