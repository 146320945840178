import { AngularOpenlayersModule } from 'ng-openlayers';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { defaults as defaultControls } from 'ol/control';
import { FullScreen } from 'ol/control';
import { ScaleLine } from 'ol/control';
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import {
  CurrentUserLocation,
  DCLocationMarker,
  Doctor,
  DoctorLocationMarker,
  DC,
} from '../../interfaces/interface.module';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'lib-map',
  standalone: true,
  imports: [AngularOpenlayersModule, CommonModule, RouterLink, DialogModule],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css',
})

export class MapComponent implements OnInit {
  currentUserLocation: CurrentUserLocation = { x: 0, y: 0 };
  error: string = '';
  acceptedLocationPermission: boolean = true;
  @Input({ required: true }) displayMap: boolean = false;
  @Input() doctors: Doctor[] = [];
  @Input() dc: DC[] = [];

  // selectedDoctor: { x: number; y: number; name: string } | null = null;
  selectedDoctor: Doctor | null = null;
  selectedDc: DC | null = null;
  selectedAddress: { fullAddress: string; lat: number; lon: number } | null = null;
  selectedDcAddress: { fullAddress: string; lat: number; lon: number } | null = null;
  // doctorCoordinates: { x: number; y: number; name: string }[] = [];
  doctorCoordinates: DoctorLocationMarker[] = [];
  dcCoordinates: DCLocationMarker[] = [];
  map!: Map;
  doctorLayer!: VectorLayer<VectorSource>;
  dcLayer!: VectorLayer<VectorSource>;
  popupPosition = { x: 0, y: 0 };
  private isMapInitialized = false;
  showDetails: boolean = false;
  showDcDetails: boolean = false;


  ngOnInit() {
    this.getUserLocation();
    this.doctorCoordinates = this.getDoctorCoordinates(this.doctors);
    this.dcCoordinates = this.getDcCoordinates(this.dc);
  }

  ngAfterViewInit() {
    if (!this.isMapInitialized) {
      this.initializeMap();
      this.isMapInitialized = true;
    }
    this.updateDoctorMarkers();
    this.updateDcMarkers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['doctors'] && !changes['doctors'].firstChange) {
      this.doctorCoordinates = this.getDoctorCoordinates(changes['doctors'].currentValue);
      this.updateDoctorMarkers();
    } else
    if (changes['dc'] && !changes['dc'].firstChange) {
      this.dcCoordinates = this.getDcCoordinates(changes['dc'].currentValue);
      this.updateDcMarkers();
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('User location:', position);
          this.currentUserLocation.x = position.coords.longitude;
          this.currentUserLocation.y = position.coords.latitude;
          this.centerMapOnUserLocation();
        },
        (error) => {
          console.error('Location permission denied', error);
        },
        // { timeout: 1000 }
      );
    }
  }

  centerMapOnUserLocation() {
    // Ensure the map view is centered on the user's location
    if (this.map) {
      const userCoordinates = fromLonLat([
        this.currentUserLocation.x,
        this.currentUserLocation.y,
      ]);
      this.map.getView().setCenter(userCoordinates);
      this.addUserLocationMarker();
    }
  }

  addUserLocationMarker() {
    // Create a marker for the user's current location
    const userFeature = new Feature({
      geometry: new Point(
        fromLonLat([this.currentUserLocation.x, this.currentUserLocation.y])
      ),
    });
    userFeature.setStyle(
      new Style({
        image: new Icon({
          src: 'assets/demo/images/location-pin.png',
          scale: 0.1,
        }),
      })
    );

    // Add user marker to a vector source and layer
    const userLocationSource = new VectorSource({
      features: [userFeature],
    });
    const userLocationLayer = new VectorLayer({
      source: userLocationSource,
    });
    this.map.addLayer(userLocationLayer);
  }

  initializeMap() {
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([
          this.currentUserLocation.x,
          this.currentUserLocation.y,
        ]),
        zoom: 12,
      }),
      controls: defaultControls({ attribution: false }).extend([
        new FullScreen(),
        new ScaleLine(),
      ]),
    });

    // Initialize an empty doctor layer with a VectorSource to hold doctor markers
    this.doctorLayer = new VectorLayer({
      source: new VectorSource(),
    });
    

    this.dcLayer = new VectorLayer({
      source: new VectorSource(),
    });
    this.map.addLayer(this.doctorLayer);
    this.map.addLayer(this.dcLayer);

    // Set up click handling for doctor markers
    const selectClick = new Select({
      condition: click,
    });
    const selectClickDc = new Select({
      condition: click,
    });
    selectClick.on('select', (e) => {
      const feature = e.selected[0];
      if (feature) {
        const doctor = feature.get('doctorData');
        const address = feature.get('addressData');
        this.showDoctorDetails(doctor, address);
      }
    });
    selectClickDc.on('select', (e) => {
      const featureDc = e.selected[0];
      if (featureDc) {
        const dc = featureDc.get('dcData');
        const dcaddress = featureDc.get('dcaddressData');
        this.showDCDetails(dc, dcaddress);
      }
    });
    this.map.addInteraction(selectClick);
    this.map.addInteraction(selectClickDc);
    
    // Close details when clicking outside markers
    this.map.on('click', (e) => {
      // Check if a feature was clicked at the pixel
      if (!this.map.hasFeatureAtPixel(e.pixel)) {
          this.closeDetails(); // Close details if no marker was clicked
      } else
      if (!this.map.hasFeatureAtPixel(e.pixel)) {
        this.closeDcDetails(); // Close details if no marker was clicked
    }
    });
  }

  getDoctorCoordinates(filteredDoctors: Doctor[]): DoctorLocationMarker[] {
    return filteredDoctors.flatMap((doctor) =>
      doctor.addresses.map((address) => ({
        x: address.lon,
        y: address.lat,
        name: doctor.fullName,
        fullAddress: address.fullAddress,
        doctorData: doctor,
        addresses: doctor.addresses,
      }))
    );
  }

  getDcCoordinates(filteredDc: DC[]): DCLocationMarker[] {
    return filteredDc.flatMap((center) =>
      center.clinicAddresses.map((address) => ({
        x: address.lon,
        y: address.lat,
        name: center.name,
        fullAddress: address.fullAddress,
        diagnosticCenterData: center,
        clinicAddresses: center.clinicAddresses,
      }))
    );
  }

  updateDoctorMarkers() {
    const doctorFeatures = this.doctorCoordinates.map((location) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([location.x, location.y])),
      });
      // Store doctor and address data in the feature
      feature.setProperties({
        doctorData: location.doctorData,
        addressData: {
          fullAddress: location.fullAddress,
          lat: location.y,
          lon: location.x,
        },
      });
      feature.setStyle(
        new Style({
          image: new Icon({
            src: 'assets/demo/images/location-pin.png',
            scale: 0.1,
          }),
        })
      );
      return feature;
    });

    const vectorSource = this.doctorLayer.getSource();
    vectorSource?.clear();
    vectorSource?.addFeatures(doctorFeatures);
  }

  updateDcMarkers() {
    const dcFeatures = this.dcCoordinates.map((location) => {
      const featureDc = new Feature({
        geometry: new Point(fromLonLat([location.x, location.y])),
      });
      // Store dc and address data in the feature
      featureDc.setProperties({
        dcData: location.diagnosticCenterData,
        dcaddressData: {
          fullAddress: location.fullAddress,
          lat: location.y,
          lon: location.x,
        },
      });
      featureDc.setStyle(
        new Style({
          image: new Icon({
            src: 'assets/demo/images/location-pin.png',
            scale: 0.1,
          }),
        })
      );
      return featureDc;
    });

    const vectorSourceDc = this.dcLayer.getSource();
    vectorSourceDc?.clear();
    vectorSourceDc?.addFeatures(dcFeatures);
  }

  showDoctorDetails(
    doctor: Doctor,
    address: { fullAddress: string; lat: number; lon: number }
  ) {
    this.showDetails = true;
    this.selectedDoctor = doctor;
    this.selectedAddress = address;

    if (address && address.lon !== undefined && address.lat !== undefined) {
      const doctorCoordinates = fromLonLat([address.lon, address.lat]);
      this.map.getView().setCenter(doctorCoordinates);
      this.map.getView().setZoom(12);
    }
  }

  showDCDetails(
    dc: DC,
    address: { fullAddress: string; lat: number; lon: number }
  ) {
    this.showDcDetails = true;
    this.selectedDc = dc;
    this.selectedDcAddress = address;

    if (address && address.lon !== undefined && address.lat !== undefined) {
      const dcCoordinates = fromLonLat([address.lon, address.lat]);
      this.map.getView().setCenter(dcCoordinates);
      this.map.getView().setZoom(12);
    }
  }

  closeDetails() {
    this.selectedDoctor = null;
    this.selectedAddress = null;
  }

  closeDcDetails() {
    this.selectedDc = null;
    this.selectedDcAddress = null;
  }
}
