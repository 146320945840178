<div class="surface-section mb-8">
  <div class="font-medium text-3xl text-900 mb-3">Διάγνωση</div>
  <div class="text-500 mb-5">
    <!-- Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum. -->
  </div>
  <ul class="list-none p-0 m-0">
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Αιτία Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <textarea
          rows="5"
          cols="30"
          pInputTextarea
          class="p-inputtextarea p-inputtext"
        >
        </textarea>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Αιτιολόγηση Συμπεράσματος:
      </div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        ΓΙΑ ΑΠΟΚΛΕΙΣΜΟ ΟΞΕΟΣ ΚΑΡΔΙΑΓΓΕΙΑΚΟΥ ΣΥΜΒΑΜΑΤΟΣ
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-4 md:w-2 font-medium">ΧΜΘ:</div>
      <div class="text-900 w-4 md:w-2 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
      <div class="text-500 w-4 md:w-2 font-medium">ΑΚΘ:</div>
      <div class="text-900 w-4 md:w-2 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
      <div class="text-500 w-4 md:w-2 font-medium">Επείγον Περιστατικό:</div>
      <div class="text-900 w-4 md:w-2 md:flex-order-0 flex-order-1">
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
      </div>
    </li>
    <!-- <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">ΑΚΘ:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" /></div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Επείγον Περιστατικό:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" /></div>
    </li> -->
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Διάγνωση Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Διάγνωση Ελεγκτή:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        R07.2 Προκάρδιος πόνος [άλγος]
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Διάγνωση Εξιτηρίου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        Ι20 Στηθάρχη
      </div>
    </li>
    <li
    class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
  >
    <div class="text-500 w-6 md:w-2 font-medium">ΚΕΝ Εισαγωγής:</div>
    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
      
    </div>
  </li>
  <li
    class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
  >
    <div class="text-500 w-6 md:w-2 font-medium">ΚΕΝ Ελεγκτή:</div>
    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
      
    </div>
  </li>
    <li
    class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
    <div class="text-500 w-6 md:w-2 font-medium">ΚΕΝ Εξιτηρίου:</div>
    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
      
    </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Τελική Έκβαση:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        Βελτίωση
      </div>
    </li>
  </ul>
</div>
