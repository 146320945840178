import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PatientDiagnosisComponent } from './patient-diagnosis/patient-diagnosis.component';
import { FilesComponent } from "./files/files.component";
import { HealthRecordComponent } from "./health-record/health-record.component";

@Component({
  selector: 'app-incident-date',
  standalone: true,
  imports: [
    CalendarModule,
    CheckboxModule,
    FormsModule,
    InputTextareaModule,
    PatientDiagnosisComponent,
    FilesComponent,
    HealthRecordComponent
],
  templateUrl: './incident-date.component.html',
  styleUrl: './incident-date.component.scss',
})
export class IncidentDateComponent {
  checkBox: boolean = false;
}
