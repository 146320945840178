import { environment as defaultEnv } from './environment';

export const environment = {
  // ...defaultEnv,
  production: false,
  apiUrl: 'api',
  api: 'https://api.gateway.dev.phi-platform.gr/',
  logoUrl: 'assets/layout/images/phi-logo.png',
  appName: 'PHI',
  APP_ID: 'tNh5WSLo'
};
