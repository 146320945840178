<div class="surface-section mb-8">
  <div class="font-medium text-3xl text-900 mb-3">Περιστατικό</div>
  <div class="text-500 mb-5">
    <!-- Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum. -->
  </div>
  <ul class="list-none p-0 m-0">
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-full md:w-3 font-medium">Αναγκαιότητα Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Σημειώσεις Ιατρού</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <textarea
              rows="5"
              cols="30"
              pInputTextarea
              class="p-inputtextarea p-inputtext"
            >
            </textarea>
          </div>
        </li>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-full md:w-3 font-medium">Στοιχεία Θεράποντος Ιατρού Α΄:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Ονοματεπώνυμο</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
        </li>
        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Ειδικότητα</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
        </li>
        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Tηλέφωνο</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
        </li>
        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Εmail</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
        </li>
        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-4 font-medium">Υπογραφή</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
        </li>
      </div>
    </li>
  </ul>
</div>

<div class="surface-section mb-8">
  <div class="font-medium text-3xl text-900 mb-3">Περιγραφή Περιστατικού</div>
  <div class="text-500 mb-5">
    <!-- Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum. -->
  </div>
  <ul class="list-none p-0 m-0">
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Τύπος Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        Εσωτερικός Ασθενής
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Αρ. Περιστ. Νοσοκ.:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        810084280
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Κλινική/Τμήμα:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        ΚΑΡΔΙΟΛΟΓΙΚΗ
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Θέση:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        Α Θέση
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Όροφος:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Δωμάτιο:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ημ.Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-calendar
          dateFormat="dd.mm.yy"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="8/12/2020"
          disabled
        />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ώρα Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-calendar
          inputId="calendar-timeonly"
          [timeOnly]="true"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="11:00:00"
          disabled
        >
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <i
              class="pi pi-clock pointer-events-none"
              (click)="clickCallBack($event)"
            ></i>
          </ng-template>
        </p-calendar>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ημ.Εξιτηρίου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-calendar
          dateFormat="dd.mm.yy"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="9/12/2020"
          disabled
        />
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ώρα Εξιτηρίου:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <p-calendar
          inputId="calendar-timeonly"
          [timeOnly]="true"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="templatedisplay"
          placeholder="11:00:00"
          disabled
        >
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <i
              class="pi pi-clock pointer-events-none"
              (click)="clickCallBack($event)"
            ></i>
          </ng-template>
        </p-calendar>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Θεράπων Ιατρός:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        ΚΩΝΣΤΑΝΤΙΝΙΔΗΣ ΣΤΥΛΙΑΝΟΣ ΚΑΡΔΙΟΛΟΓΟΣ 1213421321
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Β Ιατρός:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Εκτ.παρ.Θεράποντα:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ημέρες νοσηλείας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">1</div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Αποδεκτή διάρκεια:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        24ωρο
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Αλλαγές Ασφαλιστικού ενδιαφέροντος:
      </div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <textarea
          rows="5"
          cols="30"
          pInputTextarea
          class="p-inputtextarea p-inputtext"
        >
        </textarea>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">
        Ειδοποίηση Ασφαλιστικής:
      </div>
      <div
        class="flex align-items-center gap-3 text-900 w-full md:w-8 md:flex-order-0 flex-order-1"
      >
        <p-checkbox [(ngModel)]="checkBox" [binary]="true" inputId="binary" />
        &nbsp;
        <button pButton>Οκ, το είδα!</button>
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Ιατρικές Ενέργειες:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        <li
          class="flex align-items-center py-3 px-2 surface-border flex-wrap"
        >ICD10
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >PCS
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >HCPCS
        </li>
      </div>
    </li>
  </ul>
</div>

<app-patient-diagnosis></app-patient-diagnosis>

<app-files></app-files>

<app-health-record></app-health-record>
