import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-organization',
  standalone: true,
  imports: [TableModule, CommonModule],
  templateUrl: './organization.component.html',
  styleUrl: './organization.component.scss',
})
export class OrganizationComponent {
  organization: any = [
    {
      id: '438337',
      hospital: 'ΥΓΕΙΑ',
      insuranceBranch: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής',
      arbitrationSignedByHospital: 'checkmark',
      importDetails: 'ΚΑΡΑΣΤΑΘΗ ΧΡΥΣΟΥΛΑ Θ | 30/10/1976 ΕΣΩΤ. [08/12/2020 - 09/12/2020] ΚΑΡΔΙΟΛΟΓΙΚΗ 8100840280',
      importDate: '08/12/2020',
      insured: '',
      contract: '209186185',
      pendingGuarantee: '',
      assignments: '',
      insurancenotes: '',
      cutAmountInvoice: '292,88',
      pendingFinalGuarantee: '',
      insChanges: '',
      maximize: 'maximize',
      insuranceAnswer: 'Καλύπτεται',
      checkbox: '',
      insuranceManager: 'ΦΩΤΟΠΟΥΛΟΥ ΑΓΓΕΛΙΚΗ',
      receiptFromInsuranceManager: '08/12/2020 14:45:46',
      accurateNotes: '',
      NoInsuranceDamage: '20205169/467',
      NoOrg: '1',
      dateAnnunciation: '',
    },
  ];

  onCheckboxChange(event: Event) {
    const input = event.target as HTMLInputElement;
    console.log('Checkbox checked:', input.checked);
    // Handle checkbox change logic here
  }

  organizationCount:number = this.organization.length;
}
