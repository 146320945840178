import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';

@Component({
  selector: 'app-deductions-list',
  standalone: true,
  imports: [TableModule, CurrencyPipe, DatePipe, ButtonModule],
  templateUrl: './deductions-list.component.html',
  styleUrl: './deductions-list.component.scss',
})
export class DeductionsListComponent {
  hospitalData = [
    {
      id: '1',
      importDate: '2023-10-01',
      deduction: 'Περικοπή Α',
      hospitalCharge: '5000',
      auditorAmount: '4500',
      deductionAmount: '500',
      arbitrationReduction: '100',
      finalDeduction: '400',
      reductionReason: 'Υπερτιμολόγηση',
      comments: 'Έλεγχος πραγματοποιήθηκε',
    },
    {
      id: '2',
      importDate: '2023-11-12',
      deduction: 'Περικοπή Β',
      hospitalCharge: '6000',
      auditorAmount: '5500',
      deductionAmount: '500',
      arbitrationReduction: '200',
      finalDeduction: '300',
      reductionReason: 'Αναγκαία διευκρίνιση',
      comments: 'Απαιτείται επανέλεγχος',
    },
    {
      id: '3',
      importDate: '2023-09-20',
      deduction: 'Περικοπή Γ',
      hospitalCharge: '7000',
      auditorAmount: '6200',
      deductionAmount: '800',
      arbitrationReduction: '150',
      finalDeduction: '650',
      reductionReason: 'Ελλιπή τεκμηρίωση',
      comments: 'Πλήρης έλεγχος δεν έγινε',
    },
    {
      id: '4',
      importDate: '2023-08-05',
      deduction: 'Περικοπή Δ',
      hospitalCharge: '4500',
      auditorAmount: '4200',
      deductionAmount: '300',
      arbitrationReduction: '50',
      finalDeduction: '250',
      reductionReason: 'Λανθασμένη διάγνωση',
      comments: 'Ελέγχθηκε ξανά',
    },
    {
      id: '5',
      importDate: '2023-07-15',
      deduction: 'Περικοπή Ε',
      hospitalCharge: '5200',
      auditorAmount: '4800',
      deductionAmount: '400',
      arbitrationReduction: '100',
      finalDeduction: '300',
      reductionReason: 'Διαδικαστικά λάθη',
      comments: 'Ολοκληρωμένος έλεγχος',
    },
  ];

  expandedRows = {};

  onRowExpand(event: TableRowExpandEvent) {}

  onRowCollapse(event: TableRowCollapseEvent) {}
}
