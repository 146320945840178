import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  readonly reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization:
      'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.jwtToken,
  });
  _appName: string = '';
  private apiUrl = `${environment.api}member/`;
  private api = `${environment.api}`;

  constructor(
    private http: HttpClient // private locationStrategy: LocationStrategy // private location: Location
  ) {}

  get appName() {
    return this._appName;
  }
  set appName(appname: string) {
    this._appName = appname;
  }
  getClientHistory(): Observable<any> {
    return of([
      {
        userId: 60,
        examDate: '2023-09-12',
        type: 'νοσηλεία',
        cost: 300,
        notes: 'Εισαγωγή λόγω τραυματισμού',
        status: 'ολοκληρώθηκε',
      },
      {
        userId: 60,
        examDate: '2023-10-25',
        type: 'εξετάσεις',
        cost: 120,
        notes: 'Αιματολογικές εξετάσεις',
        status: 'εκκρεμεί',
      },
      {
        userId: 60,
        examDate: '2024-01-10',
        type: 'νοσηλεία',
        cost: 800,
        notes: 'Χειρουργική επέμβαση',
        status: 'ολοκληρώθηκε',
      },
      {
        userId: 60,
        examDate: '2024-02-18',
        type: 'εξετάσεις',
        cost: 50,
        notes: 'Τακτικός έλεγχος',
        status: 'εκκρεμεί',
      },
      {
        userId: 60,
        examDate: '2023-11-05',
        type: 'νοσηλεία',
        cost: 1500,
        notes: 'Μακροχρόνια νοσηλεία',
        status: 'ολοκληρώθηκε',
      },
    ]);
  }
  // Auditor App
  getAllInsured(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}`, this.httpOptions);
  }
  getMemberInfoByMemberId(memberId: number) {
    return this.http.get<any>(
      `${this.api}member/${memberId}/info`,
      this.httpOptions
    );
  }

  getAllClients(insuranceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.api}insurance/${insuranceId}/members`,
      this.httpOptions
    );
  }

  getClientDetails(insuredId: number): Observable<any> {
    return this.http.get<any>(
      `${this.api}member/${insuredId}/info`,
      this.httpOptions
    );
  }

  getInsuredLastestActivities(userId: number) {
    return this.http.get<any>(
      `${this.api}member/${userId}/latestActivities`,
      this.httpOptions
    );
  }

  requestAccessToMedicalRecord(doctorUserId: number, insuredUserId: number) {
    return this.http.post<any>(
      `${this.api}member/requestAccess?requestUserId=${doctorUserId}&targetUserId=${insuredUserId}`,
      this.httpOptions
    );
  }

  getEntityByUsername(username: string): Observable<any> {
    console.log(username);
    if (this.appName == 'insured') {
      return this.http.get<any>(`${this.api}member/me/${username}`, {
        headers: this.reqHeader,
      });
    } else if (this.appName == 'insurers') {
      return this.http.get<any>(`${this.api}insurance/me/${username}`, {
        headers: this.reqHeader,
      });
    } else if (this.appName == 'doctor') {
      return this.http.get<any>(`${this.api}doctors/me/${username}`, {
        headers: this.reqHeader,
      });
    } else if (this.appName == 'medicalCenter') {
      return this.http.get<any>(`${this.api}medicalCenters/me/${username}`, {
        headers: this.reqHeader,
      });
    } else {
      return this.http.get<any>(`${this.api}doctors/me/${username}`, {
        headers: this.reqHeader,
      });
    }
  }

  getInsuredClinicalExamsByMemberId(memberId: number) {
    return this.http.get<any>(
      `${this.apiUrl}${memberId}/clinicalExams`,
      this.httpOptions
    );
  }
  getMemberByUsername(username: string): Observable<any> {
    // console.log(this.auth_token);
    return this.http.get<any>(`${this.apiUrl}me/${username}`);
  }
  getMemberDetailsById(userId: number): Observable<any> {
    // console.log(this.auth_token);
    return this.http.get<any>(`${this.apiUrl}${userId}/info`);
  }

  getMedicalRecordRequests(userId: number) {
    console.log('err');
    return this.http.get<any>(
      `${this.api}accessRequests?memberUserId=${userId}`
    );
  }
  postProvideAccessToMedicalRecord(
    insuredUserId: number,
    doctorUserId: number
  ) {
    return this.http.post<any>(
      `${this.api}provideAccess?memberUserId=${insuredUserId}&doctorUserId=${doctorUserId}`,
      this.httpOptions
    );
  }
}
