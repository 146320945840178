import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuDataService {

  
  private model : any = [];

  update(model:any) { 
    this.model=model
  }

  get selectedValue(){
    return this.model
  }
}
