import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-packages',
  standalone: true,
  imports: [TableModule, CurrencyPipe, DatePipe],
  templateUrl: './packages.component.html',
  styleUrl: './packages.component.scss',
})
export class PackagesComponent {
  packages = [
    {
      id: 1,
      hospitalPackage: 1200.0,
      creationDate: '2024-09-01T00:00:00Z',
      creatorUser: 'Γιάννης Παπαδόπουλος',
      insuranceCompany: 'Ασφαλιστική Α',
      lastChangeDate: '2024-09-10T00:00:00Z',
      lastChangedByUser: 'Μαρία Ιωάννου',
    },
    {
      id: 2,
      hospitalPackage: 850.0,
      creationDate: '2024-09-05T00:00:00Z',
      creatorUser: 'Αλίκη Τζόνσον',
      insuranceCompany: 'Ασφαλιστική Β',
      lastChangeDate: '2024-09-12T00:00:00Z',
      lastChangedByUser: 'Μιχάλης Βράχος',
    },
  ];
}
