import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@shared/environments';
import { MemberService } from '../member/member.service';

interface AuthResponse {
  userName: string;
  jwtToken: string;
  expiresIn: number;
  timeStamp: string;
  serviceOutcome: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = `${environment.api}baseAuthentication`;
  private authResponse: AuthResponse | null = null;

  currentUser$: Observable<any>;
  currentUserSubject = new BehaviorSubject<any>(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private memberService: MemberService
  ) {
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.initUser(); // Initialize user on service load
  }
  get currentUser(){
    return this.currentUserSubject.value;
  }

  // Initialize user on page reload by checking localStorage
  private initUser(): void {
    const token = this.getCurrentUserFromLocalStorage();
    if (token) {
      this.memberService.getEntityByUsername(token.userName).subscribe({
        next: (user) => {
          this.currentUserSubject.next(user);
        },
      });

    }
  }

  private getCurrentUserFromLocalStorage(): any {
    const userJson = localStorage.getItem('token');
    // console.log('userJson', JSON.parse(userJson as string));
    return userJson ? JSON.parse(userJson) : undefined;
  }

  login(username: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/login`, { username, password })
      .pipe(
        tap((response) => {
          this.authResponse = response;
          localStorage.setItem('token', JSON.stringify(this.authResponse)); // Store token
        })
      );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.currentUserSubject.next(null); // Clear the current user
    this.router.navigate(['login']);
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token || token == 'undefined') return false;

    const parsedResponse: AuthResponse = JSON.parse(token);

    return token ? !this.isTokenExpired(parsedResponse) : false;
  }

  private isTokenExpired(token: AuthResponse): boolean {
    try {
      const expiry =
        new Date(token.timeStamp).getTime() / 1000 + token.expiresIn; // Convert to seconds
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    } catch (e) {
      console.error('Error parsing token:', e);
      return true;
    }
  }
}
