import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-patient-diagnosis',
  standalone: true,
  imports: [InputTextareaModule, CheckboxModule,FormsModule],
  templateUrl: './patient-diagnosis.component.html',
  styleUrl: './patient-diagnosis.component.scss',
})
export class PatientDiagnosisComponent {
  checkBox: boolean = false;
}
