import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';
import { CategoryAmountComponent } from "./category-amount/category-amount.component";
import { InvoiceDetailsComponent } from "./invoice-details/invoice-details.component";
import { GroupedSumsComponent } from "./grouped-sums/grouped-sums.component";
import { PackagesComponent } from './packages/packages.component';

@Component({
  selector: 'app-proforma-invoice',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    CurrencyPipe,
    DatePipe,
    PercentPipe,
    CalendarModule,
    InputTextareaModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    MenubarModule,
    CategoryAmountComponent,
    InvoiceDetailsComponent,
    GroupedSumsComponent,
    PackagesComponent
],
  templateUrl: './proforma-invoice.component.html',
  styleUrl: './proforma-invoice.component.scss',
})
export class ProformaInvoiceComponent {
  invoices: any = [
    {
      id: 'A001',
      insuranceCompany: 'Ασφαλιστική Εταιρεία Α',
      issueDate: '2023-09-10',
      netAmount: '1000 ',
      vat: '24',
      grossAmount: '1240 ',
      patientAmount: '200 ',
      fundCharge: '800 ',
      totalPatientParticipation: '1000 ',
      invoiceDetails: {
        document: 'Τιμολόγιο 12345',
        eInvoice: 'eΤιμολόγιο 54321',
        error: 'Καμία',
        invalid: 'Όχι',
        creationDate: '2023-08-01',
        createdByUser: 'Γιάννης Παπαδόπουλος',
        lastChangeDate: '2023-08-05',
        lastChangedByUser: 'Μαρία Κωνσταντίνου',
        id: 'A001',
        invoiceClassification: 'Κανονικό',
      },
    },
    {
      id: 'B002',
      insuranceCompany: 'Ασφαλιστική Εταιρεία Β',
      issueDate: '2023-09-11',
      netAmount: '2000 ',
      vat: '24',
      grossAmount: '2480 ',
      patientAmount: '400 ',
      fundCharge: '1600 ',
      totalPatientParticipation: '2000 ',
      invoiceDetails: {
        document: 'Τιμολόγιο 67890',
        eInvoice: 'eΤιμολόγιο 09876',
        error: 'Μικρή καθυστέρηση',
        invalid: 'Ναι',
        creationDate: '2023-08-02',
        createdByUser: 'Ελένη Καραμήτρου',
        lastChangeDate: '2023-08-06',
        lastChangedByUser: 'Αντώνης Παναγιωτόπουλος',
        id: 'B002',
        invoiceClassification: 'Άκυρο',
      },
    },
  ];
 



  expandedRows = {};

  selectedInvoice: any;

  selectedMenuItem: string = '';
  menuItems = [
    {
      label: 'Ποσό Ανά Κατηγορία',
      command: () => this.selectMenuItem('amountPerCategory'),
      styleClass: this.selectedMenuItem === 'amountPerCategory' ? 'active-menu-item' : ''
    },
    {
      label: 'Αναλυτικές εγγραφές τιμολογίου',
      command: () => this.selectMenuItem('invoiceRecordDetails'),
      styleClass: this.selectedMenuItem === 'invoiceRecordDetails' ? 'active-menu-item' : ''
    },
    {
      label: 'Ομαδοποιημένα Σύνολα',
      command: () => this.selectMenuItem('groupedSums'),
      styleClass: this.selectedMenuItem === 'groupedSums' ? 'active-menu-item' : ''
    },

    {
      label: 'Ηλεκτρονικό Αρχείο',
      command: () => this.selectMenuItem('electronicFile'),
      styleClass: this.selectedMenuItem === 'electronicFile' ? 'active-menu-item' : ''
    },
    {
      label: 'Πακέτα',
      command: () => this.selectMenuItem('packages'),
      styleClass: this.selectedMenuItem === 'packages' ? 'active-menu-item' : ''
    },
  ];

  

  checkBox: boolean = false;
  constructor() {}

  ngOnInit() {}

  selectMenuItem(item: any): void {
    this.selectedMenuItem = item;
    // Update the styleClass for each menu item based on selection
    this.menuItems = this.menuItems.map(menuItem => ({
      ...menuItem,
      styleClass: menuItem.label === this.getLabelByItemKey(item) ? 'active-menu-item' : ''
    }));
  }

  // Helper method to get the label based on the selected key
  getLabelByItemKey(key: string): string {
    const map: { [key: string]: string } = {
      amountPerCategory: 'Ποσό Ανά Κατηγορία',
      invoiceRecordDetails: 'Αναλυτικές εγγραφές τιμολογίου',
      groupedSums: 'Ομαδοποιημένα Σύνολα',
      electronicFile: 'Ηλεκτρονικό Αρχείο',
      packages: 'Πακέτα'
    };
    return map[key] || '';
  }

  onRowExpand(event: TableRowExpandEvent) {}

  onRowCollapse(event: TableRowCollapseEvent) {}
}
