import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-grouped-sums',
  standalone: true,
  imports: [TableModule, CurrencyPipe],
  templateUrl: './grouped-sums.component.html',
  styleUrl: './grouped-sums.component.scss',
})
export class GroupedSumsComponent {
  serviceCategories = [
    {
      id: 1,
      category: 'ΝΟΣΗΛΕΙΑ',
      netAmount: 1200.0,
      credit: 300.0,
    },
    {
      id: 2,
      category: 'ΚΑΡΔΙΟΛΟΓΙΚΟ',
      netAmount: 850.0,
      credit: 200.0,
    },
    {
      id: 3,
      category: 'ΒΙΟΧΗΜΙΚΟ',
      netAmount: 400.0,
      credit: 100.0,
    },
    {
      id: 4,
      category: 'ΟΥΡΟΧΗΜΙΚΟ',
      netAmount: 650.0,
      credit: 150.0,
    },
    {
      id: 5,
      category: 'ΑΚΤΙΝΟΔΙΑΓΝΩΣΤΙΚΟ',
      netAmount: 950.0,
      credit: 250.0,
    },
    {
      id: 6,
      category: 'ΥΠΕΡΗΧΟΙ ΚΑΡΔΙΑΣ',
      netAmount: 300.0,
      credit: 75.0,
    },
    {
      id: 7,
      category: 'ΑΞΟΝΙΚΟΣ ΤΟΜΟΓΡΑΦΟΣ',
      netAmount: 1400.0,
      credit: 350.0,
    },
    {
      id: 8,
      category: 'ΑΙΜΑΤΟΛΟΓΙΚΟ',
      netAmount: 500.0,
      credit: 125.0,
    },
    {
      id: 9,
      category: 'ΥΓΕΙΟΝΟΜΙΚΟ ΥΛΙΚΟ',
      netAmount: 700.0,
      credit: 175.0,
    },
    {
      id: 10,
      category: 'ΦΑΡΜΑΚΑ',
      netAmount: 400.0,
      credit: 100.0,
    },
  ];
}
