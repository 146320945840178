<div class="grid">
  <div class="col-12">
    <div class="justify-content-center">
      <p-panel
        [toggleable]="true"
        collapseIcon="pi pi-angle-right"
        expandIcon="pi pi-angle-down"
        [collapsed]="true"
        class="custom-panel m-2"
      >
        <ng-template pTemplate="header">
          <div class="custom-header full-width">
            <div class="border-round full-width">
              <div class="flex">
                <div class="avatar-placeholder mr-3">Ατομικό</div>
                <div class="flex flex-column align-items-start header-text">
                  <span class="header-text">
                    <h2 class="">PREMIUM HEALTH 100% - Economy</h2>
                    <p><strong>Αρ. Συμβ.:</strong> 1213325454546</p>
                    <p><strong>Διάρκεια:</strong> Έτος</p>
                    <p><strong>Έναρξη:</strong> 12/03/2024</p>
                  </span>
                </div>
              </div>
              <div class="custom-line"></div>
              <div
                class="custom-header flex justify-content-between align-items-center"
              >
                <span class="header-text with-icon">
                  <i class="pi pi-check-circle icon-green"></i>
                  <strong>Ενεργό</strong>
                </span>
                <span class="header-text ml-auto">
                  <strong>Λήξη:</strong> 12/03/2025
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Σύνοψη Συμβολαίου</strong>
              </span>
            </ng-template>
            <div>
              <h6>
                Το πρόγραμμα Premium Health 100% Economy σας προσφέρει
                ολοκληρωμένη νοσοκομειακή κάλυψη σε Α' ή Β' θέση νοσηλείας σε
                προσιτή τιμή, χωρίς δική σας συμμετοχή στα έξοδα νοσηλείας.
              </h6>
              <br />
              Ποσό συμμετοχής στα έξοδα νοσηλείας<br />
              <strong>0 €</strong><br /><br />
              Θέση νοσηλείας <br />
              <strong>Α' Θέση ή Β' Θέση</strong><br /><br />
              Περιλαμβάνει<br />
              <strong>Κάλυψη για νοσηλεία μιας ημέρας</strong><br /><br />
              <strong>Όριο κάλυψης:</strong>
              <li>
                Έως 700.000€ ανά ασφαλιστική περίοδο, περιστατικό νοσηλείας και
                ασφαλισμένο
              </li>
              <li>
                100% κάλυψη αναγνωρισμένων εξόδων για τις αναγνωρισμένες δαπάνες
                σε νοσοκομείο στην Ελλάδα και στην Ευρώπη, 80% στο εξωτερικό
                εξαιρουμένης της Ευρώπης. 
              </li>
              <li>
                100% κάλυψη εξόδων σε Δημόσια Νοσοκομεία για τις αναγνωρισμένες
                δαπάνες χωρίς οικονομική συμμετοχή του Ασφαλισμένου (δεν
                εφαρμόζεται το εκπιπτόμενο ποσό)
              </li>
            </div>
          </p-panel>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Παροχές Συμβολαίου</strong>
              </span>
            </ng-template>

            <!-- Diagnostic Examinations -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Καλύψεις"
            >
              <div class="text-900">
                <ul>
                  <li>
                    Όλες οι δαπάνες για μονόκλινο δωμάτιο και διατροφή (Α' θέση
                    νοσηλείας) ή δίκλινο δωμάτιο και διατροφή (Β' θέση
                    νοσηλείας) νοσοκομείου όπως χρήση των Μονάδων Εντατικής
                    Θεραπείας ή Μονάδων Αυξημένης Φροντίδας, δαπάνες για
                    χημειοθεραπεία και ακτινοθεραπεία, κ.α
                  </li>
                  <li>
                    Αντιμετώπισης επειγόντων περιστατικών σε εξωτερικά ιατρεία
                    νοσοκομείου
                  </li>
                  <li>Μεταμοσχεύσεων</li>
                  <li>Νοσηλείας μίας μέρας</li>
                  <li>Αμοιβή αποκλειστικής νοσοκόμας</li>
                  <li>
                    Αμοιβή χειρουργών, αναισθησιολόγων και λοιπών ειδικοτήτων,
                    σύμφωνα με τους όρους του προγράμματος (σε μη συμβεβλημένα
                    νοσοκομεία ισχύει πίνακας που καθορίζει το ανώτατο όριο -
                    αμοιβών)
                  </li>
                  <li>Αποκατάστασης & αποθεραπείας σε κέντρα αποκατάστασης</li>
                  <li>
                    Δαπάνες προληπτικής μαστεκτομής (έως 50% των αναγνωρισμένων
                    εξόδων)
                  </li>
                  <li>Έξοδα συνοδού</li>
                  <li>Ασθενοφόρου (300€ ετησίως)</li>
                  <li>
                    Έξοδα πριν και μετά τη νοσηλεία που σχετίζονται με το
                    περιστατικό νοσηλείας (για διάστημα 1 μήνα πριν και 2 μήνες
                    μετά, έως 1.500€)
                  </li>
                  <li>
                    Επείγουσα ιατρική βοήθεια στην Ελλάδα και το εξωτερικό
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Medical Visits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Ειδικά επιδόματα"
            >
              <div class="text-900">
                <ul>
                  <li>
                    «Επίδομα συμμετοχής», «ημερήσιο επίδομα νοσηλείας», «εφάπαξ
                    Χειρουργικό Επίδομα»
                  </li>
                  <li>
                    «Επίδομα Μητρότητας» για φυσιολογικό τοκετό ή καισαρική
                    τομή (1.600€ εφάπαξ στην Α' θέση νοσηλείας και 1.200€ εφάπαξ
                    στην Β' θέση νοσηλείας)
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Checkup -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Checkup"
            >
              <div class="text-900">
                <ul>
                  <li>
                    Γενικός προληπτικός έλεγχος, Ειδικός προληπτικός
                    λιπιδαιμικός έλεγχος, Προληπτικός παιδιατρικός έλεγχος με
                    επιπλέον δυνατότητα πραγματοποίησης μεμονωμένων εξετάσεων
                    χωρίς παρουσία παραπεμπτικού, με τιμές ΦΕΚ
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Additional Benefits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Ειδικές διαγνωστικές εξετάσεις"
            >
              <div class="text-900">
                <ul>
                  <li>Ειδικές διαγνωστικές εξετάσεις εντός νοσοκομείου</li>
                </ul>
              </div>
            </p-panel>
          </p-panel>

          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Εξαιρέσεις Συμβολαίου</strong>
              </span>
            </ng-template>
            <div class="text-900">
              Υπάρχουν περιστατικά και συνθήκες που δεν μπορούν να καλυφθούν,
              όπως:
              <ul>
                <li>
                  Προϋπάρχουσες παθήσεις <br />
                  Δεν καλύπτουμε παθήσεις οι οποίες υπήρχαν ήδη, πριν την
                  υποβολή της αίτησης ασφάλισης σε εμάς.
                </li>
                <li>
                  Θεραπείες σε δόντια και οφθαλμούς που δεν οφείλονται σε
                  ατύχημα <br />
                  Δεν καλύπτουμε ακτινογραφίες ή χειρουργικές επεμβάσεις
                  δοντιών-ούλων (εκτός και αν είναι εξαιτίας καλυπτόμενου
                  ατυχήματος). Επίσης, δεν καλύπτουμε για θεραπεία ή εγχείρηση
                  για τη διόρθωση των διαθλαστικών ανωμαλιών των οφθαλμών.
                </li>
                <li>
                  Διακοπή κύησης και εξωσωματική <br />
                  Δεν καλύπτουμε διακοπή κύησης, υπογονιμότητα, εξωσωματική
                  γονιμοποίηση και επιπλοκές αυτών και θεραπείες.
                </li>
              </ul>
            </div>
          </p-panel>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Στοιχεία Ασφαλιστή</strong>
              </span>
            </ng-template>
            <div class="text-900">
              <strong>Επωνυμία Ασφαλιστικής Εταιρείας:</strong> Eurolife FFH<br />
              <strong>Έδρα:</strong> Πανεπιστημίου 33-35 & Κοραή, 10564<br />
              <strong>Τηλέφωνο Επικοινωνίας:</strong> 210 9303800<br />
              <strong>Διεύθυνση Ηλεκτρονικού Ταχυδρομείου:</strong>
              info[at]eurolife.gr<br />
              <strong>Ιστότοπος:</strong> www.eurolife.gr<br />
              <strong>Ώρες Λειτουργίας:</strong> Δευτέρα - Παρασκευή 9:00 -
              17:00<br />
              <strong>Υπεύθυνος Επικοινωνίας:</strong> Θεοδωροπούλου
              Αναστασία<br />
            </div>
          </p-panel>
        </div>
      </p-panel>

      <p-panel
        [toggleable]="true"
        collapseIcon="pi pi-angle-right"
        expandIcon="pi pi-angle-down"
        [collapsed]="true"
        class="custom-panel m-2"
      >
        <ng-template pTemplate="header">
          <div class="custom-header full-width">
            <div class="border-round full-width">
              <div class="flex">
                <div class="avatar-placeholder mr-3">Ομαδικό</div>
                <div class="flex flex-column align-items-start header-text">
                  <span class="header-text">
                    <h2>Ομαδικό Eurolife</h2>
                    <p><strong>Αρ. Συμβ.:</strong> 1422236384541</p>
                    <p><strong>Διάρκεια:</strong> Έτος</p>
                    <p><strong>Έναρξη:</strong> 01/01/2024</p>
                  </span>
                </div>
              </div>
              <div class="custom-line"></div>
              <div
                class="custom-header flex justify-content-between align-items-center"
              >
                <span class="header-text with-icon">
                  <i class="pi pi-check-circle icon-green"></i>
                  <strong>Ενεργό</strong>
                </span>
                <span class="header-text ml-auto">
                  <strong>Λήξη:</strong> 01/01/2025
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Σύνοψη Συμβολαίου</strong>
              </span>
            </ng-template>
            <div>
              <i class="pi pi-comments mr-2 small-icon"></i> Δωρεάν ιατρικές
              συμβουλές<br />
              <i class="pi pi-users mr-2 small-icon"></i> Δωρεάν εξετάσεις
              προγεννητικού ελέγχου<br />
              <i class="pi pi-shield mr-2 small-icon"></i> 8 βασικές καλύψεις
            </div>
          </p-panel>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Καλύψεις Συμβολαίου</strong>
              </span>
            </ng-template>
            <!-- Diagnostic Examinations with Limit of 700€ -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Διαγνωστικές Εξετάσεις με Όριο 700€"
            >
              <div class="text-900">
                • Μηδενική συμμετοχή στο κόστος των διαγνωστικών εξετάσεων με
                χρήση Ε.Ο.Π.Υ.Υ.<br />
                • Συμμετοχή 20% στο κόστος των διαγνωστικών εξετάσεων (σε τιμές
                ΦΕΚ) χωρίς χρήση Ε.Ο.Π.Υ.Υ.
              </div>
            </p-panel>

            <!-- Medical Visits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Επισκέψεις σε ιατρούς"
            >
              <div class="text-900">
                • Σε ιδιωτικό ιατρείο: Το χρηματικό ποσό που καταβάλει ο
                ασφαλισμένος κατά την επίσκεψη του στον ιδιώτη γιατρό του
                δικτύου είναι 23 ευρώ & περιλαμβάνει τις ακόλουθες
                ειδικότητες:<br />
                Αγγειοχειρουργός, Αλλεργιολόγος, Γαστρεντερολόγος, Γενικής
                ιατρικής, Γενικός χειρουργός, Γυναικολόγος, Δερματολόγος,
                Ενδοκρινολόγος-Διαβητολόγος, Καρδιολόγος, Νευρολόγος,
                Νευροχειρουργός, Νεφρολόγος, Ογκολόγος, Ορθοπεδικός, Ουρολόγος,
                Οφθαλμίατρος, Παθολόγος, Παιδίατρος, Παιδοκαρδιολόγος,
                Παιδοχειρουργός, Πνευμονολόγος, Ρευματολόγος,
                Ωτορινολαρυγγολόγος<br /><br />
                • Σε ιατρεία εντός κλινικών & διαγνωστικών κέντρων:<br />
                Το χρηματικό ποσό που καταβάλει ο ασφαλισμένος κατά την επίσκεψη
                του στον γιατρό του δικτύου είναι 23 ευρώ & περιλαμβάνει τις
                ακόλουθες ειδικότητες:<br />
                Αγγειοχειρουργός, Αλλεργιολόγος, Γαστρεντερολόγος, Γενικής
                ιατρικής, Γενικός χειρουργός, Γυναικολόγος, Δερματολόγος,
                Ενδοκρινολόγος-Διαβητολόγος, Καρδιολόγος, Νευρολόγος,
                Νευροχειρουργός, Νεφρολόγος, Ογκολόγος, Ορθοπεδικός, Ουρολόγος,
                Οφθαλμίατρος, Παθολόγος, Παιδίατρος, Παιδοκαρδιολόγος,
                Παιδοχειρουργός, Πνευμονολόγος, Ρευματολόγος,
                Ωτορινολαρυγγολόγος<br /><br />
                • Επίσκεψη ιατρού κατ'οίκον:<br />
                40 € ανά επίσκεψη κατά τις εργάσιμες ώρες και ημέρες.<br />
                60 € ανά επίσκεψη κατά τις μη εργάσιμες ώρες και ημέρες, καθώς
                και τις αργίες
              </div>
            </p-panel>

            <!-- Free Checkup -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Δωρεάν Check Up"
            >
              <div class="text-900">
                Δίνεται η δυνατότητα στον χρήστη να πραγματοποιήσει ένα ετήσιο,
                δωρεάν check up. Οι εξετάσεις διαφοροποιούνται ανάλογα με το
                φύλο & την ηλικία. Πιο αναλυτικά η λίστα περιλαμβάνει:<br />
                • Παιδικό-εφηβικό (1 μηνός έως 20 ετών):<br />
                Γενική αίματος, Σάκχαρο, ΤΚΕ, Χοληστερόλη ολική, Γενική ούρων<br />
                • Άνδρες & Γυναίκες (άνω των 21 ετών)<br />
                Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη, Χοληστερόλη ολική,
                HDL, LDL, Τριγλυκερίδια, Γενική ούρων
              </div>
            </p-panel>

            <!-- Physiotherapy -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Φυσιοθεραπείες"
            >
              <div class="text-900">
                • 20€ αμοιβή φυσιοθεραπευτή σε συμβεβλημένο κέντρο<br />
                • 30€ αμοιβή για κατ'οίκον φυσιοθεραπεία
              </div>
            </p-panel>

            <!-- Dental Coverage with 40% Discount -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Οδοντιατρική κάλυψη με έκπτωση 40%"
            >
              <div class="text-900">
                Παρέχεται έκπτωση 40% επί του εκάστοτε ιδιωτικού καταλόγου σε
                δίκτυο συνεργαζόμενων οδοντιάτρων, σε οδοντιατρικές πράξεις
              </div>
            </p-panel>

            <!-- Free Ophthalmology Examination -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Δωρεάν Οφθαλμολογικός έλεγχος"
            >
              <div class="text-900">
                Δωρεάν ετήσιος οφθαλμολογικός έλεγχος στο δίκτυο των
                συμβεβλημένων διαγνωστικών κέντρων.<br />
                Οι εξετάσεις περιλαμβάνουν: Εξέταση (διαθλαστικός έλεγχος),
                εξέταση σε σχισμοειδή λυχνία, τονομέτρηση, βυθοσκόπηση,
                χρωματική αντίληψη
              </div>
            </p-panel>

            <!-- Prenatal Screening -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Προγεννητικός έλεγχος"
            >
              <div class="text-900">
                Παρέχεται δωρεάν η δυνατότητα στις ασφαλισμένες γυναίκες, να
                πραγματοποιήσουν συγκεκριμένη λίστα εξετάσεων σχετικές με την
                εγκυμοσύνη τους. Πιο συγκεκριμένα καλύπτονται οι:<br />
                VDRL, HBSAG, ANTI-HCV, HIV (I,II), Αντισώματα Κυτταρομεγαλοϊου
                (IGG, IGM), Αντισώματα Ερυθράς (IGG, IGM), Αντισώματα
                Τοξοπλάσματος (IGG, IGM), Γενική αίματος, Ομάδα αίματος, Rhesus,
                Ηλεκτροφόρηση αιμοσφαιρίνης, Γενική ούρων
              </div>
            </p-panel>

            <!-- Additional Benefits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Επιπλέον παροχές"
            >
              <div class="text-900">
                Ιατρικές συμβουλές στο 1010<br />
                Ασφάλιση ζωής 100 €
              </div>
            </p-panel>
          </p-panel>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Εξαιρέσεις Συμβολαίου</strong>
              </span>
            </ng-template>
            <!-- Pre-existing Medical Conditions -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Προϋπάρχουσες Ιατρικές Καταστάσεις"
            >
              <div class="text-900">
                Οποιαδήποτε προϋπάρχουσα ιατρική κατάσταση πριν από την έναρξη
                του ασφαλιστικού συμβολαίου δεν καλύπτεται, εκτός εάν έχει
                συμφωνηθεί και αναφέρεται ρητά στο συμβόλαιο.
              </div>
            </p-panel>

            <!-- Cosmetic Surgery -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Αισθητικές ή Πλαστικές Επεμβάσεις"
            >
              <div class="text-900">
                Κάθε είδους επέμβαση ή θεραπεία που έχει αισθητικό χαρακτήρα και
                δεν είναι ιατρικά αναγκαία, όπως πλαστικές επεμβάσεις, δεν
                καλύπτεται από το ασφαλιστικό πρόγραμμα.
              </div>
            </p-panel>

            <!-- Substance Abuse -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Κατάχρηση Ουσιών ή Αλκοόλ"
            >
              <div class="text-900">
                Θεραπείες ή υπηρεσίες υγείας που απαιτούνται ως αποτέλεσμα της
                κατάχρησης ναρκωτικών ουσιών, αλκοόλ ή άλλων τοξικών ουσιών δεν
                καλύπτονται από την ασφάλιση.
              </div>
            </p-panel>

            <!-- Experimental Treatments -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Πειραματικές Θεραπείες"
            >
              <div class="text-900">
                Οι θεραπείες που θεωρούνται πειραματικές ή μη αναγνωρισμένες από
                τις αρμόδιες υγειονομικές αρχές δεν περιλαμβάνονται στις
                καλύψεις του ασφαλιστικού συμβολαίου.
              </div>
            </p-panel>

            <!-- Fertility Treatments -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Θεραπείες Υπογονιμότητας"
            >
              <div class="text-900">
                Οι θεραπείες και οι ιατρικές διαδικασίες που αφορούν την
                υπογονιμότητα, όπως εξωσωματική γονιμοποίηση, δεν καλύπτονται
                από την ασφάλιση υγείας, εκτός εάν αναφέρεται διαφορετικά στο
                συμβόλαιο.
              </div>
            </p-panel>

            <!-- Injuries from Hazardous Activities -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Τραυματισμοί από Επικίνδυνες Δραστηριότητες"
            >
              <div class="text-900">
                Τυχόν τραυματισμοί που προκύπτουν από τη συμμετοχή σε
                επικίνδυνες δραστηριότητες, όπως extreme sports, ορειβασία ή
                αγώνες ταχύτητας, δεν καλύπτονται.
              </div>
            </p-panel>

            <!-- War and Terrorism -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Πόλεμος και Τρομοκρατία"
            >
              <div class="text-900">
                Απώλειες ή τραυματισμοί που προκύπτουν από πολεμικές
                συγκρούσεις, τρομοκρατικές ενέργειες, στρατιωτική δράση ή
                πολιτική αναταραχή εξαιρούνται από την κάλυψη.
              </div>
            </p-panel>
          </p-panel>

          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Στοιχεία Ασφαλιστή</strong>
              </span>
            </ng-template>
            <div class="text-900">
              <strong>Επωνυμία Ασφαλιστικής Εταιρείας:</strong> Eurolife FFH<br />
              <strong>Έδρα:</strong> Πανεπιστημίου 33-35 & Κοραή, 10564<br />
              <strong>Τηλέφωνο Επικοινωνίας:</strong> 210 9303800<br />
              <strong>Διεύθυνση Ηλεκτρονικού Ταχυδρομείου:</strong>
              info[at]eurolife.gr<br />
              <strong>Ιστότοπος:</strong> www.eurolife.gr<br />
              <strong>Ώρες Λειτουργίας:</strong> Δευτέρα - Παρασκευή 9:00 -
              17:00<br />
              <strong>Υπεύθυνος Επικοινωνίας:</strong> Θεοδωροπούλου
              Αναστασία<br />
            </div>
          </p-panel>
        </div>
      </p-panel>

      <p-panel
        [toggleable]="true"
        collapseIcon="pi pi-angle-right"
        expandIcon="pi pi-angle-down"
        [collapsed]="true"
        class="custom-panel m-2"
      >
        <ng-template pTemplate="header">
          <div class="custom-header full-width">
            <div class="border-round full-width">
              <div class="flex">
                <div class="avatar-placeholder mr-3">Ατομικό</div>
                <div class="flex flex-column align-items-start header-text">
                  <span class="header-text">
                    <h2>PREMIUM HEALTH 1500 - Economy</h2>
                    <p><strong>Αρ. Συμβ.:</strong> 1213325454545</p>
                    <p><strong>Διάρκεια:</strong> Έτος</p>
                    <p><strong>Έναρξη:</strong> 12/03/2023</p>
                  </span>
                </div>
              </div>
              <div class="custom-line"></div>
              <div
                class="custom-header flex justify-content-between align-items-center"
              >
                <span class="header-text with-icon">
                  <i class="pi pi-times-circle icon-red"></i>
                  <strong>Ανενεργό</strong>
                </span>
                <span class="header-text ml-auto">
                  <strong>Λήξη:</strong> 12/03/2024
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div>
          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Σύνοψη Συμβολαίου</strong>
              </span>
            </ng-template>
            <div>
              <h6>
                Το πρόγραμμα Premium Health 1.500€ Economy σας προσφέρει
                νοσοκομειακή κάλυψη σε Α' ή Β' θέση νοσηλείας με χαμηλό
                ασφάλιστρο, αφού έχετε την οικονομική δυνατότητα να καλύπτετε με
                δική σας συμμετοχή ή μέσω άλλου φορέα έξοδα νοσηλείας ύψους
                1.500€.
              </h6>
              <br />
              Ποσό συμμετοχής στα έξοδα νοσηλείας<br />
              <strong>1.500 €</strong><br /><br />
              Θέση νοσηλείας <br />
              <strong>Α' Θέση ή Β' Θέση</strong><br /><br />
              Περιλαμβάνει<br />
              <strong
                >Εξειδικευμένες διαγνωστικές εξετάσεις εντός νοσοκομείου</strong
              ><br /><br />
              <strong>Όριο κάλυψης:</strong>
              <li>
                Έως 700.000€ ανά ασφαλιστική περίοδο, περιστατικό νοσηλείας και
                ασφαλισμένο
              </li>
              <li>
                100% κάλυψη αναγνωρισμένων εξόδων μετά την αφαίρεση του
                εκπιπτόμενου ποσού των 1.500€ για τις αναγνωρισμένες δαπάνες σε
                νοσοκομείο στην Ελλάδα και στην Ευρώπη, 80% στο εξωτερικό
                εξαιρουμένης της Ευρώπης.
              </li>
              <li>
                100% κάλυψη εξόδων σε Δημόσια Νοσοκομεία για τις αναγνωρισμένες
                δαπάνες χωρίς οικονομική συμμετοχή του Ασφαλισμένου (δεν
                εφαρμόζεται το εκπιπτόμενο ποσό)
              </li>
            </div>
          </p-panel>

          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Παροχές Συμβολαίου</strong>
              </span>
            </ng-template>

            <!-- Diagnostic Examinations -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Καλύψεις"
            >
              <div class="text-900">
                <ul>
                  <li>
                    Όλες οι δαπάνες για μονόκλινο δωμάτιο και διατροφή (Α' θέση
                    νοσηλείας) ή δίκλινο δωμάτιο και διατροφή (Β' θέση
                    νοσηλείας) νοσοκομείου όπως χρήση των Μονάδων Εντατικής
                    Θεραπείας ή Μονάδων Αυξημένης Φροντίδας, δαπάνες για
                    χημειοθεραπεία και ακτινοθεραπεία, κ.α
                  </li>
                  <li>
                    Αντιμετώπισης επειγόντων περιστατικών σε εξωτερικά ιατρεία
                    νοσοκομείου
                  </li>
                  <li>Μεταμοσχεύσεων</li>
                  <li>Νοσηλείας μίας μέρας</li>
                  <li>Αμοιβή αποκλειστικής νοσοκόμας</li>
                  <li>
                    Αμοιβή χειρουργών, αναισθησιολόγων και λοιπών ειδικοτήτων,
                    σύμφωνα με τους όρους του προγράμματος (σε μη συμβεβλημένα
                    νοσοκομεία ισχύει πίνακας που καθορίζει το ανώτατο όριο -
                    αμοιβών)
                  </li>
                  <li>Αποκατάστασης & αποθεραπείας σε κέντρα αποκατάστασης</li>
                  <li>
                    Δαπάνες προληπτικής μαστεκτομής (έως 50% των αναγνωρισμένων
                    εξόδων)
                  </li>
                  <li>Έξοδα συνοδού</li>
                  <li>Ασθενοφόρου (300€ ετησίως)</li>
                  <li>
                    Έξοδα πριν και μετά τη νοσηλεία που σχετίζονται με το
                    περιστατικό νοσηλείας (για διάστημα 1 μήνα πριν και 2 μήνες
                    μετά, έως 1.500€)
                  </li>
                  <li>
                    Επείγουσα ιατρική βοήθεια στην Ελλάδα και το εξωτερικό
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Medical Visits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Ειδικά επιδόματα"
            >
              <div class="text-900">
                <ul>
                  <li>
                    «Επίδομα συμμετοχής», «ημερήσιο επίδομα νοσηλείας», «εφάπαξ
                    Χειρουργικό Επίδομα»
                  </li>
                  <li>
                    «Επίδομα Μητρότητας» για φυσιολογικό τοκετό ή καισαρική τομή
                    (500€ εφάπαξ)
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Checkup -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Checkup"
            >
              <div class="text-900">
                <ul>
                  <li>
                    Γενικός προληπτικός έλεγχος, Ειδικός προληπτικός
                    λιπιδαιμικός έλεγχος, Προληπτικός παιδιατρικός έλεγχος με
                    επιπλέον δυνατότητα πραγματοποίησης μεμονωμένων εξετάσεων
                    χωρίς παρουσία παραπεμπτικού, με τιμές ΦΕΚ
                  </li>
                </ul>
              </div>
            </p-panel>

            <!-- Additional Benefits -->
            <p-panel
              [toggleable]="true"
              collapseIcon="pi pi-angle-right"
              expandIcon="pi pi-angle-down"
              [collapsed]="true"
              header="Ειδικές διαγνωστικές εξετάσεις"
            >
              <div class="text-900">
                <ul>
                  <li>Ειδικές διαγνωστικές εξετάσεις εντός νοσοκομείου</li>
                </ul>
              </div>
            </p-panel>
          </p-panel>

          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Εξαιρέσεις Συμβολαίου</strong>
              </span>
            </ng-template>
            <div class="text-900">
              Υπάρχουν περιστατικά και συνθήκες που δεν μπορούν να καλυφθούν,
              όπως:
              <ul>
                <li>
                  Προϋπάρχουσες παθήσεις <br />
                  Δεν καλύπτουμε παθήσεις οι οποίες υπήρχαν ήδη, πριν την
                  υποβολή της αίτησης ασφάλισης σε εμάς.
                </li>
                <li>
                  Θεραπείες σε δόντια και οφθαλμούς που δεν οφείλονται σε
                  ατύχημα <br />
                  Δεν καλύπτουμε ακτινογραφίες ή χειρουργικές επεμβάσεις
                  δοντιών-ούλων (εκτός και αν είναι εξαιτίας καλυπτόμενου
                  ατυχήματος). Επίσης, δεν καλύπτουμε για θεραπεία ή εγχείρηση
                  για τη διόρθωση των διαθλαστικών ανωμαλιών των οφθαλμών.
                </li>
                <li>
                  Διακοπή κύησης και εξωσωματική <br />
                  Δεν καλύπτουμε διακοπή κύησης, υπογονιμότητα, εξωσωματική
                  γονιμοποίηση και επιπλοκές αυτών και θεραπείες.
                </li>
              </ul>
            </div>
          </p-panel>

          <p-panel
            [toggleable]="true"
            collapseIcon="pi pi-angle-right"
            expandIcon="pi pi-angle-down"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <span class="header-text">
                <strong>Στοιχεία Ασφαλιστή</strong>
              </span>
            </ng-template>
            <div class="text-900">
              <strong>Επωνυμία Ασφαλιστικής Εταιρείας:</strong> Eurolife FFH<br />
              <strong>Έδρα:</strong> Πανεπιστημίου 33-35 & Κοραή, 10564<br />
              <strong>Τηλέφωνο Επικοινωνίας:</strong> 210 9303800<br />
              <strong>Διεύθυνση Ηλεκτρονικού Ταχυδρομείου:</strong>
              info[at]eurolife.gr<br />
              <strong>Ιστότοπος:</strong> www.eurolife.gr<br />
              <strong>Ώρες Λειτουργίας:</strong> Δευτέρα - Παρασκευή 9:00 -
              17:00<br />
              <strong>Υπεύθυνος Επικοινωνίας:</strong> Θεοδωροπούλου
              Αναστασία<br />
            </div>
          </p-panel>
        </div>
      </p-panel>
    </div>
  </div>
</div>