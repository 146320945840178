import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuService } from './app.menu.service';
import { MenuDataService } from './service/menu-data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  constructor(
    public layoutService: LayoutService,
    private menuDataService: MenuDataService
  ) {}

  ngOnInit() {
    this.model = this.menuDataService.selectedValue;
  }
}
