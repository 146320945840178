import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor() { }

  getAreas(): Observable<any> {
    return of([
      { name: 'Κέντρο', code: 'kentro' },
      { name: 'Βόρεια Προάστεια', code: 'voreia-proasteia' },
      { name: 'Νότια Προάστεια', code: 'notia-proasteia' },
      { name: 'Δυτικά Προάστεια', code: 'ditika-proasteia' },
      { name: 'Ανατολικά Προάστεια', code: 'anatolika-proasteia' },
    ]);
  }



}
