import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  AuthService,
  MemberService,
  MenuDataService,
} from 'shared';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Προγράμματα Κάλυψης',
          icon: 'pi pi-fw pi-info-circle',
          routerLink: ['coverage-programs'],
        },
        {
          label: 'Απαιτήσεις',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/claims-table'],
        },
      ],
    },
  ];

  constructor(
    private primengConfig: PrimeNGConfig,
    private menuDataService: MenuDataService,
    private memberService: MemberService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'doctor';
    const user = JSON.parse(localStorage.getItem('token')!);
    if (user) {
      this.authService.fetchUser(user).subscribe(() => {});
    }
  }
}
