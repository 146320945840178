<div id="map" *ngIf="acceptedLocationPermission && displayMap" style="height: 500px">

  <div *ngFor="let doctor of doctorCoordinates" hidden>
    <div *ngFor="let address of doctor.addresses">
      <p (click)="showDoctorDetails(doctor.doctorData, address)" style="cursor: pointer;">
        {{ doctor.doctorData.fullName }} at ({{ address.lat }}, {{ address.lon }}) - {{ address.fullAddress }}
      </p>
    </div>
  </div>

  <div *ngIf="selectedDoctor && selectedAddress" class="doctor-details">
    <div class="header flex align-items-center justify-content-between mb-3">
      <div class="text-xl text-primary font-bold flex align-items-center">
        <span class="p-badge text-white p-badge-no-gutter mr-2">
          <i class="pi pi-info" style="font-size: x-small;"></i>
        </span>
          Πληροφορίες
      </div>
      <!-- <button class="p-button p-button-text" (click)="closeDetails()">×</button> -->
        <i class="pi pi-times" (click)="closeDetails()"></i>
    </div>
    <p><strong><i class="pi pi-user mr-2"></i> {{ selectedDoctor.fullName }}</strong></p>
    <p><strong><i class="pi pi-briefcase mr-2"></i> {{ selectedDoctor.specialty.name }}</strong></p>
    <p><strong><i class="pi pi-map-marker mr-2"></i> {{ selectedAddress.fullAddress }}</strong></p>
    <!-- <p><strong><i class="pi pi-star mr-2"></i> {{ selectedDoctor.rating }}</strong></p> -->
    <div class="flex justify-content-center mt-3">
      <button type="button" class="p-button" [routerLink]="['/doctor-details/', selectedDoctor.userId]">Κλείσε Ραντεβού</button>
      <!-- <button pButton type="button" class="p-button-outlined p-button" (click)="closeDetails()">Κλείσιμο</button> -->
    </div>
  </div>

  <div *ngFor="let dc of dcCoordinates" hidden>
    <div *ngFor="let dcaddress of dc.clinicAddresses">
      <p (click)="showDCDetails(dc.diagnosticCenterData, dcaddress)" style="cursor: pointer;">
        {{ dc.diagnosticCenterData.name }} at ({{ dcaddress.lat }}, {{ dcaddress.lon }}) - {{ dcaddress.fullAddress }}
      </p>
    </div>
  </div>

  <div *ngIf="selectedDc && selectedDcAddress" class="doctor-details">
    <div class="header flex align-items-center justify-content-between mb-3">
      <div class="text-xl text-primary font-bold flex align-items-center">
        <span class="p-badge text-white p-badge-no-gutter mr-2">
          <i class="pi pi-info" style="font-size: x-small;"></i>
        </span>
          Πληροφορίες
      </div>
      <!-- <button class="p-button p-button-text" (click)="closeDetails()">×</button> -->
        <i class="pi pi-times" (click)="closeDcDetails()"></i>
    </div>
    <p><strong><i class="pi pi-search-plus mr-2"></i> {{ selectedDc.name }}</strong></p>
    <p><strong><i class="pi pi-briefcase mr-2"></i> {{ selectedDc.description }}</strong></p>
    <p><strong><i class="pi pi-map-marker mr-2"></i> {{ selectedDcAddress.fullAddress }}</strong></p>
    <!-- <p><strong><i class="pi pi-star mr-2"></i> {{ selecteddiagnosticCenter.rating }}</strong></p> -->
    <div class="flex justify-content-center mt-3">
      <button type="button" class="p-button" [routerLink]="['/diagnostic-center-details/', selectedDc.userId]">Κλείσε Ραντεβού</button>
      <!-- <button pButton type="button" class="p-button-outlined p-button" (click)="closeDetails()">Κλείσιμο</button> -->
    </div>
  </div>



  <!-- <div *ngIf="selectedDoctor && selectedAddress">
    <p-dialog
      header="Πληροφορίες"
      [(visible)]="showDetails"
      [modal]="true"
      [style]="{ width: '30rem' }"
      [resizable]="false"
      [draggable]="false"
      showEffect="fade"
    >
      <div class="flex flex-column">
        <p><strong><i class="pi pi-user mr-2"></i> {{ selectedDoctor.fullName }}</strong></p>
        <p><strong><i class="pi pi-map-marker mr-2"></i> {{ selectedAddress.fullAddress }}</strong></p>
        <p><strong><i class="pi pi-star mr-2"></i> {{ selectedDoctor.rating }}</strong></p>
        <p><strong><i class="pi pi-briefcase mr-2"></i> {{ selectedDoctor.specialty.name }}</strong></p>
      </div>
      <ng-template pTemplate="footer">
        <button type="button" class="p-button" [routerLink]="['/doctor-details/', selectedDoctor.userId]">Κλείσε Ραντεβού</button>
      </ng-template>
    </p-dialog>
  </div> -->
  
</div>