import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Claim } from '../../interfaces/claim';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root',
})
export class ClaimsService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}patients/`;

  claims: any[] = [
    {
      id: 10,
      endDate: '13/02/2024',
      startDate: '13/02/2023',
      healthcareProvider: 'N/A',
      incident: 'Παθολογικό',
      icd10: 'N/A',
      ken: 'N/A',
      amount: '4,500€',
      folder: 'N/A',
      outsideNetwork: false,
      doctor: 'OLYMPIC PIRAEUS ΙΔΙΩΤΙΚΟ ΠΟΛΥΙΑΤΡΕΙΟ-ΙΔΙΩΤΙΚΟ ΔΙΑΓΝ',
    },
    {
      id: 11,
      endDate: '21/01/2024',
      startDate: '21/01/2023',
      healthcareProvider: 'N/A',
      incident: 'Χειρουργικό',
      icd10: 'N/A',
      ken: 'N/A',
      amount: '8,200€',
      folder: 'N/A',
      outsideNetwork: false,
      doctor: 'ΑΘΑΝΑΣΟΠΟΥΛΟΣ ΑΛΕΞΙΟΣ',
    },
    {
      id: 12,
      endDate: '20/02/2024',
      startDate: '20/02/2022',
      healthcareProvider: 'N/A',
      incident: 'Παθολογικό',
      icd10: 'N/A',
      ken: 'N/A',
      amount: '6,800€',
      folder: 'N/A',
      outsideNetwork: false,
      doctor: 'ΠΑΡΑΣΧΟΥ ΑΓΓΕΛΙΚΗ ΤΟΥ ΕΥΑΓΓΕΛΟΥ',
    },
    {
      id: 4,
      endDate: '15/03/2023',
      startDate: '15/03/2023',
      healthcareProvider: 'N/A',
      incident: 'Χειρουργικό',
      icd10: 'G92',
      ken: 'G92A',
      amount: '3,000€',
      folder: 'Yes',
      outsideNetwork: true,
      type: 'Ανοικτή Νοσηλεία',
      doctor: 'Γενικό Νοσοκομείο Αθηνών',
    },
    {
      id: 5,
      endDate: '10/04/2023',
      startDate: '10/04/2023',
      healthcareProvider: 'N/A',
      incident: 'Ογκολογικό',
      icd10: 'C03',
      ken: 'C03B',
      amount: '1,500€',
      folder: 'No',
      outsideNetwork: true,
      type: 'Κλειστή Νοσηλεία',
      doctor: 'Ιδιωτική Κλινική Αθηνών',
    },
    {
      id: 6,
      endDate: '25/05/2023',
      startDate: '25/05/2023',
      healthcareProvider: 'N/A',
      incident: 'Χειρουργικό',
      icd10: 'D04',
      ken: 'D04C',
      amount: '900€',
      folder: 'Yes',
      outsideNetwork: true,
      type: 'Ανοικτή Νοσηλεία',
      doctor: 'Διαγνωστικό Κέντρο Θεσσαλονίκης',
    },
    {
      id: 1,
      endDate: '13/02/2023',
      startDate: '13/02/2023',
      healthcareProvider: 'N/A',
      incident: 'Χημειοθεραπεία',
      icd10: 'G91',
      ken: 'Ν01Μ',
      amount: '2,500€',
      folder: 'Yes',
      outsideNetwork: false,
      doctor: 'OLYMPIC PIRAEUS ΙΔΙΩΤΙΚΟ ΠΟΛΥΙΑΤΡΕΙΟ-ΙΔΙΩΤΙΚΟ ΔΙΑΓΝ',
    },
    {
      id: 2,
      endDate: '21/01/2023',
      startDate: '21/01/2023',
      healthcareProvider: 'N/A',
      incident: 'Ακτινοθεραπεία',
      icd10: 'A01',
      ken: 'A01A',
      amount: '1,000€',
      folder: 'No',
      outsideNetwork: false,
      doctor: 'ΑΘΑΝΑΣΟΠΟΥΛΟΣ ΑΛΕΞΙΟΣ',
    },
    {
      id: 3,
      endDate: '20/02/2022',
      startDate: '20/02/2022',
      healthcareProvider: 'N/A',
      incident: 'Χειρουργικό',
      icd10: 'B02',
      ken: 'B02B',
      amount: '750€',
      folder: 'Yes',
      outsideNetwork: false,
      doctor: 'ΠΑΡΑΣΧΟΥ ΑΓΓΕΛΙΚΗ ΤΟΥ ΕΥΑΓΓΕΛΟΥ',
    },
  ];

  constructor(private http: HttpClient) {}

  getClaims(): Observable<any> {
    return of(this.claims);
  }
}
