import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root'
})
export class HospitalDataService {

  

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  apiUrl: string = `${environment.api}medicalCenters/`

  constructor(private http: HttpClient) { }


  getClinicPatientsByUserId(UserId: number) {
    return this.http.get<any>(
      `${this.apiUrl}${UserId}/patients`,
      this.httpOptions
    );
  }
  getClinicDataByUsername(doctorName: any) {
    return this.http.get<any>(
      `${this.apiUrl}me/${doctorName}`,
      this.httpOptions
    );
  }
  
}
