import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';

@Component({
  selector: 'app-invoice-details',
  standalone: true,
  imports: [TableModule, CurrencyPipe, DatePipe, ButtonModule],
  templateUrl: './invoice-details.component.html',
  styleUrl: './invoice-details.component.scss',
})
export class InvoiceDetailsComponent {
  detailedInvoice = [
    {
      id: 1,
      executionDate: '2024-09-01T00:00:00Z',
      hospitalCode: 'ΝΟΣΚ001',
      quantity: 15,
      directive: 'Κατευθυντήρια Οδηγία 1',
      cutJustification: 'Εξήγηση για περικοπή 1',
      disputedAmount: 180.0,
      netAmount: 1200.0,
      packageExamsMember: 250.0,
      contractBasedCheck: 75.0,
      errorMessage: 'Χωρίς σφάλματα',
      patientAmount: 150.0,
      publicBodyAmount: 1050.0,
      'contract100%': 600.0,
      invoiceDetails: {
        'agreementPrice20%': 240.0,
        auditorAmount: 180.0,
        auditorAmountApprovedPercent: 85,
        auditorClaim: 'Αίτημα 1',
        comments: 'Δεν παρατηρήθηκαν προβλήματα',
        creationDate: '2024-09-01T00:00:00Z',
        creationTime: '09:30',
        creatorUser: 'Γιάννης Παπαδόπουλος',
      },
    },
    {
      id: 2,
      executionDate: '2024-09-06T00:00:00Z',
      hospitalCode: 'ΝΟΣΚ002',
      quantity: 25,
      directive: 'Κατευθυντήρια Οδηγία 2',
      cutJustification: 'Εξήγηση για περικοπή 2',
      disputedAmount: 300.0,
      netAmount: 2000.0,
      packageExamsMember: 400.0,
      contractBasedCheck: 100.0,
      errorMessage: 'Μικρό σφάλμα',
      patientAmount: 250.0,
      publicBodyAmount: 1750.0,
      'contract100%': 750.0,
      invoiceDetails: {
        'agreementPrice20%': 400.0,
        auditorAmount: 300.0,
        auditorAmountApprovedPercent: 90,
        auditorClaim: 'Αίτημα 2',
        comments: 'Εξετάστηκε και εγκρίθηκε',
        creationDate: '2024-09-06T00:00:00Z',
        creationTime: '13:45',
        creatorUser: 'Μαρία Κωνσταντίνου',
      },
    },
  ];

  expandedRows = {};

  onRowExpand(event: TableRowExpandEvent) {}

  onRowCollapse(event: TableRowCollapseEvent) {}
}
