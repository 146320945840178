import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-imports',
  standalone: true,
  imports: [TableModule],
  templateUrl: './imports.component.html',
  styleUrl: './imports.component.scss',
})
export class ImportsComponent {
  imports: any = [
    {
      id: '364340',
      importDate: '13/02/2020 09:36:00',
      exportDate: '13/02/2020',
      importStatus: 'Με εξιτήριο',
      reasonForEntry: 'ΦΛΕΒΙΚΗ ΑΝΕΠΑΡΚΕΙΑ ΚΑΤΩ ΑΚΡΩΝ',
      xmth: '',
      auditorsOutputDiagnosis: 'I83 Κιρσοί των κάτω άκρων',
      importType: 'Ημερήσια Νοσηλεία (ODC-ODS)',
      hospital: 'ΥΓΕΙΑ',
      clinicDepartment: 'ΑΓΓΕΙΟΧΕΙΡΟΥΡΓΙΚΗ',
      insuranceInfo: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής 209186185',
      charge: '3545,57',
      auditorsConclusion: 'Αποδεκτό',
    },
    {
      id: '359593',
      importDate: '28/01/2020 08:30:00',
      exportDate: '28/01/2020',
      importStatus: 'Με εξιτήριο',
      reasonForEntry: 'ΦΛΕΒΙΚΗ ΑΝΕΠΑΡΚΕΙΑ ΚΑΤΩ ΑΚΡΩΝ',
      xmth: '',
      auditorsOutputDiagnosis: 'I83 Κιρσοί των κάτω άκρων',
      importType: 'Ημερήσια Νοσηλεία (ODC-ODS)',
      hospital: 'ΥΓΕΙΑ',
      clinicDepartment: 'ΑΓΓΕΙΟΧΕΙΡΟΥΡΓΙΚΗ',
      insuranceInfo: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής 209186185',
      charge: '3409,58',
      auditorsConclusion: 'Αποδεκτό',
    },
    {
      id: '153876',
      importDate: '28/05/2017 20:36:40',
      exportDate: '29/05/2017',
      importStatus: 'Με εξιτήριο',
      reasonForEntry: 'ΑΙΣΘΗΜΑ ΠΑΛΜΩΝ-ΔΥΣΠΝΟΙΑ',
      xmth: '',
      auditorsOutputDiagnosis: 'I20 Στηθάγχη',
      importType: 'Εσωτερικός Ασθενής',
      hospital: 'ΥΓΕΙΑ',
      clinicDepartment: 'ΠΑΘΟΛΟΓΙΚΗ',
      insuranceInfo: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής 209186185',
      charge: '1771,02',
      auditorsConclusion: 'Μερικώς Αποδεκτό',
    },
  ];

  importsCount:number = this.imports.length;
}
