<div class="card">
  <div
    class="flex justify-content-center align-items-center flex-wrap gap-2 mb-3"
  >
    <button
      pButton
      class="p-button"
      *ngFor="let status of statuses"
      (click)="showTable(status.label)"
    >
    <i [class]="status.icon" style="margin-right: 0.5rem;"></i>
      {{ status.label }}
    </button>
  </div>

  <p-table
    [value]="getFilteredClaims()"
    responsiveLayout="scroll"
    [rows]="5"
    responsiveLayout="scroll"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [paginator]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between flex-wrap row-gap-2">
        <div class="flex flex-wrap gap-3">
          <input
            pInputText
            [(ngModel)]="searchText"
            placeholder="Αναζήτηση..."
          />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          Κατάσταση <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th pSortableColumn="auditorClaimId">
          Auditor Claim ID <p-sortIcon field="auditorClaimId"></p-sortIcon>
        </th>
        <th pSortableColumn="patientName">
          Ασθενής <p-sortIcon field="patientName"></p-sortIcon>
        </th>
        <th pSortableColumn="insuranceCompany">
          Εταιρεία ασφάλισης <p-sortIcon field="insuranceCompany"></p-sortIcon>
        </th>
        <th>Αριθμός συμβολαίου</th>
        <th pSortableColumn="healthProvider">
          Πάροχος Υγείας <p-sortIcon field="healthProvider"></p-sortIcon>
        </th>
        <th pSortableColumn="incidentDate">
          Ημερ/νια έναρξης συμβάντος
          <p-sortIcon field="incidentDate"></p-sortIcon>
        </th>
        <th pSortableColumn="incidentType">
          Περιστατικό <p-sortIcon field="incidentType"></p-sortIcon>
        </th>
        <th pSortableColumn="admissionStatus">
          Κατάσταση Εισαγωγής <p-sortIcon field="admissionStatus"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-claim>
      <tr>
        <td>
            <i [ngClass]="getStatusIconClass(claim.status).icon"
               [class]="getStatusIconClass(claim.status).colorClass"></i>
        </td>
        <td>{{ claim.auditorClaimId }}</td>
        <td>{{ claim.patientName }}</td>
        <td>{{ claim.insuranceCompany }}</td>
        <td>
          <ul style="list-style-type: none; padding-left: 0; margin: 0;">
            <li *ngFor="let policy of claim.policyNumbers">{{ policy }}</li>
          </ul>
        </td>
        <td>{{ claim.healthProvider }}</td>
        <td>{{ claim.incidentDate | date : "dd/MM/yyyy" }}</td>
        <td>{{ claim.incidentType }}</td>
        <td>{{ claim.admissionStatus }}</td>
        <td>
          <a
            class="hover:text-orange-700 font-bold cursor-pointer"
            [routerLink]="['/claim-details/', claim.auditorClaimId]"
            ><i class="pi pi-ellipsis-v"></i
          ></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
