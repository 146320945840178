import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-audits',
  standalone: true,
  imports: [TableModule],
  templateUrl: './audits.component.html',
  styleUrl: './audits.component.scss',
})
export class AuditsComponent {
  audits: any = [
    {
      type: 'Έντυπο αναγγελίας αποζημίωσης',
      auditorConclusion: 'Αποδεκτό',
      acceptableDuration: '12 μήνες',
      lastChangeDate: '01 Σεπ 2023',
      lastChangeUser: 'Επιθεωρητής Παπαδόπουλος Γιάννης',
      filename: 'έντυπο_αναγγελίας_αποζημίωσης.pdf'
    },
    {
      type: 'Έντυπο προγραμματισμένης νοσηλείας',
      auditorConclusion: 'Αποδεκτό',
      acceptableDuration: '6 μήνες',
      lastChangeDate: '15 Αυγ 2023',
      lastChangeUser: 'Δρ. Κωνσταντίνου Μαρία',
      filename: 'έντυπο_προγραμματισμένης_νοσηλείας.pdf'
    },
    {
      type: 'Γνωμάτευση πριν τη νοσηλεία',
      auditorConclusion: 'Αποδεκτό',
      acceptableDuration: '3 μήνες',
      lastChangeDate: '20 Ιουλ 2023',
      lastChangeUser: 'Επιθεωρητής Οικονομίδη Μαρία',
      filename: 'γνωμάτευση_πριν_τη_νοσηλεία.pdf'
    },
    {
      type: 'Αποτελέσματα εξετάσεων πριν τη νοσηλεία',
      auditorConclusion: 'Αποδεκτό',
      acceptableDuration: '12 μήνες',
      lastChangeDate: '05 Σεπ 2023',
      lastChangeUser: 'Επιθεωρητής Στεφανίδης Θεόδωρος',
      filename: 'αποτελέσματα_εξετάσεων_πριν_τη_νοσηλεία.pdf'
    },
    {
      type: 'Βιβλιάριο υγείας',
      auditorConclusion: 'Αποδεκτό',
      acceptableDuration: '6 μήνες',
      lastChangeDate: '30 Αυγ 2023',
      lastChangeUser: 'Δρ. Αλεξίου Ελένη',
      filename: 'βιβλιάριο_υγείας.pdf'
    },
  ];

  auditsCount:number = this.audits.length;
}
