import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Button, ButtonModule } from 'primeng/button';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';

@Component({
  selector: 'app-arbitration',
  standalone: true,
  imports: [TableModule, ButtonModule, DatePipe, CurrencyPipe],
  templateUrl: './arbitration.component.html',
  styleUrl: './arbitration.component.scss',
})
export class ArbitrationComponent {
  arbitrations = [
    {
      id: 1,
      arbitration: 'Αρ. 123/2024',
      completed: 'Ναι',
      branch: 'Καρδιολογία',
      dateOfEDENotification: '2024-07-15',
      dateOfInsuranceEDENotification: '2024-07-20',
      details: {
        dateOfEDEMinutesReceipt: '2024-07-25',
        dateOfEDEMinutesPosting: '2024-07-28',
        creationDate: '2024-06-01',
        creatorUser: 'Γιώργος Παπαδόπουλος',
        lastChangeDate: '2024-08-10',
        lastChangedByUser: 'Ελένη Σαμαρά',
      },
    },
    {
      id: 2,
      arbitration: 'Αρ. 456/2024',
      completed: 'Όχι',
      branch: 'Ορθοπεδική',
      dateOfEDENotification: '2024-08-01',
      dateOfInsuranceEDENotification: '2024-08-05',
      details: {
        dateOfEDEMinutesReceipt: '2024-08-10',
        dateOfEDEMinutesPosting: '2024-08-15',
        creationDate: '2024-07-10',
        creatorUser: 'Μαρία Κωνσταντίνου',
        lastChangeDate: '2024-08-12',
        lastChangedByUser: 'Νίκος Αλεξανδρόπουλος',
      },
    },
  ];

  expandedRows = {};

  onRowExpand(event: TableRowExpandEvent) {}

  onRowCollapse(event: TableRowCollapseEvent) {}
}
