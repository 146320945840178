import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-guarantee-letters',
  standalone: true,
  imports: [TableModule, CommonModule],
  templateUrl: './guarantee-letters.component.html',
  styleUrl: './guarantee-letters.component.scss',
})
export class GuaranteeLettersComponent {
  guaranteeLetters: any = [
    {
      insuranceBranch: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής',
      insuranceAnswer: 'Με επιφύλαξη',
      guaranteeDesc: '1η Απάντηση Mega Care',
      temporary: 'checkmark',
      completed: 'checkmark',
      userWhoCompleted: 'ΦΩΤΟΠΟΥΛΟΥ ΑΓΓΕΛΙΚΗ',
      completionDate: '08/12/2020',
      invalid: 'checkmark',
      coverageRate: '100%',
      exemptionAmount: '0,00',
      maxCoverage: '',
      insuranceManager: 'ΦΩΤΟΠΟΥΛΟΥ ΑΓΓΕΛΙΚΗ',
      documents: '',
      creationDate: '08/12/2020 14:46:07',
      id: '438337 || 558947',
      dateOfLastChange: '13/01/2021 11:07:27',
      userOfLastChange: 'ΑΛΕΞΑΝΔΡΟΥ ΜΑΡΙΑ',
    },
    {
      insuranceBranch: 'ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Ατομικά Ζωής',
      insuranceAnswer: 'Καλύπτεται',
      guaranteeDesc: 'Τελικό Mega Care',
      temporary: '',
      completed: 'checkmark',
      userWhoCompleted: 'ΑΛΕΞΑΝΔΡΟΥ ΜΑΡΙΑ',
      completionDate: '13/01/2021',
      invalid: '',
      coverageRate: '100%',
      exemptionAmount: '0,00',
      maxCoverage: '',
      insuranceManager: 'ΦΩΤΟΠΟΥΛΟΥ ΑΓΓΕΛΙΚΗ',
      documents: '',
      creationDate: '12/01/2021 14:02:20',
      id: '438337 || 567721',
      dateOfLastChange: '13/01/2021 11:15:20',
      userOfLastChange: 'ΚΟΛΟΒΟΥ ΚΩΝΣΤΑΝΤΙΝΑ',
    },
  ];

  guaranteeLettersCount:number = this.guaranteeLetters.length;
}
