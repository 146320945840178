import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  AppLayoutComponent,
  AuthGuard,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
} from 'shared';






@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Πίνακας Ελέγχου' },
              loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'coverage-programs',
              data: { breadcrumb: 'Προγράμματα Κάλυψης' },
              loadComponent: () => import('./pages/coverage-programs/coverage-programs.component').then(m => m.CoverageProgramsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'claims-table',
              data: { breadcrumb: 'Απαιτήσεις' },
              loadComponent: () => import('./pages/claims-table/claims-table.component').then(m => m.ClaimsTableComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'claim-details/:id',
              data: { breadcrumb: '' },
              loadComponent: () => import('./pages/claim-details/claim-details.component').then(m => m.ClaimDetailsComponent),
              canActivate: [AuthGuard],
            },
          ],
        },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
