import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  AppLayoutComponent,
  AuthGuard,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
} from 'shared';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CoverageProgramsComponent } from './pages/coverage-programs/coverage-programs.component';
import { ClaimDetailsComponent } from './pages/claim-details/claim-details.component';

import { ClaimsTableComponent } from './pages/claims-table/claims-table.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Πίνακας Ελέγχου' },
              component: DashboardComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'coverage-programs',
              data: { breadcrumb: 'Προγράμματα Κάλυψης' },
              component: CoverageProgramsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'claims-table',
              data: { breadcrumb: 'Απαιτήσεις' },
              component: ClaimsTableComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'claim-details/:id',
              data: { breadcrumb: '' },
              component: ClaimDetailsComponent,
              canActivate: [AuthGuard],
            },
          ],
        },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
