<div class="font-medium text-3xl text-900 mb-3 mt-3">Πακέτα</div>

<p-table
  [value]="packages"
  dataKey="id"
  [tableStyle]="{ 'min-width': '60rem' }"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="id">ID <p-sortIcon field="id" /></th>
      <th pSortableColumn="hospitalPackage">
        Πακέτο Νοσοκομείου <p-sortIcon field="hospitalPackage" />
      </th>
      <th pSortableColumn="creationDate">
        Ημ. Δημιουργίας <p-sortIcon field="creationDate" />
      </th>
      <th pSortableColumn="creatorUser">
        Χρήστης Δημιουργίας <p-sortIcon field="creatorUser" />
      </th>
      <th pSortableColumn="insuranceCompany">
        Ασφαλιστική <p-sortIcon field="insuranceCompany" />
      </th>
      <th pSortableColumn="lastChangeDate">
        Ημ. Τελευταίας Αλλαγής <p-sortIcon field="lastChangeDate" />
      </th>
      <th pSortableColumn="lastChangedByUser">
        Χρήστης Τελευταίας Αλλαγής <p-sortIcon field="lastChangedByUser" />
      </th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-service>
    <tr>
      <td>{{ service.id }}</td>
      <td>{{ service.hospitalPackage | currency : "EUR" }}</td>
      <td>{{ service.creationDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ service.creatorUser }}</td>
      <td>{{ service.insuranceCompany }}</td>
      <td>{{ service.lastChangeDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ service.lastChangedByUser }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">Δεν υπάρχουν διαθέσιμα δεδομένα.</td>
    </tr>
  </ng-template>
</p-table>
