import { NgModule } from '@angular/core';

export {Appointment} from './appointment'
export * from './contract'
export * from './doctor'
export * from './examcenter'
export * from './incident'
export * from './insured/insured'
export * from './claim'
export * from './authresponse'
export * from './auditorclaim'
export * from './insured/visit-dto'
export * from './insured/exam-result'
export * from './insured/reference-dto'

@NgModule({
  declarations: [],
  exports: [],
})
export class InterfaceModule { }
