<div class="grid row-gap-2">
  <div class="col-12">
    <div class="grid">
      @for(data of kpiCards();track $index;){
      <div class="col-12 md:col-6 xl:col-3">
        <lib-kpi-card [data]="data"></lib-kpi-card>
      </div>
      }
    </div>
  </div>

  <ng-content></ng-content>
</div>
