import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-files',
  standalone: true,
  imports: [TableModule, ButtonModule],
  templateUrl: './files.component.html',
  styleUrl: './files.component.scss',
})
export class FilesComponent {
  files: any = {
    files: [
      {
        name: 'έντυπο_αναγγελίας_αποζημίωσης.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '30 Jul 2021',
        fileSize: '12 MB',
      },
      {
        name: 'έντυπο_προγραμματισμένης_νοσηλείας.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '31 Jul 2021',
        fileSize: '6 MB',
      },
      {
        name: 'βιβλιάριο_υγείας.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '1 Aug 2021',
        fileSize: '32 MB',
      },
      {
        name: 'αποτελέσματα_εξετάσεων_πριν_τη_νοσηλεία.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '2 Aug 2021',
        fileSize: '15 MB',
      },
      {
        name: 'γνωμάτευση_πριν_τη_νοσηλεία.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '3 Aug 2021',
        fileSize: '3.2 MB',
      },
      {
        name: 'απόφαση_αιτήματος_αποζημίωσης.pdf',
        icon: 'pi pi-file-pdf',
        dateOfUpload: '29 Jul 2021',
        dateOfUpdate: '3 Aug 2021',
        fileSize: '5 MB',
      },
    ],
  };
}
