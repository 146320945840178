<div class="flex justify-content-center align-items-center">
  <span class="text-900 text-lg mb-3 border-round bg-primary-100 p-2">
    <strong>Εισαγωγές:</strong>  {{ importsCount }} εγγραφές
  </span>
</div>

<p-table
  responsiveLayout="scroll"
  #dt
  [value]="imports"
  [rows]="8"
  [paginator]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="id"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        ID <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th
        pSortableColumn="importDate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Εισαγωγής <p-sortIcon field="importDate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="exportDate"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Εξιτηρίου <p-sortIcon field="exportDate"></p-sortIcon>
      </th>
      <th
        pSortableColumn="importStatus"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Κατάσταση Εισαγωγής <p-sortIcon field="importStatus"></p-sortIcon>
      </th>
      <th
        pSortableColumn="reasonForEntry"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αιτία Εισόδου <p-sortIcon field="reasonForEntry"></p-sortIcon>
      </th>
      <th
        pSortableColumn="xmth"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        ΧΜΘ <p-sortIcon field="xmth"></p-sortIcon>
      </th>
      <th
        pSortableColumn="auditorsOutputDiagnosis"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Διάγνωση Εξόδου Ελεγκτή <p-sortIcon field="auditorsOutputDiagnosis"></p-sortIcon>
      </th>
      <th
        pSortableColumn="importType"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Τύπος Εισαγωγής <p-sortIcon field="importType"></p-sortIcon>
      </th>
      <th
        pSortableColumn="hospital"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Νοσοκομείο <p-sortIcon field="hospital"></p-sortIcon>
      </th>
      <th
        pSortableColumn="clinicDepartment"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Κλινική/Τμήμα <p-sortIcon field="clinicDepartment"></p-sortIcon>
      </th>
      <th
        pSortableColumn="insuranceInfo"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Πληροφορίες Ασφαλιστικής <p-sortIcon field="insuranceInfo"></p-sortIcon>
      </th>
      <th
        pSortableColumn="charge"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Χρέωση <p-sortIcon field="charge"></p-sortIcon>
      </th>
      <th
        pSortableColumn="auditorsConclusion"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Συμπέρασμα Ελεγκτή <p-sortIcon field="auditorsConclusion"></p-sortIcon>
      </th>     
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-imports>
    <tr>
      <td>
        <span>{{ imports.id }}</span>
      </td>
      <td>
        <span>{{ imports.importDate }}</span>
      </td>
      <td>
        <span>{{ imports.exportDate }}</span>
      </td>
      <td>
        <span>{{ imports.importStatus }}</span>
      </td>
      <td>
        <span>{{ imports.reasonForEntry }}</span>
      </td>
      <td>
        <span>{{ imports.xmth }}</span>
      </td>
      <td>
        <span>{{ imports.auditorsOutputDiagnosis }}</span>
      </td>
      <td>
        <span>{{ imports.importType }}</span>
      </td>
      <td>
        <span>{{ imports.hospital }}</span>
      </td>
      <td>
        <span>{{ imports.clinicDepartment }}</span>
      </td>
      <td>
        <span>{{ imports.insuranceInfo }}</span>
      </td>
      <td>
        <span>{{ imports.charge }}</span>
      </td>
      <td>
        <span>{{ imports.auditorsConclusion }}</span>
      </td>      
    </tr>
  </ng-template>
</p-table>