<div class="grid row-gap-3">
  <div class="col-12">
    <div class="grid">
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-0 flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-list font-bold text-2xl text-white mr-2"></i
            >Συνολικοί Έλεγχοι
          </h5>
          <h1 class="mb-1 m-0 text-white">350</h1>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-check font-bold text-2xl text-white mr-2"></i>
            Ολοκληρωμένοι Έλεγχοι
          </h5>
          <h1 class="mb-1 m-0 text-white">1200</h1>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-spinner font-bold text-2xl text-white mr-2"></i>
            Έλεγχοι σε Εξέλιξη
          </h5>
          <h1 class="mb-1 m-0 text-white">650</h1>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i
              class="pi pi-minus-circle font-bold text-2xl text-white mr-2"
            ></i>
            Συνολικές Περικοπές
          </h5>
          <h1 class="mb-1 m-0 text-white">340</h1>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-chart-bar font-bold text-2xl text-white mr-2"></i>
            Μέσος Όρος Περικοπών ανα Έλεγχο
          </h5>
          <h1 class="mb-1 m-0 text-white">650</h1>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-4">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-percentage font-bold text-2xl text-white mr-2"></i>
            Μέσο Ποσοστό Περικοπών
          </h5>
          <h1 class="mb-1 m-0 text-white">340</h1>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="donut"></app-dashboard-charts>
  </div>

  <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="horizontal-bar"></app-dashboard-charts>
  </div>
  <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="visits"></app-dashboard-charts>
  </div>
  <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="diagnosis"></app-dashboard-charts>
  </div> -->
  <div class="lg:col-12 col-12">
    <div class="card h-full">
      <div class="card-header gap-3">
        <div class="card-title"></div>
        <p-dropdown
          [options]="dateRanges"
          [(ngModel)]="selectedDate"
          [placeholder]="selectedDate"
          optionLabel="name"
          [showClear]="false"
          (onChange)="changeChartPeriod($event)"
          styleClass="w-9rem"
        ></p-dropdown>
      </div>
      <app-dashboard-charts
        chartCase="line"
        [data]="data"
        [labels]="labels"
      ></app-dashboard-charts>
    </div>
  </div>
</div>
