import { Component, input, InputSignal } from '@angular/core';
import {
  KpiCardComponent,
  KpiCardI,
} from '../../ui-components/kpi-card/kpi-card.component';

@Component({
  selector: 'lib-base-dashboard',
  standalone: true,
  imports: [KpiCardComponent],
  templateUrl: './base-dashboard.component.html',
  styleUrl: './base-dashboard.component.css',
})
export class BaseDashboardComponent {
  kpiCards: InputSignal<KpiCardI[]> = input.required();
}
