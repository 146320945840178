<div class="font-medium text-3xl text-900 mb-3 mt-3">
  Αναλυτικές εγγραφές τιμολογίου
</div>

<p-table
  [value]="detailedInvoice"
  dataKey="id"
  [tableStyle]="{ 'min-width': '80rem' }"
  [expandedRowKeys]="expandedRows"
  (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem"></th>
      <th pSortableColumn="insuranceCompany">
        Hμ.Εκτέλεσης <p-sortIcon field="insuranceCompany" />
      </th>
      <th>Κωδικός Νοσοκομείου</th>
      <th pSortableColumn="netAmount">
        Ποσότητα<p-sortIcon field="netAmount" />
      </th>
      <th pSortableColumn="vat">Οδηγία <p-sortIcon field="vat" /></th>
      <th pSortableColumn="grossAmount">
        Αιτιολόγηση περικοπής <p-sortIcon field="grossAmount" />
      </th>
      <th pSortableColumn="patientAmount">
        Αμφισβητούμενο<p-sortIcon field="patientAmount" />
      </th>
      <th pSortableColumn="fundCharge">
        Καθαρό Ποσό <p-sortIcon field="fundCharge" />
      </th>
      <th pSortableColumn="totalPatientParticipation">
        Μέλος Πακέτου Εξετάσεων
        <p-sortIcon field="totalPatientParticipation" />
      </th>
      <th pSortableColumn="patientAmount">
        Έλεγχο βάσει Σύμβασης<p-sortIcon field="patientAmount" />
      </th>
      <th pSortableColumn="fundCharge">
        Μήνυμα Λάθους<p-sortIcon field="fundCharge" />
      </th>
      <th pSortableColumn="totalPatientParticipation">
        Ποσό Ασθενή
        <p-sortIcon field="totalPatientParticipation" />
      </th>
      <th pSortableColumn="fundCharge">
        Ποσό δημόσιου φορέα<p-sortIcon field="fundCharge" />
      </th>
      <th pSortableColumn="totalPatientParticipation">
        Σύμβαση 100%
        <p-sortIcon field="totalPatientParticipation" />
      </th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-invoice let-expanded="expanded">
    <tr>
      <td>
        <p-button
          type="button"
          pRipple
          [pRowToggler]="invoice"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        />
      </td>
      <td>{{ invoice.executionDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ invoice.hospitalCode }}</td>
      <td>{{ invoice.quantity }}</td>
      <td>{{ invoice.directive }}</td>
      <td>{{ invoice.cutJustification }}</td>
      <td>{{ invoice.disputedAmount | currency : "EUR" }}</td>
      <td>{{ invoice.netAmount | currency : "EUR" }}</td>
      <td>{{ invoice.packageExamsMember | currency : "EUR" }}</td>
      <td>{{ invoice.contractBasedCheck | currency : "EUR" }}</td>
      <td>{{ invoice.errorMessage }}</td>
      <td>{{ invoice.patientAmount | currency : "EUR" }}</td>
      <td>{{ invoice.publicBodyAmount | currency : "EUR" }}</td>
      <td>{{ invoice["contract100%"] | currency : "EUR" }}</td>
    </tr>
  </ng-template>

  <!-- Row Expansion Template -->
  <ng-template pTemplate="rowexpansion" let-invoice>
    <tr>
      <td colspan="8">
        <div class="p-3">
          <p-table [value]="[invoice.invoiceDetails]" dataKey="id">
            <ng-template pTemplate="header">
              <tr>
                <th>Agreement Price 20%</th>
                <th>Ποσό Ελεγκτή</th>
                <th>Auditor Amount Approved Percent</th>
                <th>Κατήγ. Ελεγκτή</th>
                <th>Σχόλια</th>
                <th>Ημ. Δημιουργίας</th>
                <th>Ώρα Δημιουργίας</th>
                <th>Χρήστης Δημιουργίας</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-detail>
              <tr>
                <td>{{ detail["agreementPrice20%"] | currency : "EUR" }}</td>
                <td>{{ detail.auditorAmount | currency : "EUR" }}</td>
                <td>{{ detail.auditorAmountApprovedPercent }}%</td>
                <td>{{ detail.auditorClaim }}</td>
                <td>{{ detail.comments }}</td>
                <td>{{ detail.creationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ detail.creationTime }}</td>
                <td>{{ detail.creatorUser }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="10">No invoice details available.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
