import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InsurerConfigService {
  constructor() {}

  getUserConfig(insurerName: string) {
    // Ideally, this data would come from an API endpoint
    const userConfigs = [
      {
        insurerName: 'eurolife',
        appName: 'PHI - Eurolife',
        themeColor:'red',
        logoUrl: '../../assets/layout/images/phi_eurolife.png',
      },
      {
        insurerName: 'vpapadakis',
        appName: 'PHI - Insurance',
        themeColor:'avocado',
        logoUrl: '../../assets/layout/images/phi-logo.png',
      },
      // Add more user configurations as needed
    ];

    return userConfigs.find((insurer) => insurer.insurerName == insurerName.toLocaleLowerCase());
  }
}
