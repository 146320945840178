<div class="font-medium text-3xl text-900 mb-3 mt-3">Περικοπές</div>

<p-table
  [value]="hospitalData"
  dataKey="id"
  [tableStyle]="{ 'min-width': '80rem' }"
>
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>Ημερομηνία Εισαγωγής</th>
      <th>Περικοπή</th>
      <th>Χρέωση Νοσοκομείου</th>
      <th>Ποσό Ελεγκτή</th>
      <th>Ποσό Περικοπής</th>
      <th>Μείωση Διαιτησίας</th>
      <th>Τελική Περικοπή</th>
      <th>Αιτιολόγηση Μείωσης</th>
      <th>Σχόλια</th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-deduction>
    <tr>
      <td>{{ deduction.id }}</td>
      <td>{{ deduction.importDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ deduction.deduction }}</td>
      <td>{{ deduction.hospitalCharge | currency : "EUR" }}</td>
      <td>{{ deduction.auditorAmount | currency : "EUR" }}</td>
      <td>{{ deduction.deductionAmount | currency : "EUR" }}</td>
      <td>{{ deduction.arbitrationReduction | currency : "EUR" }}</td>
      <td>{{ deduction.finalDeduction | currency : "EUR" }}</td>
      <td>{{ deduction.reductionReason }}</td>
      <td>{{ deduction.comments }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="11">Δεν υπάρχουν διαθέσιμες περικοπές.</td>
    </tr>
  </ng-template>
</p-table>
