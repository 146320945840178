import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImportsModule } from 'shared';

@Component({
  selector: 'app-dashboard-charts',
  standalone: true,
  imports: [ImportsModule, NgApexchartsModule],
  templateUrl: './dashboard-charts.component.html',
  styleUrl: './dashboard-charts.component.scss',
})
export class DashboardChartsComponent implements OnInit, OnChanges {
  chartOptions: any;
  @Input() chartCase: string = '';
  @Input() period: any;

  primaryColor = '#5297FF';
  grayColor = '#6c757d'; // Use a standard gray color

  @Input() labels: any;
  @Input() data: any;
  ngOnInit() {
    this.getChartOptions(this.chartCase);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.data = changes['data'].currentValue;
    this.labels = changes['labels'].currentValue;
    this.getChartOptions('line');
  }
  getChartOptions(chartCase: string) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );

    switch (chartCase) {
      case 'donut':
        this.chartOptions = {
          series: [60, 40], // Example data: 60% males, 40% females
          chart: {
            type: 'donut',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary,
          },
          labels: ['Άνδρες', 'Γυναίκες'], // Greek for "Males" and "Females"
          title: {
            text: 'Κατανομή Ανδρών και Γυναικών', // Greek for "Distribution of Males and Females"
            align: 'center',
          },
          colors: [this.primaryColor, this.grayColor],
        };
        break;
      case 'horizontal-bar':
        this.chartOptions = {
          series: [
            {
              name: 'Population',
              data: [8, 6, 5, 7, 3], // Example data for the age groups
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            },
          },
          xaxis: {
            categories: ['0-18', '19-35', '36-50', '51-65', '66+'], // Age groups
            min: 0,
            max: 10,
            tickAmount: 5, // Number of ticks from 0 to 10 (0, 2, 4, 6, 8, 10)
          },
          title: {
            text: 'Ηλικιακή Ομάδα',
            align: 'center',
          },
          // colors: [this.primaryColor],
          colors: ['#00E396', '#33CC99', '#66B2FF', '#3399FF', '#008FFB'],
        };
        break;
      case 'visits':
        this.chartOptions = {
          series: [
            {
              name: 'Patient Visits',
              data: [20, 30, 50, 75, 100, 120, 115, 110, 85, 60, 40, 25],
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary,
          },
          plotOptions: {
            bar: {
              horizontal: false, // This makes the bars vertical
              columnWidth: '80%', // Width of the bars
              endingShape: 'rounded', // Rounded bars
              distributed: true,
            },
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
          },
          title: {
            text: 'Κατανομή Επισκέψεων ανά Έτος/Μήνα',
            align: 'center',
          },
          // colors: [this.primaryColor],
          colors: [
            '#00E396',
            '#33CC99',
            '#66B2FF',
            '#3399FF',
            '#008FFB',
            '#0055FF',
            '#00CCFF',
            '#00BFFF',
            '#0099FF',
            '#33CCCC',
            '#66FF99',
            '#33FF66',
          ],
        };
        break;

      case 'diagnosis':
        this.chartOptions = {
          series: [
            {
              name: 'Patient Visits',
              data: [49, 69, 99, 110, 160, 205, 115, 110, 85, 60, 40, 25],
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary,
          },
          plotOptions: {
            bar: {
              horizontal: false, // This makes the bars vertical
              columnWidth: '80%', // Width of the bars
              endingShape: 'rounded', // Rounded bars
              distributed: true,
            },
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
          },
          title: {
            text: 'Κατανομή Διαγνώσεων ανά Έτος/Μήνα',
            align: 'center',
          },
          // colors: [this.primaryColor],
          colors: [
            '#00C7E6',
            '#00A8CC',
            '#0096C7',
            '#0077B6',
            '#005F73',
            '#80FFDB',
            '#72EFDD',
            '#64DFDF',
            '#56CFE1',
            '#48BFE3',
            '#5390D9',
            '#6930C3',
          ],
        };
        break;
      case 'line':
        this.chartOptions = {
          series: [
            {
              name: 'Ημερήσιο',
              data: this.data, // Δείγμα δεδομένων
            },
            // {
            //   name: 'Εβδομαδιαίο',
            //   data: [60, 80, 70, 90, 85, 110, 130, 150], // Δείγμα δεδομένων
            // },
            // {
            //   name: 'Ετήσιο',
            //   data: [100, 120, 105, 140, 130, 160, 180, 200], // Δείγμα δεδομένων
            // },
          ],
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: 'Συχνότητα Aσφαλιστικών Aπαιτήσεων',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: this.labels,
          },
          // colors: [this.primaryColor],
          colors: [
            '#00C7E6',
            '#00A8CC',
            '#0096C7',
            '#0077B6',
            '#005F73',
            '#80FFDB',
            '#72EFDD',
            '#64DFDF',
            '#56CFE1',
            '#48BFE3',
            '#5390D9',
            '#6930C3',
          ],
        };
        break;
    }
  }
}
