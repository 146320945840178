import { DecimalPipe, NgClass } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'lib-kpi-card',
  standalone: true,
  imports: [RouterLink, ButtonModule, NgClass, DecimalPipe],
  templateUrl: './kpi-card.component.html',
  styleUrl: './kpi-card.component.css',
})
export class KpiCardComponent {
  data: InputSignal<KpiCardI> = input.required();
}

export interface KpiCardI {
  header: string[];
  headerIcon: string;
  pipe: string | null;
  dataCount: number;
  button: KpiButtonI;
}
export interface KpiButtonI {
  routerLink: string;
  label: string;
}
