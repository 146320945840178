<p-table
  #dt1
  [value]="paginatedData()"
  [loading]="dataLoading()"
  dataKey="id"
  [rowHover]="true"
  styleClass="p-datatable-striped"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="header">
    <tr>
      @for(header of tableConfig().headers;track header){
      <th scope="col">{{ header }}</th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-index="rowIndex">
    <tr>
      @for (key of tableConfig().jsonKeys; track key; let i =$index;) { @if
      (tableConfig().dataType[i] === 'TEXT') {
      <td>{{ rowData[key] }}</td>
      } @if (tableConfig().dataType[i] === 'DATE') {
      <td>{{ rowData[key] | date : "dd/MM/yyyy" }}</td>
      } @if (tableConfig().dataType[i] === 'TAG') {
      <td>
        <p-tag [value]="rowData[key]" [severity]="getSeverity(rowData[key])">
        </p-tag>
      </td>
      } @if (tableConfig().dataType[i] === 'CURRENCY') {
      <td>{{ rowData[key] | currency : "EUR" }}</td>
      } }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="tableConfig().dataType.length">No data found.</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator
  [rows]="5"
  [totalRecords]="data().length"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>
