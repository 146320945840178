<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo cursor-pointer">
            <img [src]="logoSrc" class="p-p-3 p-mb-3" style="width: 130px; height: auto;"/>
        </a>
        <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    </div>

    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
