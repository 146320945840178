<div class="surface-section p-4 border-round shadow-2 mb-3">
  <ul class="list-none p-0 m-0">
    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Όνομα:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">ΚΑΡΑΣΤΑΘΗ ΧΡΥΣΟΥΛΑ Θ. | 30/10/1976</div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Νοσοκομείο:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Υγεία</div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Κατάσταση Εισαγωγής:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Με εξιτήριο</div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Νοσηλευτικός Τομέας:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Παθολογικό</div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Διαιτησία:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">ΥΓΕΙΑ 26/01/2021 10:00 1317 1317</div>
    </li>
    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
      <div class="text-500 w-6 md:w-2 font-medium">Κατάσταση:</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Ολοκληρωμένο</div>
    </li>
  </ul>
</div>
<p-menubar [model]="items"></p-menubar>
<div class="surface-section p-4 border-round shadow-2 mt-3">
  <ng-container *ngComponentOutlet="currentComponent"></ng-container>
</div>
