/*
 * Public API Surface of shared
 */

/* Export Partials*/
export * from './lib/partials/table-plain/table-plain.component';

/* Export Base Classes */
export * from './lib/base/base-dashboard/base-dashboard.component';

/* Export utils*/
export * from './lib/utils/resolvers/get-user.resolver';
export * from './lib/services/auth/jwt.interceptor';
export * from './lib/utils/interceptors/error.interceptor';

// Export AuthGuard
export * from './lib/services/auth/auth.guard';
export * from './lib/services/auth/auth.service';
export * from './lib/services/chat/chat.service';
export * from './lib/services/appointment/appointments.service';
export * from './lib/services/doctor/doctor.service';
export * from './lib/services/exams/exams.service';
// Exporting hospital-app services
export * from './lib/services/hospital-app/hospital-data.service';

export * from './lib/services/insured-app/claims.service';
export * from './lib/services/member/member.service';
export * from './lib/services/insured-app/incident.service';
export * from './lib/services/insured-app/medical-record.service';

// Exporting insurers-app services

export * from './lib/services/insurers-app/claims.service';
export * from './lib/services/insurers-app/contracts.service';
export * from './lib/services/insurers-app/insurance-products.service';
export * from './lib/services/insurers-app/insurer-config.service';
export * from './lib/services/insurers-app/dashboard-data.service';

// Exporting Interfaces
export * from './lib/interfaces/interface.module';

// exporting the data services

export * from './lib/ui-components/layout/service/app.layout.service';

// shared services
export * from './lib/services/shared/exam-centers.service';

export * from './lib/services/shared/area.service';
export * from './lib/services/other/country.service';
export * from './lib/services/shared/notifications.service';

// Exporting insured-app services

// export * from './lib/services/hospital-app/exams.service';

// Export Import module
export * from './lib/imports/imports.module';

// Export Const
export * from './lib/enums/period.constants';
export * from './lib/enums/modal-messages.constants';
export * from './lib/enums/appointment-status.constants';

/*Exporting UI Components*/
export * from './lib/ui-components/notfound/notfound.component';
export * from './lib/ui-components/auth/login/login.component';
export * from './lib/ui-components/auth/register/register.component';
export * from './lib/ui-components/layout/app.layout.module';
export * from './lib/ui-components/layout/app.layout.component';
export * from './lib/ui-components/layout/app.topbar.component';
export * from './lib/ui-components/layout/app.sidebar.component';
export * from './lib/ui-components/layout/app.breadcrumb.component';
export * from './lib/ui-components/layout/app.menuitem.component';
export * from './lib/ui-components/layout/app.profilesidebar.component';
export * from './lib/ui-components/layout/app.menu.component';
export * from './lib/ui-components/kpi-card/kpi-card.component';
/*Filters*/
export * from './lib/ui-components/filters/filters.component';
export * from './lib/ui-components/map/map.component';

/*Settings*/
export * from './lib/ui-components/settings/settings.component';

/*Common calendar components*/
export * from './lib/ui-components/calendar/pending-appointments/pending-appointments.component';
export * from './lib/ui-components/calendar/appointments-table/appointments-table.component';
export * from './lib/ui-components/calendar/full-calendar/custom-calendar.component';

/* Chat Box */
export * from './lib/ui-components/talkjs-chat/talkjs-chat.component';
export * from './lib/ui-components/inbox/inbox.component';

export * from './lib/ui-components/layout/service/menu-data.service';
