import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { catchError, of } from 'rxjs';
// import { AuthService } from 'shared';

export const getUserResolver: ResolveFn<any> = (route, state) => {
  const userService = inject(AuthService);

  return userService.currentUser$.pipe(
    catchError((error) => {
      console.error(error);
      return of('No data');
    })
  );
};
