<div class="layout-container" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-config></app-config>
  <div class="layout-content-wrapper">
    <div class="layout-content">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
      <lib-talkjs-chat></lib-talkjs-chat>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>
  
  <div class="layout-mask"></div>
</div>
