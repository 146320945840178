<div class="surface-section mb-8">
  <div class="font-medium text-3xl text-900 mb-5">Έγγραφα</div>
  <div class="text-500 mb-5 border-bottom-1 surface-border">
    <!-- Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum. -->
  </div>
  <p-table
    responsiveLayout="scroll"
    #dt
    [value]="files.files"
    [rows]="8"
    [paginator]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          pSortableColumn="name"
          style="min-width: 12rem"
          class="white-space-nowrap"
        >
          Έγγραφο <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th
          pSortableColumn="date"
          style="min-width: 12rem"
          class="white-space-nowrap"
        >
          Ημερομηνία Δημιουργίας <p-sortIcon field="date"></p-sortIcon>
        </th>
        <th
          pSortableColumn="date"
          style="min-width: 12rem"
          class="white-space-nowrap"
        >
          Ημερομηνία Τροποποίησης <p-sortIcon field="date"></p-sortIcon>
        </th>
        <th
          pSortableColumn="fileSize"
          style="min-width: 12rem"
          class="white-space-nowrap"
        >
          Μέγεθος Εγγράφου <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th style="width: 10rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file>
      <tr>
        <td>
          <div class="flex align-items-center">
            <i [class]="'text-xl text-primary mr-2 ' + file.icon"></i>
            <span>{{ file.name }}</span>
          </div>
        </td>
        <td>
          <span>{{ file.dateOfUpload }}</span>
        </td>
        <td>
          <span>{{ file.dateOfUpdate }}</span>
        </td>
        <td>
          <span>{{ file.fileSize }}</span>
        </td>
        <td class="text-center">
          <button
            pButton
            pRipple
            icon="pi pi-times"
            class="p-button-danger p-button-text p-button-rounded mr-2"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-search"
            class="p-button-text p-button-rounded"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>