<div class="flex justify-content-center align-items-center">
  <span class="text-900 text-lg mb-3 border-round bg-primary-100 p-2">
    {{ auditsCount }} έλεγχοι
  </span>
</div>

<p-table
  responsiveLayout="scroll"
  #dt
  [value]="audits"
  [rows]="8"
  [paginator]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="name"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Τύπος <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th
        pSortableColumn="date"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Συμπέρασμα Ελεγκτή <p-sortIcon field="date"></p-sortIcon>
      </th>

      <!-- <th
        pSortableColumn="fileSize"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Αποδεκτή Διάρκεια <p-sortIcon field="name"></p-sortIcon>
      </th> -->
      <th
        pSortableColumn="date"
        style="min-width: 12rem"
        class="white-space-nowrap"
      >
        Ημερομηνία Τροποποίησης <p-sortIcon field="date"></p-sortIcon>
      </th>
      <th style="width: 18rem">Χρήστης τελευταίας αλλαγής</th>
      <th style="width: 18rem">Αρχείο</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-audit>
    <tr>
      <td>
        <span>{{ audit.type }}</span>
      </td>
      <td>
        <span>{{ audit.auditorConclusion }}</span>
      </td>
      <!-- <td>
        <span>{{ audit.acceptableDuration }}</span>
      </td> -->
      <td>
        <span>{{ audit.lastChangeDate }}</span>
      </td>
      <td>
        <span>{{ audit.lastChangeUser }}</span>
      </td>
      <td>
        <span>{{ audit.filename }}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
