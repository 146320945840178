<div class="font-medium text-3xl text-900 mb-3 mt-3">Ποσό Ανά Κατηγορία</div>

<p-table [value]="services" dataKey="id">
  <!-- Table Header -->
  <ng-template pTemplate="header">
    <tr>
      <th>#</th>
      <th>Είδος Υπηρεσίας</th>
      <th>Καθαρό Ποσό</th>
      <th>Φ.Π.Α</th>
      <th>Μικτό Ποσό</th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-service let-rowIndex="rowIndex">
    <tr>
      <!-- Row Number -->
      <td>{{ rowIndex + 1 }}</td>

      <!-- Service Type -->
      <td>{{ service.serviceType }}</td>

      <!-- Net Amount -->
      <td>
        <input
          pInputText
          type="number"
          [(ngModel)]="service.netAmount"
          placeholder="Καθαρό Ποσό"
        />
      </td>

      <!-- VAT -->
      <td>
        <input
          pInputText
          type="number"
          [(ngModel)]="service.vat"
          placeholder="Φ.Π.Α"
        />
      </td>

      <!-- Gross Amount (calculated as Net + VAT) -->
      <td>{{ service.netAmount + service.vat }}</td>
    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">Δεν υπάρχουν υπηρεσίες.</td>
    </tr>
  </ng-template>
</p-table>
