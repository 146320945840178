import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environments';
import {
  concatMap,
  delay,
  Observable,
  retryWhen,
  Subject,
  take,
  takeUntil,
  timer,
} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  private apiUrl = `${environment.api}notifications`;
  private stopPolling = new Subject<void>();

  constructor(private http: HttpClient) {}

  // Start polling for notifications
  startPolling(userId: string): Observable<any> {
    let requestInterval = 5000;
    let retryCount = 5;
    return timer(0, requestInterval).pipe(
      // takeUntil(this.stopPolling),
      concatMap(() => this.fetchNotifications(userId)),
      retryWhen((error$) =>
        error$.pipe(delay(requestInterval), take(retryCount))
      )
    );
  }

  // Fetch notifications for the given userId
  private fetchNotifications(userId: string): Observable<any[]> {
    const url = `${this.apiUrl}/${userId}`;
    return this.http.get<any[]>(url, this.httpOptions);
  }

  // Stop polling (could be called during logout)
  stopPollingForNotifications(): void {
    this.stopPolling.next();
    this.stopPolling.complete();
  }

  deleteNotification(notificationId: any): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}`,
      notificationId,
      this.httpOptions
    );
  }
}
